import { GOOGLE_SSO_LOADING_LOCAL_STORAGE } from "../components/common/googleSso/constants";

export const STORAGE_API_KEY = 'amber.key';
export const STORAGE_USER_LOGGED_IN = 'amber.user_logged_in';
export const STORAGE_USER_NAME = 'amber.user_name';
export const STORAGE_USER_TYPE = 'amber.user_type';

// Functions
export function getRoles() {
  const rolesString = localStorage.getItem(STORAGE_USER_TYPE);
  if (!rolesString) {
    return null;
  }

  if (!rolesString.startsWith('[') || !rolesString.endsWith(']')) {
    return [JSON.parse(rolesString)]
  }

  return JSON.parse(rolesString);
}

export function getUserName() {
  return localStorage.getItem(STORAGE_USER_NAME);
}

export function getUserLoggedIn() {
  const loggedInString = localStorage.getItem(STORAGE_USER_LOGGED_IN);
  if (!loggedInString) {
    return false;
  }
  return JSON.parse(loggedInString);
}

export function cleanLocalStorage() {
  localStorage.removeItem(STORAGE_USER_TYPE);
  localStorage.removeItem(STORAGE_USER_LOGGED_IN);
  localStorage.removeItem(STORAGE_USER_NAME);
  localStorage.removeItem(STORAGE_API_KEY);
  localStorage.removeItem('lang');

  // Remove Google SSO login flag from local storage
  localStorage.removeItem(GOOGLE_SSO_LOADING_LOCAL_STORAGE);
}

export function logout(history) {
  cleanLocalStorage();

  if (history) {
    history.push('/');
  } else {
    document.location.replace('/');
  }
}

export function getLoginDetails() {
  return {
    role: getRoles(),
    userName: getUserName(),
    isLoggedIn: getUserLoggedIn(),
  };
}
