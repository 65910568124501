import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export default function setupSentry() {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new BrowserTracing()],
    ignoreErrors: [
      `/Cannot use 'in' operator to search for/g`,
      `Cannot use 'in' operator to search for`,
      `right-hand side of 'in' should be an object, got undefined`,
      `cwvi.strg['urls'] is not an Object. (evaluating 'cwvi.rum.url in cwvi.strg['urls']')`,
      `b.cwvi.strg.urls is not an Object. (evaluating 'b.cwvi.rum.url in b.cwvi.strg.urls')`,
    ],
    environment:
      process.env.REACT_APP_ENVIRONMENT === 'development'
        ? 'staging'
        : 'production',
    tracesSampleRate: 1.0,
  });
}
