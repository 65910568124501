import React from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Box,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

export default function AmberEnhancedTableHead(props) {
  const { sortDirection, sortProperty, onRequestSort, headCells } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.key}
            align="left"
            padding="normal"
            sortDirection={
              sortProperty === headCell.key ? sortDirection : false
            }
            sx={{
              display: {
                xs: headCell.displayInXS ? 'table-cell' : 'none',
                sm: 'table-cell',
              },
            }}
          >
            {!headCell.sortable ? (
              <>
                <Typography variant="normalBold" whiteSpace="nowrap">
                  {headCell.label}
                </Typography>
                {sortProperty === headCell.key ? (
                  <Box component="span" sx={visuallyHidden}>
                    {sortDirection === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </>
            ) : (
              <TableSortLabel
                active={sortProperty === headCell.key}
                direction={
                  sortProperty === headCell.key ? sortDirection : 'asc'
                }
                onClick={createSortHandler(headCell.key)}
              >
                <Typography variant="normalBold" whiteSpace="nowrap">
                  {headCell.label}
                </Typography>
                {sortProperty === headCell.key ? (
                  <Box component="span" sx={visuallyHidden}>
                    {sortDirection === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

AmberEnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  sortProperty: PropTypes.string,
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      width: PropTypes.shape({
        xs: PropTypes.string,
        normal: PropTypes.string,
      }).isRequired,
      sortable: PropTypes.bool,
      displayInXS: PropTypes.bool,
      displayer: PropTypes.func,
    }).isRequired,
  ).isRequired,
};

AmberEnhancedTableHead.defaultProps = {
  sortDirection: 'asc',
  sortProperty: 'key',
};
