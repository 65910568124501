import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Container, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  acceptRequest,
  denyRequest,
  getCurrentJobsWithAllocation,
  getInvitationRequests,
  submitJob,
} from '../utils/api';
import { useDataApi } from '../utils/apiHooks';
import useSnackbar from '../utils/useSnackbar';
import InvitationTable from '../components/invitation_requests/invitationTable';
import CurrentJobs from '../components/current_jobs/currentJobs';
import JobDetailsContainer from './jobDetails/JobDetailsContainer';
import { TRANSCRIBER } from '../roles';
import { DONT_SHOW_EXPORT_DIALOG } from '../store/constants';
import AmberHeadingBold from '../components/labels/AmberHeadingBold';
import { AMBER_PRIMARY, MANGO } from '../constants/colours';
import { getReportingAction } from '../store/jobManagerDashboard';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 65,
    marginBottom: 40,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  currenJobs: {
    width: '80%',
    margin: 'auto',
  },
  invitations: {
    width: '80%',
    marginTop: theme.spacing(1),
    margin: 'auto',
  },
  acceptedJobs: {
    width: '80%',
    marginTop: theme.spacing(1),
    margin: 'auto',
  },
  padding: {
    padding: theme.spacing(0, 2),
  },
}));

function TranscriberDashboard() {
  const classes = useStyles();
  const [t] = useTranslation();
  const theme = useTheme();
  const ALLOCATION_CAP = process.env.REACT_APP_ALLOCATION_CAP;
  const reportingAction = useRecoilValue(getReportingAction);

  const [tabValue, setTabValue] = useState(0);
  const [currentJobId, setCurrentJobId] = useState({
    jobId: null,
    openDialog: false,
    tabIndex: 0,
  });
  const [currentGlossaryId, setCurrentGlossaryId] = useState(null);
  const [openCutOffDialog, setOpenCutOffDialog] = useState(false);
  const [jobForCutOffDialog, setJobForOpenCutOffDialog] = useState([]);

  const { showError, showSuccess } = useSnackbar();

  function onError(msg) {
    showError(msg);
  }

  const [currentJobs, doFetchCurrentJobs] = useDataApi(
    getCurrentJobsWithAllocation,
    [],
    onError,
  );

  const [invitationRequests, doFetchInvitationRequests] = useDataApi(
    getInvitationRequests,
    [],
    onError,
  );

  function refreshInvites() {
    doFetchInvitationRequests(getInvitationRequests());
  }

  function refreshCurrentJobs() {
    doFetchCurrentJobs(getCurrentJobsWithAllocation());
  }

  const [, doAcceptRequest] = useDataApi(
    false,
    '',
    (msg) => {
      refreshInvites();
      onError(msg);
    },
    () => {
      refreshInvites();
      refreshCurrentJobs();
      showSuccess('Success');
    },
  );

  const [, doReportJob] = useDataApi(
    false,
    '',
    (msg) => {
      refreshInvites();
      onError(msg);
    },
    () => {
      refreshInvites();
      refreshCurrentJobs();
      showSuccess('Success');
    },
  );
  const [, doDenyRequest] = useDataApi(
    false,
    '',
    (msg) => {
      refreshInvites();
      onError(msg);
    },
    () => {
      refreshInvites();
      showSuccess('Success');
    },
  );

  const [, doSubmitJob] = useDataApi(false, '', onError, () => {
    showSuccess('Success');
    refreshCurrentJobs();
  });

  function shouldOpenCuttOffDialog() {
    const dontShowAgain = localStorage.getItem(DONT_SHOW_EXPORT_DIALOG);

    return dontShowAgain === 'true';
  }

  function acceptJob(perfectRequest) {
    doAcceptRequest(acceptRequest(perfectRequest));
  }

  function reportJob(jobId, issueType, note) {
    if (reportingAction) {
      doReportJob(reportingAction(jobId, issueType, note));
    }
  }

  function acceptRequestHandler(perfectRequest) {
    if (!shouldOpenCuttOffDialog()) {
      setOpenCutOffDialog(true);
      setJobForOpenCutOffDialog(perfectRequest);
    } else {
      acceptJob(perfectRequest);
    }
  }

  function denyRequestHandler(perfectRequest) {
    doDenyRequest(denyRequest(perfectRequest));
  }

  function submitJobHandler(jobId) {
    doSubmitJob(submitJob(jobId));
  }

  // Gets the glossaryId from a job with a specific jobId from a specific array of jobs
  function getGlossaryFromSelectedJob(jobId, arrayOfJobs) {
    const selectedJob = arrayOfJobs.find((job) => job.jobId === jobId);
    return selectedJob.glossaryId;
  }

  function showJobDetailsAccepted(jobId, glossaryId, tabIndex) {
    setCurrentJobId({ jobId, openDialog: true, tabIndex });
    setCurrentGlossaryId(glossaryId);
  }

  function showJobDetails(jobId, glossaryId, tabIndex) {
    setCurrentJobId({ jobId, openDialog: true, tabIndex });
    if (
      currentJobs &&
      currentJobs.data.transcriberJobRes &&
      currentJobs.data.transcriberJobRes.length > 0
    ) {
      setCurrentGlossaryId(
        getGlossaryFromSelectedJob(jobId, currentJobs.data.transcriberJobRes),
      );
    }
  }

  function closeJobDetailsHandler() {
    setCurrentJobId({ jobId: null, openDialog: false });
    setCurrentGlossaryId(null);
  }

  function getNumberOfMinutes() {
    return Math.floor(currentJobs.data.numberOfAllocatedSeconds / 60);
  }

  function getColor() {
    return getNumberOfMinutes() > ALLOCATION_CAP ? MANGO : AMBER_PRIMARY;
  }

  return (
    <div className={classes.container}>
      <Container maxWidth="xl">
        {/* HEADER */}

        <Box mt={6} mb={5} display="flex" justifyContent="space-between">
          <Typography
            variant="h4"
            id="tableTitle"
            data-testid="transcriber-dashboard-title"
          >
            {t('transcriberDashBoard.title')}
          </Typography>

          <Box display="flex" alignItems="end">
            <Box mr={1}>
              <Typography
                variant="h5"
                id="tableTitle"
                style={{ color: getColor(), fontWeight: 'bold' }}
              >
                {`${getNumberOfMinutes()}/${ALLOCATION_CAP}`}
              </Typography>
            </Box>
            <Typography variant="h5" id="tableTitle">
              {t('transcriberDashBoard.minutesClaimed')}
            </Typography>
          </Box>
        </Box>

        {/* ACTIVE JOBS */}
        <Box mb={5}>
          <Box mb={1}>
            <AmberHeadingBold
              color={theme.palette.custom.darkGreenColor}
              text={t('transcriberDashBoard.activeJobs')}
              testid="transcriber-dashboard-active-title"
            />
          </Box>
          <CurrentJobs
            setTabValue={setTabValue}
            currentJobs={currentJobs.data.transcriberJobRes}
            submitJobHandler={submitJobHandler}
            showJobDetails={(jobId, glossaryId, tabIndex) =>
              showJobDetails(jobId, glossaryId, tabIndex)
            }
          />
        </Box>

        <Box>
          <Box mb={1}>
            <AmberHeadingBold
              text={t('transcriberDashBoard.invitations')}
              testid="transcriber-dashboard-invitation-title"
            />
          </Box>

          <InvitationTable
            jobForCutOffDialog={jobForCutOffDialog}
            openCutOffDialog={openCutOffDialog}
            setOpenCutOffDialog={setOpenCutOffDialog}
            requests={invitationRequests}
            acceptRequest={(perfectRequest) =>
              acceptRequestHandler(perfectRequest)
            }
            acceptJob={acceptJob}
            denyRequest={(perfectRequest) => denyRequestHandler(perfectRequest)}
            refreshTables={refreshInvites}
            showJobDetails={(jobId, glossaryId, tabIndex) =>
              showJobDetailsAccepted(jobId, glossaryId, tabIndex)
            }
          />

          {currentJobId.jobId && (
            <JobDetailsContainer
              role={TRANSCRIBER}
              refreshJobsTable={refreshCurrentJobs}
              tabValue={tabValue}
              handleOnClickReport={reportingAction ? reportJob : null}
              jobId={currentJobId.jobId}
              closeJobDetailsHandler={closeJobDetailsHandler}
              openDialog={currentJobId.openDialog}
              glossaryId={currentGlossaryId}
              tabIndex={currentJobId.tabIndex}
            />
          )}
        </Box>
      </Container>
    </div>
  );
}

export default TranscriberDashboard;
