export const AMBER_PRIMARY = '#329678';
export const AMBER_SECONDARY = '#FAAA28';
export const AMBER_RED_COLOR = '#bf360c';

export const AMBER_INFO_GRAY = '#726F7B';

export const DARK_GREEN_COLOR = '#005A50';
export const GREEN_COLOR = '#25d366';
export const RED_COLOR = '#e36a57';
export const PINKISH_RED = '#DC283C';
export const GRAY_COLOR = '#e0e0e0';
export const DARK_GRAY_COLOR = '#D0D0D0';

export const PIG_PINK = '#F082A0';
export const CHARCOAL_GRAY = '#2B292D';
export const AMBER_LIGHT_GRAY = '#ECECEC';
export const PURPLISH_GRAY = '#726F7B';
export const LIGHT_GRAY = '#E5E5E5';
export const LIGHER_GRAY = '#ECECEC';
export const WHITE = '#FFFFFF';
export const WHITE_TWO = '#F5F5F5';
export const BLACK = '#000000';
export const MANGO = '#FAAA28';
export const LIGHT_MANGO = '#FFC260';
