import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

export default function AmberLargeTextLabel(props) {
  const {
    text,
    textAlign = 'left',
    color = 'unset',
    whiteSpace = 'nowrap',
    testid = '',
  } = props;

  return (
    <Box
      component="span"
      textAlign={textAlign}
      whiteSpace={whiteSpace}
      textOverflow="ellipsis"
      overflow="hidden"
      color={color || 'unset'}
      maxWidth="inherit"
    >
      <Typography
        variant="normal"
        data-testid={testid}
        component="p"
        whiteSpace="normal"
      >
        {text}
      </Typography>
    </Box>
  );
}

AmberLargeTextLabel.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]).isRequired,
  textAlign: PropTypes.string,
  color: PropTypes.string,
  whiteSpace: PropTypes.string,
  testid: PropTypes.string,
};

AmberLargeTextLabel.defaultProps = {
  textAlign: 'left',
  color: 'unset',
  whiteSpace: 'nowrap',
  testid: '',
};
