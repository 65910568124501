import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  fontClass: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  hideOverflow: {
    fontSize: '16px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

function AmberHeadingBold(props) {
  const { text, color, testid, overflow } = props;
  const classes = useStyles();

  return (
    <Typography
      data-testid={testid}
      style={{ color }}
      className={overflow ? classes.hideOverflow : classes.fontClass}
    >
      {text}
    </Typography>
  );
}
AmberHeadingBold.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  testid: PropTypes.string,
  overflow: PropTypes.bool,
};

AmberHeadingBold.defaultProps = {
  color: 'inherit',
  testid: '',
  overflow: false,
};

export default AmberHeadingBold;
