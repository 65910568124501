import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import intlConvertor from '../../../helpers/intlConvertor';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 200,
    maxWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function MultiSelect(props) {
  const { id, data, label, type, value, onChange } = props;
  const classes = useStyles();
  const inputId = `${id}-select-input`;

  function handleChange(e) {
    onChange(e.target.name, e.target.value);
  }

  function getFullNameOfLanguages(element) {
    return intlConvertor(element);
  }

  function sortedData() {
    if (type === 'lang') {
      const langWithLabels = data.map((d) => ({
        code: d,
        label: getFullNameOfLanguages(d),
      }));
      const sortedLangsWithLabels = langWithLabels.sort((a, b) =>
        (a.label > b.label ? 1 : -1),
      );
      const sortedLangs = sortedLangsWithLabels.map((l) => l.code);
      return sortedLangs;
    }
    return data;
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel shrink htmlFor={inputId}>
        {label}
      </InputLabel>
      <Select
        multiple
        value={Array.isArray(value) ? value : []}
        onChange={handleChange}
        inputProps={{
          name: id,
          id: inputId,
        }}
        renderValue={(selected) =>
          Array.isArray(selected) ? selected.join(', ') : selected
        }
      >
        {sortedData().map((element) => (
          <MenuItem key={element} value={element}>
            <Checkbox checked={value.indexOf(element) > -1} />
            <ListItemText
              primary={type === 'lang' ? getFullNameOfLanguages(element) : element}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

MultiSelect.propTypes = {
  data: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
  type: PropTypes.string,
};

MultiSelect.defaultProps = {
  type: 'default',
};
