import React from 'react';
import { makeStyles, Fade } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import AmberSimpleTextField from './amberSimpleTextfield';
import AmberFormHelper from '../labels/AmberFormHelper';

const useStyles = makeStyles((theme) => ({
  textAreaRoot: {
    margin: 0,
  },
  textField: {
    border: `1px solid ${theme.palette.custom.darkGrey}`,
    borderRadius: '0.5em',
    padding: '1em',
    margin: 0,
    '&:hover': {
      borderColor: theme.palette.custom.lightGray,
    },
  },
  textFieldError: {
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: '0.5em',
    padding: '1em',
    margin: 0,
    '&:hover': {
      borderColor: theme.palette.error.main,
    },
  },
}));

const getHelperText = (hasError, formHelperText, t) => {
  if (hasError) {
    return (
      <Fade transition={700} in={hasError}>
        <AmberFormHelper error text={t('common.error.noEmpty')} />
      </Fade>
    );
  }
  if (formHelperText) {
    <AmberFormHelper error={false} text={formHelperText} />;
  }
  return null;
};

function AmberCustomBoxTextArea(props) {
  const {
    formHelperText,
    inputClasses,
    hasError,
    handleChange,
    id,
    value,
    rows,
    ...rest
  } = props;
  const [t] = useTranslation();
  const classes = useStyles();

  const textFieldClass = clsx({
    [classes.textField]: !hasError,
    [classes.textFieldError]: hasError,
  });
  return (
    <AmberSimpleTextField
      helperText={getHelperText(hasError, formHelperText, t)}
      maxRows={rows}
      minRows={rows}
      InputProps={{
        classes: {
          root: textFieldClass,
          ...inputClasses,
        },
        disableUnderline: true,
      }}
      classes={{ root: classes.textAreaRoot }}
      variant="standard"
      onChange={handleChange}
      value={value}
      id={id}
      multiline
      {...rest}
    />
  );
}
AmberCustomBoxTextArea.propTypes = {
  formHelperText: PropTypes.string,
  hasError: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  value: PropTypes.string,
  inputClasses: PropTypes.objectOf(PropTypes.string),
  rows: PropTypes.number
};
AmberCustomBoxTextArea.defaultProps = {
  formHelperText: '',
  hasError: false,
  id: null,
  value: '',
  inputClasses: {},
  rows: 3
};
export default AmberCustomBoxTextArea;
