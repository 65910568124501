import React from 'react';
import PropTypes from 'prop-types';
import { Button, styled } from '@material-ui/core';

import AmberRootButton from './AmberRootButton';

const CustomButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 80,
  whiteSpace: 'nowrap',
  fontSize: '1rem',
  fontWeight: 800,
  height: theme.spacing(6),
  border: '1px solid',
}));

export default function AmberPrimaryIconButton(props) {
  const {
    text,
    disabled = false,
    handleClick,
    fullWidth = false,
    testid = '',
    icon,
  } = props;

  return (
    <CustomButton
      color="primary"
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={handleClick}
      data-testid={testid}
      startIcon={icon}
      variant="outlined"
    >
      <AmberRootButton>{text}</AmberRootButton>
    </CustomButton>
  );
}

AmberPrimaryIconButton.propTypes = {
  text: PropTypes.string.isRequired,
  testid: PropTypes.string,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  icon: PropTypes.element,
};

AmberPrimaryIconButton.defaultProps = {
  disabled: false,
  fullWidth: false,
  testid: '',
  icon: null,
};
