import {
  BUSY,
  PERFECT_AWAITING_CONFIRMATION_TRANSCRIBER,
  PERFECT_EDITING,
  PERFECT_IN_QUEUE,
  PERFECT_IN_REVIEW,
  PERFECT_OPEN,
  PERFECT_REPORTED,
  PERFECT_REVIEW_NOT_PASSED,
  PERFECT_TO_BE_REVIEWED,
} from '../../constants/jobStatuses';

/**
 * Check if change dates button can be enabled
 * @param {Array} selectedjobIds IDs of the selected jobs
 * @param {Array} selectedJobs Object of the selected jobs
 * @returns {Boolean}
 */
export function checkIfBtnChangeDatesCanBeEnabled(
  selectedjobIds,
  selectedJobs,
) {
  if (selectedjobIds.length === 0) return false;

  return selectedJobs.every((job) => {
    const { jobStatus } = job;
    switch (jobStatus) {
      case PERFECT_OPEN:
      case PERFECT_AWAITING_CONFIRMATION_TRANSCRIBER:
      case PERFECT_IN_QUEUE:
      case PERFECT_EDITING:
      case PERFECT_TO_BE_REVIEWED:
      case PERFECT_REVIEW_NOT_PASSED:
      case PERFECT_IN_REVIEW:
      case PERFECT_REPORTED:
      case BUSY:
        return true;
      default:
        return false;
    }
  });
}

/**
 * Check if assign manager button can be enabled
 * @param {Array} selectedjobIds IDs of the selected jobs
 * @param {Array} selectedJobs Object of the selected jobs
 * @returns {Boolean}
 */
export function checkIfBtnAssignManagerCanBeEnabled(
  selectedjobIds,
  selectedJobs,
) {
  if (selectedjobIds.length === 0) return false;

  return selectedJobs.every((job) => {
    const { jobStatus } = job;
    switch (jobStatus) {
      case PERFECT_OPEN:
      case PERFECT_AWAITING_CONFIRMATION_TRANSCRIBER:
      case PERFECT_IN_QUEUE:
      case PERFECT_EDITING:
      case PERFECT_TO_BE_REVIEWED:
      case PERFECT_REVIEW_NOT_PASSED:
      case PERFECT_IN_REVIEW:
      case PERFECT_REPORTED:
        return true;
      default:
        return false;
    }
  });
}

/**
 * Check if assign transcribers button can be enabled
 * @param {Array} selectedjobIds IDs of the selected jobs
 * @param {Array} selectedJobs Object of the selected jobs
 * @returns {Boolean}
 */
export function checkIfBtnAssignTranscribersCanBeEnabled(
  selectedjobIds,
  selectedJobs,
) {
  if (selectedjobIds.length === 0) return false;

  return selectedJobs.every((job) => {
    const { jobStatus } = job;
    switch (jobStatus) {
      case PERFECT_REPORTED:
      case PERFECT_REVIEW_NOT_PASSED:
      case PERFECT_OPEN:
        return true;
      default:
        return false;
    }
  });
}

/**
 * Check if invite transcribers button can be enabled
 * @param {Array} selectedjobIds IDs of the selected jobs
 * @param {Array} selectedJobs Object of the selected jobs
 * @returns {Boolean}
 */
export function checkIfBtnInviteTranscribersCanBeEnabled(
  selectedjobIds,
  selectedJobs,
) {
  if (selectedjobIds.length === 0) return false;

  return selectedJobs.every((job) => {
    const { jobStatus } = job;
    switch (jobStatus) {
      case PERFECT_EDITING:
      case PERFECT_REPORTED:
      case PERFECT_OPEN:
        return true;
      default:
        return false;
    }
  });
}
