import { CAPTIONS, TRANSLATED_SUBTITLES } from './transcriptionTypes';
import { WORKFLOW_STYLES } from './constants';

/**
 * Renamed topics
 * content -> transcription
 * guidelines - > syntax
 * spellingTyposGrammar -> grammar
 * punctuationSyntax - > punctuation
 * namesAndJargon -> research
 */

export const TRANSCRIPTION = 'transcription';
export const SYNTAX = 'syntax';
export const GRAMMAR = 'grammar';
export const PUNCTUATION = 'punctuation';

export const TRANSCRIPTION_HQ = 'transcriptionHQ';
export const RESEARCH = 'research';
export const DIFFICULTY = 'difficulty';
export const TIMING = 'timing';
export const CAPTIONS_FORMATTING = 'captionsFormatting';
export const TRANSLATED_SUBTITLES_FORMATTING = 'translatedSubtitlesFormatting';
export const MEANING = 'transferOfMeaning';
export const TERMINOLOGY = 'terminology';
export const COMPLETENESS = 'completeness';
export const ACCURACY = 'grammarAndSpelling';

const getDifficulty = (t) => ({
  [DIFFICULTY]: {
    label: t ? t(`dialogJobFeedback.${DIFFICULTY}`) : null,
    min: 1,
    max: 5,
  },
});

const getStandardReviewForm = (t) => ({
  [TRANSCRIPTION]: {
    label: t ? t(`dialogJobFeedback.${TRANSCRIPTION}`) : null,
    tooltip: t ? t(`dialogJobFeedback.tooltip.${TRANSCRIPTION}`) : null,
    weight: 0.3,
    min: 1,
    max: 5,
  },
  [SYNTAX]: {
    label: t ? t(`dialogJobFeedback.${SYNTAX}`) : null,
    tooltip: t ? t(`dialogJobFeedback.tooltip.${SYNTAX}`) : null,
    weight: 0.25,
    min: 1,
    max: 5,
  },
  [GRAMMAR]: {
    label: t ? t(`dialogJobFeedback.${GRAMMAR}`) : null,
    tooltip: t ? t(`dialogJobFeedback.tooltip.${GRAMMAR}`) : null,
    weight: 0.25,
    min: 1,
    max: 5,
  },
  [PUNCTUATION]: {
    label: t ? t(`dialogJobFeedback.${PUNCTUATION}`) : null,
    tooltip: t ? t(`dialogJobFeedback.tooltip.${PUNCTUATION}`) : null,
    weight: 0.15,
    min: 1,
    max: 5,
  },
  [RESEARCH]: {
    label: t ? t(`dialogJobFeedback.${RESEARCH}`) : null,
    tooltip: t ? t(`dialogJobFeedback.tooltip.${RESEARCH}`) : null,
    weight: 0.05,
    min: 1,
    max: 5,
  },
  ...getDifficulty(t),
});

const getCaptionsHQReviewForm = (t) => ({
  [TRANSCRIPTION_HQ]: {
    label: t ? t(`dialogJobFeedback.${TRANSCRIPTION_HQ}`) : null,
    min: 1,
    max: 5,
  },
  [CAPTIONS_FORMATTING]: {
    label: t ? t(`dialogJobFeedback.${CAPTIONS_FORMATTING}`) : null,
    min: 1,
    max: 5,
  },
  [TIMING]: {
    label: t ? t(`dialogJobFeedback.${TIMING}`) : null,
    min: 1,
    max: 5,
  },
  [RESEARCH]: {
    label: t ? t(`dialogJobFeedback.${RESEARCH}`) : null,
    min: 1,
    max: 5,
  },
  ...getDifficulty(t),
});

const getCaptionsRatingForm = (t, workflowStyle) => {
  switch (workflowStyle) {
    case WORKFLOW_STYLES.HQ:
      return getCaptionsHQReviewForm(t);
    default:
      return getStandardReviewForm(t);
  }
};

const getTranslatedSubsRatingForm = (t) => ({
  [COMPLETENESS]: {
    label: t ? t(`dialogJobFeedback.${COMPLETENESS}`) : null,
    min: 1,
    max: 5,
  },
  [MEANING]: {
    label: t ? t(`dialogJobFeedback.${MEANING}`) : null,
    min: 1,
    max: 5,
  },
  [ACCURACY]: {
    label: t ? t(`dialogJobFeedback.${ACCURACY}`) : null,
    min: 1,
    max: 5,
  },
  [TERMINOLOGY]: {
    label: t ? t(`dialogJobFeedback.${TERMINOLOGY}`) : null,
    min: 1,
    max: 5,
  },
  [TRANSLATED_SUBTITLES_FORMATTING]: {
    label: t ? t(`dialogJobFeedback.${TRANSLATED_SUBTITLES_FORMATTING}`) : null,
    min: 1,
    max: 5,
  },
  ...getDifficulty(t),
});

export const getRatingDefaults = (t, workflowStyle, transcriptionType) => {
  switch (transcriptionType) {
    case CAPTIONS:
      return getCaptionsRatingForm(t, workflowStyle);
    case TRANSLATED_SUBTITLES:
      return getTranslatedSubsRatingForm(t);
    default:
      return getStandardReviewForm(t);
  }
};
