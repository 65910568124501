import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  fontSize: {
    fontSize: '16px',
  },
});

function AmberHeading(props) {
  const { text, color, testid } = props;
  const classes = useStyles();

  return (
    <Typography data-testid={testid} color={color} className={classes.fontSize}>
      {text}
    </Typography>
  );
}
AmberHeading.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  testid: PropTypes.string,
};

AmberHeading.defaultProps = {
  color: 'inherit',
  testid: '',
};

export default AmberHeading;
