import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';

import AmberSimpleCollapse from '../../components/collapse/AmberSimpleCollapse';
import AmberNoItemFoundCard from '../../components/cards/AmberNoItemFound';
import AmberIconButton from '../../components/buttons/AmberIconButton';
import AmberSectionTitle from '../../components/labels/AmberSectionTitle';
import { getAllIssueTypesMap } from '../../constants/jobIssueTypes';
import AmberSimpleTextField from '../../components/textfields/amberSimpleTextfield';
import { getFormattedDate } from '../../utils/timeUtils';
import generateKey from '../../utils/generateReactKey';
import FieldValue from '../../components/common/fields/fieldValue';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginBottom: '1em',
  },
  buttonOpen: {
    transform: 'rotate(180deg)',
    padding: '0.5em',
    margin: 0,
    transition: 'transform 500ms',
  },
  buttonClosed: {
    transform: 'rotate(360deg)',
    transition: 'transform 500ms',
    margin: 0,
    padding: '0.5em',
  },
  collapsibleControlContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fieldName: {
    flex: '0 1 50%',
    color: theme.palette.custom.charcoalGray,
    fontWeight: '900',
    marginBottom: 0,
  },
  fieldValue: {
    fontWeight: '900',
    padding: '0.1em 1em 0 1em',
    maxHeight: '1.2em',
  },
  issueReportContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  collapseWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const getIssueTypeItem = (t, issueType) => {
  if (!issueType) {
    return t('common.jobItemDialog.unknownIssueType');
  }

  const issueTypes = getAllIssueTypesMap(t);
  const currentIssueType = issueTypes.find(
    (issueTypeObject) => issueTypeObject.value === issueType,
  );
  if (!currentIssueType) {
    return t('common.jobItemDialog.unknownIssueType');
  }
  return currentIssueType.label;
};

function CollapsibleControl(props) {
  const { handleCollapse, isCollapsed, jobIssueType } = props;
  const classes = useStyles();
  const [t] = useTranslation();
  const buttonClass = clsx({
    [classes.buttonOpen]: isCollapsed,
    [classes.buttonClosed]: !isCollapsed,
  });
  const issueType = getIssueTypeItem(t, jobIssueType);
  return (
    <>
      <div className={classes.collapsibleControlContainer}>
        <AmberSectionTitle
          text={issueType}
          typographyVariant="body1"
          marginBottom={0}
        />
        <AmberIconButton
          icon={<KeyboardArrowDownIcon />}
          onClick={handleCollapse}
          classes={{ root: buttonClass }}
        />
      </div>
      <Divider className={classes.divider} />
    </>
  );
}

CollapsibleControl.propTypes = {
  handleCollapse: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool,
  jobIssueType: PropTypes.string,
};

CollapsibleControl.defaultProps = {
  isCollapsed: false,
  jobIssueType: null,
};

function IssueReportItem(props) {
  const {
    item: { message, created, userName, issueType },
    index,
  } = props;
  const classes = useStyles();
  const [isCollapsed, handleCollapse] = useState(index === 0);
  const [t] = useTranslation();
  return (
    <>
      <CollapsibleControl
        handleCollapse={() => handleCollapse(!isCollapsed)}
        isCollapsed={isCollapsed}
        jobIssueType={issueType}
      />

      <AmberSimpleCollapse
        wrapperClasses={classes.collapseWrapper}
        open={isCollapsed}
      >
        <div className={classes.issueReportContainer}>
          <FieldValue
            valueClasses={{ root: classes.fieldValue }}
            value={getFormattedDate(created)}
            fieldName={t('common.jobFields.created')}
            fieldClasses={{ root: classes.fieldName }}
          />
          <FieldValue
            valueClasses={{ root: classes.fieldValue }}
            value={userName}
            fieldName={t('common.jobItemDialog.reporter')}
            fieldClasses={{ root: classes.fieldName }}
          />

          <AmberSimpleTextField
            fullWidth
            disabled
            value={message}
            multiline
            maxRows={10}
            minRows={3}
          />
        </div>
      </AmberSimpleCollapse>
    </>
  );
}

IssueReportItem.propTypes = {
  item: PropTypes.shape({
    message: PropTypes.string,
    created: PropTypes.number,
    userName: PropTypes.string,
    issueType: PropTypes.string,
  }),
  index: PropTypes.number.isRequired,
};
IssueReportItem.defaultProps = {
  item: {},
};

function IssueReports(props) {
  const { issueReports } = props;
  const [t] = useTranslation();

  const getLastFiveIssueType = useCallback(
    () =>
      issueReports
        .slice()
        .sort((a, b) => b.created - a.created)
        .slice(0, 5),
    [issueReports],
  );

  return (
    <div>
      {!issueReports.length && (
        <AmberNoItemFoundCard
          text={t('common.jobItemDialog.noIssueReportFound')}
        />
      )}
      {!!issueReports.length &&
        getLastFiveIssueType().map((report, index) => (
          <IssueReportItem
            key={generateKey(index)}
            item={report}
            index={index}
          />
        ))}
    </div>
  );
}

IssueReports.propTypes = {
  issueReports: PropTypes.arrayOf(PropTypes.object),
};
IssueReports.defaultProps = {
  issueReports: [],
};
export default IssueReports;
