import { ReactComponent as MathematicsIcon } from '../assets/images/mathematics.svg';
import { ReactComponent as BiologyIcon } from '../assets/images/biologyphysics.svg';
import { ReactComponent as ItIcon } from '../assets/images/it.svg';
import { ReactComponent as MedicalIcon } from '../assets/images/medical.svg';
import { ReactComponent as LegalIcon } from '../assets/images/legal.svg';
import { ReactComponent as EconomicsIcon } from '../assets/images/economics.svg';
import { ReactComponent as ArtIcon } from '../assets/images/art.svg';
import { ReactComponent as PsychologyIcon } from '../assets/images/psychology.svg';
import { ReactComponent as BusinessIcon } from '../assets/images/business.svg';
import { ReactComponent as HistoryIcon } from '../assets/images/history.svg';
import { ReactComponent as PoliticsIcon } from '../assets/images/politics.svg';
import { ReactComponent as JournalismIcon } from '../assets/images/journalism.svg';
import { ReactComponent as EducationalIcon } from '../assets/images/educational.svg';
import { ReactComponent as MarketingIcon } from '../assets/images/marketing.svg';
import { ReactComponent as MediaIcon } from '../assets/images/media.svg';
import { ReactComponent as ExplicitIcon } from '../assets/images/explicit.svg';

export default function getTopicIcon(jobToDisplay) {
  if (jobToDisplay.topic) {
    const { topic } = jobToDisplay;
    switch (topic) {
      case 'MATHEMATICS':
        return {
          Icon: MathematicsIcon,
          tooltip: 'transcriberDashBoard.mathematics',
        };
      case 'BIOLOGY_PHYSICS':
        return {
          Icon: BiologyIcon,
          tooltip: 'transcriberDashBoard.biologyPhysics',
        };
      case 'IT':
        return { Icon: ItIcon, tooltip: 'transcriberDashBoard.it' };
      case 'MEDICAL':
        return { Icon: MedicalIcon, tooltip: 'transcriberDashBoard.medical' };
      case 'LEGAL':
        return { Icon: LegalIcon, tooltip: 'transcriberDashBoard.legal' };
      case 'ECONOMICS':
        return {
          Icon: EconomicsIcon,
          tooltip: 'transcriberDashBoard.economics',
        };
      case 'ART_CULTURE':
        return { Icon: ArtIcon, tooltip: 'transcriberDashBoard.artCulture' };
      case 'PSYCHOLOGY':
        return {
          Icon: PsychologyIcon,
          tooltip: 'transcriberDashBoard.psychology',
        };
      case 'BUSINESS':
        return { Icon: BusinessIcon, tooltip: 'transcriberDashBoard.business' };
      case 'HISTORY':
        return { Icon: HistoryIcon, tooltip: 'transcriberDashBoard.history' };
      case 'POLITICS_GOVERNMENT':
        return {
          Icon: PoliticsIcon,
          tooltip: 'transcriberDashBoard.politicsGovernment',
        };
      case 'JOURNALISM':
        return {
          Icon: JournalismIcon,
          tooltip: 'transcriberDashBoard.journalism',
        };
      case 'EDUCATION':
        return {
          Icon: EducationalIcon,
          tooltip: 'transcriberDashBoard.education',
        };
      case 'MARKETING':
        return {
          Icon: MarketingIcon,
          tooltip: 'transcriberDashBoard.marketing',
        };
      case 'MEDIA':
        return { Icon: MediaIcon, tooltip: 'transcriberDashBoard.media' };
      case 'VIOLENT_EXPLICIT_CONTENT':
        return { Icon: ExplicitIcon, tooltip: 'transcriberDashBoard.other' };

      default:
        break;
    }
  }
  return undefined;
}
