import React, { useEffect, useCallback } from 'react';
import { useRecoilValueLoadable, useRecoilValue } from 'recoil';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  openJobsAtom,
  activeJobsAtom,
  completedJobsAtom,
  currentViewAtom,
} from '../store/qualityControl';
import {
  QUALITY_CHECKER_OPEN_VIEW,
  QUALITY_CHEKER_COMPLETED_VIEW,
  RECOIL_LOADABLE_LOADING_STATE,
} from '../constants/constants';
import AmberSectionTitle from '../components/labels/AmberSectionTitle';
import withQualityControlLoading from './withQualityControlLoading';
import { CHARCOAL_GRAY } from '../constants/colours';
import SwitcherList from '../components/quality_control/buttonSwitcher/switcherList';
import OpenJobList from '../components/quality_control/jobs/open/openJobList';
import CompletedJobList from '../components/quality_control/jobs/completed/completedJobList';
import ActiveJobsList from '../components/quality_control/jobs/active/activeJobList';

const useStyles = makeStyles((theme) => ({
  dashboardContainer: {
    marginTop: theme.mixins.toolbar.minHeight,
    display: 'flex',
    flexDirection: 'column',
    padding: '1em 1em 1em 1em',
    width: '100%',
  },

  contentsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  openActiveContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

const getViewDashboard = (activeView, openActiveClassName) => {
  switch (activeView) {
    case QUALITY_CHECKER_OPEN_VIEW:
      return (
        <div className={openActiveClassName}>
          <ActiveJobsList />
          <OpenJobList />
        </div>
      );
    case QUALITY_CHEKER_COMPLETED_VIEW:
      return <CompletedJobList />;
    default:
      return null;
  }
};
function QualityControllerDashboard(props) {
  const classes = useStyles();
  const { setIsLoading } = props;
  const openJobs = useRecoilValueLoadable(openJobsAtom);
  const activeJobs = useRecoilValueLoadable(activeJobsAtom);
  const completedJobs = useRecoilValueLoadable(completedJobsAtom);
  const activeView = useRecoilValue(currentViewAtom);
  const [t] = useTranslation();
  const isLoading = useCallback(
    () =>
      openJobs.state === RECOIL_LOADABLE_LOADING_STATE ||
      activeJobs.state === RECOIL_LOADABLE_LOADING_STATE ||
      completedJobs.state === RECOIL_LOADABLE_LOADING_STATE,
  );
  useEffect(() => {
    setIsLoading(isLoading());
  });

  return (
    !isLoading() && (
      <div className={classes.dashboardContainer}>
        <AmberSectionTitle
          text={t('qualityControlDashboard.jobsTitle')}
          typographyVariant="h4"
          color={CHARCOAL_GRAY}
          marginBottom="1em"
        />
        <div className={classes.contentsContainer}>
          <SwitcherList />
          {getViewDashboard(activeView, classes.openActiveContainer)}
        </div>
      </div>
    )
  );
}
QualityControllerDashboard.propTypes = {
  setIsLoading: PropTypes.func.isRequired,
};
export default withQualityControlLoading(QualityControllerDashboard);
