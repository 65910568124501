import React from 'react';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: { display: 'flex' },
}));
function AmberSimpleCollapse({ children, open, wrapperClasses }) {
  const classes = useStyles();

  return (
    <Collapse in={open}>
      <div className={wrapperClasses || classes.root}>{children}</div>
    </Collapse>
  );
}

AmberSimpleCollapse.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  wrapperClasses: PropTypes.string,
};
AmberSimpleCollapse.defaultProps = {
  open: true,
  wrapperClasses: null,
};
export default AmberSimpleCollapse;
