import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 0,
    transition: 'color 500ms, border 500ms ease-out',
    border: `1px solid ${theme.palette.custom.basicLightGray}`,
    color: theme.palette.custom.infoMessage,

    '&:hover': {
      border: `1px solid ${theme.palette.primary.black}`,
      color: theme.palette.custom.charcoalGray,
    },
  },
}));
function AmberCard(props) {
  const { children, classes, contentClasses, ...rest } = props;
  const defaultClasses = useStyles();

  return (
    <Card
      classes={classes ? { ...classes } : { root: defaultClasses.root }}
      {...rest}
    >
      <CardContent
        {...(contentClasses ? { classes: { ...contentClasses } } : {})}
      >
        {children}
      </CardContent>
    </Card>
  );
}
AmberCard.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
  contentClasses: PropTypes.objectOf(PropTypes.string),
};
AmberCard.defaultProps = {
  classes: null,
  contentClasses: null,
};

export default AmberCard;
