import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import SelectAutosuggest from '../common/fields/selectautosuggest';

export default function AssignDialog(props) {
  const { open, managersList, handleAssign, handleClose, transcriberUsername } =
    props;
  const [selectedManager, setSelectedManager] = useState(null);
  const [t, i18n] = useTranslation();

  function handleSelectTranscriber(value) {
    setSelectedManager(value);
  }

  function assign() {
    if (selectedManager) {
      handleAssign(transcriberUsername, selectedManager.value);
      handleClose();
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="assign-dialog-title"
      fullWidth={true}
    >
      <DialogTitle id="assign-dialog-title">
        {t('managerDashboard.assignDialog.titleManagers')}
      </DialogTitle>
      <DialogContent>
        <SelectAutosuggest
          isMulti={false}
          label="Manager"
          value={selectedManager}
          onChange={handleSelectTranscriber}
          placeholder="Select manager"
          suggestions={managersList.map((suggestion) => ({
            value: suggestion,
            label: suggestion,
          }))}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t('common.button.close')}
        </Button>
        <Button
          onClick={assign}
          disabled={selectedManager === null || selectedManager.length === 0}
          color="primary"
        >
          {t('common.button.assign')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
