import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function SelectAutoSuggest(props) {
  const { isMulti, label, value, onChange, placeholder, suggestions, id } =
    props;

  function handleChange(value, event) {
    const syntethicEvent = { target: { id, value } };
    onChange(value, { ...syntethicEvent });
  }

  function handlePaste(e) {
    const copiedText = e.clipboardData.getData('text');
    const emails = copiedText.split('\\');
    const parsedEmails = JSON.stringify(emails);
    const rawEmails = parsedEmails
      .split('\\')
      .map((email) =>
        email
          .replaceAll('"', '')
          .replaceAll('[', '')
          .replaceAll(']', '')
          .trim(),
      );
  }

  return (
    <Autocomplete
      id={id || 'select-auto-suggest'}
      options={suggestions.map((s) => s.value)}
      getOptionLabel={(option) => option}
      placeholder={placeholder}
      style={{ maxWidth: 200 }}
      renderInput={(params) => (
        <TextField {...params} label={label} style={{ whiteSpace: 'nowrap' }} />
      )}
      onChange={(event, newValue) => handleChange(newValue, event)}
      multiple={isMulti}
      value={value}
    />
  );
}
