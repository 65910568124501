import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import AmberSimpleTooltip from '../tooltips/AmberSimpleTooltip';
import AudioPlayer from '../../audio_player/audioPlayer';
import AmberSectionTitle from '../../labels/AmberSectionTitle';
import Transcript from '../../job_details/transcript';
import AmberSectionTitleRef from '../../labels/AmberSectionTitleRef';

const useStyles = makeStyles((theme) => ({
  audioPlayerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  fileName: {
    fontWeight: 'bolder',
    color: theme.palette.custom.charcoalGray,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  fileNameEllipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  fileField: {
    color: theme.palette.custom.charcoalGray,
    flex: '0 1 30%',
  },
  fileNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
  },
  transcriptContainer: {
    overflow: 'auto',
    border: `1px solid ${theme.palette.custom.lightGray}`,
    borderRadius: '0.3em',
    maxHeight: '20em',
  },
  innterTranscript: {
    padding: '0.5em',
  },
}));
function TranscriptTabPanelContents(props) {
  const { media, filename } = props;
  const classes = useStyles();
  const [t] = useTranslation();
  if (!media || !media.transcript || !media.audioLink) {
    return null;
  }
  const {
    transcript: { segments },
    audioLink: { downloadUrl },
  } = media;

  return (
    <div className={classes.audioPlayerContainer}>
      <AudioPlayer downloadUrl={downloadUrl} />
      <div className={classes.fileNameContainer}>
        <AmberSectionTitle
          typographyVariant="body1"
          typographyClasses={{ root: classes.fileField }}
          text={t('common.jobFields.filename')}
        />
        <AmberSimpleTooltip title={filename}>
          <AmberSectionTitleRef
            typographyVariant="body1"
            typographyClasses={{ root: classes.fileName }}
            className={classes.fileNameEllipsis}
            text={filename}
          />
        </AmberSimpleTooltip>
      </div>
      <div className={classes.transcriptContainer}>
        <div className={classes.innterTranscript}>
          <Transcript data={segments} />
        </div>
      </div>
    </div>
  );
}
TranscriptTabPanelContents.propTypes = {
  media: PropTypes.shape({
    audioLink: PropTypes.shape({ downloadUrl: PropTypes.string }),
    transcript: PropTypes.shape({
      segments: PropTypes.arrayOf(PropTypes.object),
      speakers: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
  filename: PropTypes.string.isRequired,
};

TranscriptTabPanelContents.defaultProps = {
  media: null,
};
export default TranscriptTabPanelContents;
