/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { ReactComponent as GoogleLogo } from '../../../assets/images/icons/google_g_logo.svg';
import {
  redirectWithGoogleSSOSignIn,
  signInWithGoogleSSORedirect,
} from '../../../helpers/googleSSO';
import {
  getItemFromLocalStorage,
  removeToken,
  storeItemInLocalStorage,
} from '../../../helpers/storage';
import { GOOGLE_SSO_LOADING_LOCAL_STORAGE } from './constants';
import { trackSentryErrorByTag } from '../../../sentry/common';
import AmberPrimaryIconButton from '../../buttons/AmberPrimaryIconButton';
import { currentGoogleSSOUserAtom } from '../../../store/user';
import { googleSSOLoadingAtom } from '../../../store/googleSso';
import CircularLoadingGoogleSso from '../loadingGoogleSso';

export default function GoogleSSO() {
  const [t] = useTranslation();

  const setGoogleSSOUser = useSetRecoilState(currentGoogleSSOUserAtom);
  const [googleSSOLoading, setGoogleSSOLoading] =
    useRecoilState(googleSSOLoadingAtom);

  const handleSignInGoogleSSO = () => {
    storeItemInLocalStorage(GOOGLE_SSO_LOADING_LOCAL_STORAGE, 'true');
    setGoogleSSOLoading(true);

    signInWithGoogleSSORedirect(setGoogleSSOUser);
  };


  // Will sign up and redirect if Google SSO sign in function was called
  const registerGoogleSSO = async () => {
    try {
      if (getItemFromLocalStorage(GOOGLE_SSO_LOADING_LOCAL_STORAGE)) {
        await redirectWithGoogleSSOSignIn(setGoogleSSOUser);
      }
    } catch (error) {
      trackSentryErrorByTag(error, 'google-sso-sign-in-navigate-to-dashboard');
    }
  };

  // Will set the loading state of Google SSO spinner
  const getGoogleSSOLoading = () => {
    setGoogleSSOLoading(
      getItemFromLocalStorage(GOOGLE_SSO_LOADING_LOCAL_STORAGE),
    );
  };
  useEffect(() => {
    registerGoogleSSO();
  }, []);

  return (
    <>
      {googleSSOLoading ? (
        <CircularLoadingGoogleSso size="2.5rem" thickness={2.5} />
      ) : (
        <AmberPrimaryIconButton
          text={t('loginForm.continueWithGoogle')}
          icon={<GoogleLogo />}
          handleClick={handleSignInGoogleSSO}
          testid="google-sso-continue-btn"
          fullWidth
        />
      )}
    </>
  );
}
