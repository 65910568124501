/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  defaultClass: {
    width: '100%',
  },
}));

function AudioPlayer(props) {
  const { downloadUrl, className, ...rest } = props;
  const classes = useStyles();
  return (
    <audio
      controls
      controlsList="nodownload"
      tabIndex={-1}
      className={className || classes.defaultClass}
      {...rest}
    >
      <source src={downloadUrl} type="audio/wav" />
    </audio>
  );
}
AudioPlayer.propTypes = {
  className: PropTypes.string,
  downloadUrl: PropTypes.string.isRequired,
};
AudioPlayer.defaultProps = {
  className: null,
};
export default AudioPlayer;
