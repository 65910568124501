import React from 'react';
import { useState, createContext, useMemo } from 'react';
import CustomSnackbar from '../components/common/notification/customSnackbar';

export const SnackbarContext = createContext();

export function SnackbarProvider({ children }) {
  const [notification, setNotification] = useState({
    visible: false,
    variant: 'error',
    message: 'Error!',
  });

  const showNotification = (variant, message) => {
    setNotification({ visible: true, variant: variant, message: message });
  };
  const handleClose = () => {
    setNotification({ visible: false, variant: 'error', message: 'Error!' });
  };

  const notificator = useMemo(
    () => ({
      showError: (message) => showNotification('error', message || 'Error'),
      showInfo: (message) => showNotification('info', message),
      showSuccess: (message) =>
        showNotification('success', message || 'Success'),
      showWarning: (message) => showNotification('warning', message),
    }),
    [],
  );

  // const notificator = {
  //   showError: (message) => showNotification('error', message),
  //   showInfo: (message) => showNotification('info', message),
  //   showSuccess: (message) => showNotification('success', message),
  //   showWarning: (message) => showNotification('warning', message),
  // };

  return (
    <SnackbarContext.Provider value={notificator}>
      <CustomSnackbar
        handleClose={handleClose}
        visible={notification.visible}
        variant={notification.variant}
        message={notification.message}
      />
      {children}
    </SnackbarContext.Provider>
  );
}
