import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { DialogContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AssignmentIcon from '@material-ui/icons/Assignment';

import AmberOutlinedButton from '../../buttons/AmberOutlinedButton';
import AmberPrimaryButton from '../../buttons/AmberPrimaryButton';
import AmberDialog from '../dialogs/AmberDialog';
import AmberDialogActions from '../dialogs/AmberDialogActions';
import TabularContent from './tabularContents';
import DetailsTabPanelContents from './detailsTabPanelContents';
import HistoryNotesTabPanelContents from './historyNotesTabPanelContents';
import CircularIndeterminate from '../../loading/indeterminateSpinner';
import TranscriptTabPanelContents from './transcriptTabPanelContents';
import QualityCheckContents from './qualityCheckPanel';
import GlossaryPanel from './glossary/glossaryPanel';
import { getGlossaryById } from '../../../utils/api';

const getTabPanels = (job, media, qualityCheck, glossary) => {
  const { historyLog, notes, client, displayFileName, canEditNotes, ...rest } =
    job;
  const { contents } = media;

  return [
    <DetailsTabPanelContents jobDetails={rest} />,
    <HistoryNotesTabPanelContents
      history={historyLog}
      notes={notes}
      client={client}
      jobId={rest._id}
      canEditNotes={canEditNotes}
    />,
    <TranscriptTabPanelContents filename={displayFileName} media={contents} />,
    <QualityCheckContents qualityCheck={qualityCheck} />,
    <GlossaryPanel glossary={glossary} />,
  ];
};

const useStyles = makeStyles((theme) => ({
  dialogContents: {
    padding: '1em',
  },
  buttonRed: {
    color: theme.palette.custom.pinkishRed,
    border: `2px solid ${theme.palette.custom.pinkishRed}`,
    '&:hover': {
      border: `1px solid ${theme.palette.custom.pinkishRed}`,
      backgroundColor: 'rgba(50, 150, 120, 0.04)',
    },
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonsRight: {
    display: 'flex',
    flexDirection: 'row',
  },
  circularIndeterminate: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: '-1.5em 0 0 -1.5em',
  },
  dialogPaper: {
    minHeight: '70%',
    maxHeight: '70%',
  },
}));

function GenericDialogActions(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const {
    handleOnClickReport,
    handleClose,
    hasDetailsAction,
    buttonText,
    disableAction,
    shouldDialogLoad,
    handleOnClickAction,
  } = props;
  return (
    <>
      <AmberOutlinedButton
        text={t('qualityControlDashboard.reportIssue')}
        onClick={handleOnClickReport}
        classes={{ root: classes.buttonRed }}
      />
      <div className={classes.buttonsRight}>
        <AmberOutlinedButton
          text={t('common.button.close')}
          onClick={handleClose}
        />
        {hasDetailsAction && (
          <AmberPrimaryButton
            text={buttonText}
            disabled={disableAction || !shouldDialogLoad}
            onClick={handleOnClickAction}
          />
        )}
      </div>
    </>
  );
}

GenericDialogActions.propTypes = {
  buttonText: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleOnClickReport: PropTypes.func.isRequired,
  hasDetailsAction: PropTypes.bool,
  handleOnClickAction: PropTypes.func.isRequired,
  disableAction: PropTypes.bool.isRequired,
  shouldDialogLoad: PropTypes.bool,
};
GenericDialogActions.defaultProps = {
  hasDetailsAction: true,
  shouldDialogLoad: false,
};

function JobItemDialog(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const {
    job,
    open,
    handleClose,
    shouldDialogLoad,
    media,
    DialogActions,
    extraTabs,
    extraTabPanels,
    qualityCheck,
    tabIndex,
    ...rest
  } = props;

  const [glossary, setGlossary] = useState(null);

  // Get the glossary by id
  const getGlossary = () => {
    const { glossaryId } = job;
    if (glossaryId && glossaryId !== '') {
      getGlossaryById(glossaryId)
        .then((response) => {
          if (response && response.data) {
            const { data } = response;
            setGlossary({
              ...data,
            });
          }
        })
        .catch(() => {});
    }
  };

  const isGlossaryIdValid = () =>
    job && job.glossaryId && job.glossaryId !== '';

  useEffect(() => {
    if (isGlossaryIdValid()) {
      getGlossary();
    }
  }, [job.glossaryId]);

  const getTabs = useCallback(() => [
    { label: t('common.jobItemDialog.detailsTab'), icon: null },
    {
      label: t('common.jobItemDialog.historyNotesTab'),
      icon: <AssignmentIcon classes={{ root: classes.assignmentIcon }} />,
    },
    { label: t('common.jobItemDialog.transcriptTab'), icon: null },
    { label: t('common.jobItemDialog.qualityCheck'), icon: null },
    { label: t('common.glossary.glossaryTab'), icon: null },
  ]);

  return (
    <AmberDialog
      open={open}
      title={t('common.jobItemDialog.title')}
      fullWidth
      handleClose={handleClose}
      maxWidth="md"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent>
        {shouldDialogLoad && (
          <CircularIndeterminate
            size="3.6rem"
            thickness={3.6}
            className={classes.circularIndeterminate}
          />
        )}
        {!shouldDialogLoad && (
          <TabularContent
            tabIndex={tabIndex || 0}
            tabs={[...getTabs(), ...extraTabs]}
            tabPanelContents={[
              ...getTabPanels(job, media, qualityCheck, glossary),
              ...extraTabPanels,
            ]}
          />
        )}
      </DialogContent>
      <AmberDialogActions classes={{ root: classes.dialogActions }}>
        {!DialogActions && (
          <GenericDialogActions {...rest} handleClose={handleClose} />
        )}
        {DialogActions && <DialogActions job={job} {...rest} />}
      </AmberDialogActions>
    </AmberDialog>
  );
}

JobItemDialog.propTypes = {
  job: PropTypes.shape({
    _id: PropTypes.string,
    displayFileName: PropTypes.string,
    transcriptionType: PropTypes.string,
    recordId: PropTypes.string,
    qualityControlDeadline: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    nrAudioSeconds: PropTypes.number,
    language: PropTypes.string,
    numberOfSpeakers: PropTypes.number,
    workflowStyle: PropTypes.string,
    jobOptions: PropTypes.shape({
      transcriptionStyle: PropTypes.string,
    }),
    glossaryId: PropTypes.string,
  }),

  open: PropTypes.bool.isRequired,

  shouldDialogLoad: PropTypes.bool,
  media: PropTypes.shape({
    contents: PropTypes.shape({
      downloadUrl: PropTypes.string,
      transcript: PropTypes.shape({
        segments: PropTypes.arrayOf(PropTypes.object),
        speakers: PropTypes.arrayOf(PropTypes.object),
      }),
    }),
  }),
  DialogActions: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  extraTabs: PropTypes.arrayOf(PropTypes.object),
  extraTabPanels: PropTypes.arrayOf(PropTypes.node),
  qualityCheck: PropTypes.objectOf(PropTypes.any),
  tabIndex: PropTypes.number,
};

JobItemDialog.defaultProps = {
  shouldDialogLoad: true,
  job: {
    jobOptions: {},
    glossaryId: '',
  },
  media: {},
  DialogActions: null,
  extraTabs: [],
  extraTabPanels: [],
  qualityCheck: {},
  tabIndex: 0,
};
export default JobItemDialog;
