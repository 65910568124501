import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

function CircularIndeterminate(props) {
  const classes = useStyles();
  const { className, ...rest } = props;

  return (
    <div className={className || classes.root}>
      <CircularProgress {...rest} />
    </div>
  );
}
CircularIndeterminate.propTypes = {
  className: PropTypes.string,
};
CircularIndeterminate.defaultProps = {
  className: '',
};
export default CircularIndeterminate;
