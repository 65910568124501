import React, { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';

import Switch from './switch';
import AmberSectionTitle from '../../labels/AmberSectionTitle';
import {
  QUALITY_CHECKER_OPEN_VIEW,
  QUALITY_CHEKER_COMPLETED_VIEW,
} from '../../../constants/constants';
import {
  currentViewAtom,
  getNumberOfOpenActiveJobsSelector,
  getNumberOfCompletedJobsSelector,
} from '../../../store/qualityControl';

const useStyles = makeStyles((theme) => ({
  switchContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.custom.darkGrey}`,
    boxSizing: 'border-box',
    borderRadius: '0.2em',
    flex: '0 1 10%',
    margin: '0 1em 1em 0',
    background: theme.palette.primary.white,
    height: 'fit-content',
  },
  switchTitle: {
    padding: '1em 1em 1em 1em',
    fontSize: '0.8em',
    color: theme.palette.custom.purplishGray,
    margin: 0,
  },
}));

function SwitcherList(props) {
  const classes = useStyles();
  const [t] = useTranslation();

  const [currentView, setCurrentView] = useRecoilState(currentViewAtom);
  const numberOfOpenActiveJobs = useRecoilValue(
    getNumberOfOpenActiveJobsSelector,
  );
  const numberOfCompletedJobs = useRecoilValue(
    getNumberOfCompletedJobsSelector,
  );
  const onClick = useCallback(
    (selectedView) => {
      setCurrentView(selectedView);
    },
    [setCurrentView],
  );
  return (
    <div className={classes.switchContainer}>
      <AmberSectionTitle
        text={t('qualityControlDashboard.sideButtonsTitle')}
        typographyClasses={{ root: classes.switchTitle }}
        margin={0}
      />
      <Divider />
      {[QUALITY_CHECKER_OPEN_VIEW, QUALITY_CHEKER_COMPLETED_VIEW].map(
        (view) => {
          switch (view) {
            case QUALITY_CHECKER_OPEN_VIEW:
              return (
                <Switch
                  key={view}
                  view={view}
                  currentView={currentView}
                  onClick={onClick}
                  numberOfJobs={numberOfOpenActiveJobs}
                />
              );
            case QUALITY_CHEKER_COMPLETED_VIEW:
              return (
                <Switch
                  key={view}
                  view={view}
                  currentView={currentView}
                  onClick={onClick}
                  numberOfJobs={numberOfCompletedJobs}
                />
              );
            default:
              return null;
          }
        },
      )}
    </div>
  );
}

export default SwitcherList;
