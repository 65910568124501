import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { DialogContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import AmberOutlinedButton from '../../buttons/AmberOutlinedButton';
import AmberPrimaryButton from '../../buttons/AmberPrimaryButton';
import AmberDialog from '../dialogs/AmberDialog';
import AmberDialogActions from '../dialogs/AmberDialogActions';
import JobReportingContents from './jobReportingContents';
import JOB_FIELDS from './jobFieldsConstants';

const useStyles = makeStyles(() => ({
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dialogPaper: {
    minHeight: '50%',
    maxHeight: '50%',
  },
}));
function JobReportingDialog(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const [notes, setNotes] = useState(undefined);
  const [issueType, setIssueType] = useState(undefined);
  const [canSubmit, setCanSubmit] = useState(false);
  const {
    open,
    handleClose,
    handleOnClickAction,
    job: { status },
  } = props;
  useEffect(() => {
    if (notes && notes.trim() && issueType) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [notes, issueType]);

  const handleClick = useCallback(() => {
    if (!notes.trim() || !issueType) {
      return;
    }
    const { value } = issueType;
    handleOnClickAction(value, notes);
  });

  const handleChange = useCallback((event) => {
    const { id, value } = event;
    switch (id) {
      case JOB_FIELDS.NOTES:
        setNotes(value);
        break;
      case JOB_FIELDS.ISSUE_TYPE:
        setIssueType(value);
        break;
      default:
        break;
    }
  });

  return (
    <AmberDialog
      open={open}
      title={t('dialogIssueReport.title')}
      fullWidth
      handleClose={handleClose}
      maxWidth="sm"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent>
        <JobReportingContents
          handleChange={handleChange}
          notes={notes}
          issueType={issueType}
          jobStatus={status}
        />
      </DialogContent>
      <AmberDialogActions classes={{ root: classes.dialogActions }}>
        <AmberOutlinedButton
          text={t('common.button.close')}
          onClick={handleClose}
        />
        <AmberPrimaryButton
          text={t('common.button.reportIssue')}
          onClick={handleClick}
          disabled={!canSubmit}
        />
      </AmberDialogActions>
    </AmberDialog>
  );
}
JobReportingDialog.propTypes = {
  handleOnClickAction: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  job: PropTypes.shape({ status: PropTypes.string }).isRequired,
};

export default JobReportingDialog;
