import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import { BUSY, ERROR, PERFECT_EDITING } from '../constants/jobStatuses';
import { ADMIN, QUALITY_CHECKER, TRANSCRIBER } from '../roles';

import {
  exportHighQualityVideo,
  exportJsonFormat,
  exportLatestSrt,
  exportLatestStl,
  exportLatestVtt,
  exportLowQualityVideo,
  exportOrDownloadDocxOrRtf,
  exportTranscriberLatestSRT,
  exportTxt,
  getAudioLink,
  getAvailableExports,
  getJobDetails,
  getQualityChecks,
  getTranscript,
  reportQCJob,
  reportTranscriberJob,
} from '../utils/api';
import {
  CAN_EDIT_NOTES,
  CURRENT_JOB_DETAILS_ADMIN_DASHBOARD_ATOM,
  CURRENT_JOB_DETAILS_ADMIN_DASHBOARD_DATA,
  CURRENT_JOB_DETAILS_ADMIN_DASHBOARD_EXPORT_OPTIONS,
  CURRENT_JOB_QUALITY_CHECK_OBJECTS,
  CURRENT_JOB_QUALITY_CHECK_OBJECTS_WRITABLE,
  CURRENT_JOB_TRANSCRIPT,
  REFRESH_ADMIN_TABLE,
  REPORT_FUNCTION_ACTIONS,
} from './constants';
import { currentUserRoleAtom } from './user';
import {
  DOC_OPTION,
  EBU_STL_OPTION,
  HQ_VIDEO_OPTION,
  JSON_OPTION,
  LQ_VIDEO_OPTION,
  RTF_OPTION,
  SRT_OPTION,
  SRT_SOURCE_OPTION,
  TXT_OPTION,
  VTT_OPTION,
} from './contractOptions';

export const refreshTableAtom = atom({
  key: REFRESH_ADMIN_TABLE,
  default: { func: null },
});

export const getQualityChecksSelectorFamily = selectorFamily({
  key: CURRENT_JOB_QUALITY_CHECK_OBJECTS,
  get: (jobId) => async () => {
    const qualityChecks = await getQualityChecks(null, null, jobId, 0, 5);
    return qualityChecks.data.content;
  },
});

const getExportActionsAndKeys = (option, role) => {
  switch (option) {
    case SRT_OPTION:
      return {
        func:
          TRANSCRIBER === role ? exportTranscriberLatestSRT : exportLatestSrt,
        useRecordId: false,
        buttonKey: 'exportLatestSrt',
      };
    case LQ_VIDEO_OPTION:
      return {
        func: exportLowQualityVideo,
        useRecordId: true,
        buttonKey: 'exportLowQualityVideo',
      };
    case SRT_SOURCE_OPTION:
      return {
        func:
          TRANSCRIBER === role ? exportTranscriberLatestSRT : exportLatestSrt,
        useRecordId: false,
        useSourceJobId: true,
        buttonKey: 'exportSourceSRT',
      };

    case DOC_OPTION:
      return {
        func: async (jobId) =>
          exportOrDownloadDocxOrRtf(jobId, DOC_OPTION.toLowerCase()),
        useRecordId: false,
        buttonKey: 'exportSubAsWord',
      };
    case HQ_VIDEO_OPTION:
      return {
        func: exportHighQualityVideo,
        useRecordId: true,
        useTranscript: true,
        buttonKey: 'exportHighQualityVideo',
      };
    case JSON_OPTION:
      return {
        func: exportJsonFormat,
        useRecordId: false,
        buttonKey: 'exportJsonFormat',
      };
    case VTT_OPTION:
      return {
        func: exportLatestVtt,
        useRecordId: false,
        buttonKey: 'exportLatestVtt',
      };
    case TXT_OPTION:
      return { func: exportTxt, useRecordId: false, buttonKey: 'exportTxt' };
    case EBU_STL_OPTION:
      return {
        func: exportLatestStl,
        useRecordId: false,
        buttonKey: 'exportLatestStl',
      };
    case RTF_OPTION:
      return {
        func: async (jobId) =>
          exportOrDownloadDocxOrRtf(jobId, RTF_OPTION.toLowerCase()),
        useRecordId: false,
        buttonKey: 'exportSubAsRTF',
      };
    default:
      return {};
  }
};

export const getExportOptionsSelectorFamily = selectorFamily({
  key: CURRENT_JOB_DETAILS_ADMIN_DASHBOARD_EXPORT_OPTIONS,
  get:
    (jobId) =>
    async ({ get }) => {
      const roles = get(currentUserRoleAtom);
      if (!roles || roles.length > 1 || !jobId) {
        return null;
      }
      const exports = await getAvailableExports(jobId, roles[0]);
      const exportOptions = exports?.availableExports
        ?.map((option) => getExportActionsAndKeys(option, roles[0]))
        .filter((item) => !!item.func);

      return {
        exportOptions,
        editorEnabled: exports?.editorEnabled,
        uploadOptions: exports?.availableUploads,
      };
    },
});

//  fixme tabValue is only used to invalidate the cache. Find another unique identifiers here.
export const getJobDetailsSelectorFamily = selectorFamily({
  key: CURRENT_JOB_DETAILS_ADMIN_DASHBOARD_DATA,
  get:
    ({ jobId, tabValue }) =>
    async () => {
      let jobStateData = {};
      if (jobId) {
        await Promise.all([getAudioLink(jobId), getJobDetails(jobId)]).then(
          ([audioLink, jobDetails]) => {
            const {
              data: { jobStatus },
            } = jobDetails;

            jobStateData = {
              media: { audioLink: audioLink.data },
              jobDetails: {
                ...jobStatus,
              },
            };
          },
        );
      }

      return Object.keys(jobStateData).length ? jobStateData : null;
    },
  set:
    () =>
    ({ set }, newValue) => {
      set(jobDetailsAtomFamily(newValue.jobDetails._id), newValue);
    },
});
export const jobDetailsAtomFamily = atomFamily({
  key: CURRENT_JOB_DETAILS_ADMIN_DASHBOARD_ATOM,
  default: getJobDetailsSelectorFamily,
});
export const qualityChecksAtomFamily = atomFamily({
  key: CURRENT_JOB_QUALITY_CHECK_OBJECTS_WRITABLE,
  default: getQualityChecksSelectorFamily,
});

export const jobTranscriptSelectorFamily = selectorFamily({
  key: CURRENT_JOB_TRANSCRIPT,
  get:
    ({ jobId, tabValue }) =>
    async ({ get }) => {
      const jobStateData = get(jobDetailsAtomFamily({ jobId, tabValue }));
      let transcript = null;
      if (
        jobStateData &&
        jobStateData.jobDetails.jobStatus !== BUSY &&
        jobStateData.jobDetails.jobStatus !== ERROR
      ) {
        const transcriptResult = await getTranscript(jobId);
        transcript = transcriptResult.data;
      }
      return transcript;
    },
});
export const canEditNotesSelectorFamily = selectorFamily({
  key: CAN_EDIT_NOTES,
  get:
    ({ jobId, tabValue }) =>
    async ({ get }) => {
      if (!jobId) {
        return false;
      }
      if (tabValue) {
        return false;
      }
      const currentUserRole = get(currentUserRoleAtom);
      const currentJob = await get(jobDetailsAtomFamily({ jobId, tabValue }));
      const currentJobDetails = currentJob?.jobDetails;
      if (!currentJobDetails?.jobStatus && currentUserRole?.length !== 1) {
        return false;
      }
      switch (currentUserRole[0]) {
        case ADMIN:
          return true;
        case TRANSCRIBER:
          return currentJobDetails.jobStatus === PERFECT_EDITING;
        default:
          return false;
      }
    },
});

export const getReportingAction = selector({
  key: REPORT_FUNCTION_ACTIONS,
  get: ({ get }) => {
    const currentUserRole = get(currentUserRoleAtom);
    switch (currentUserRole[0]) {
      case TRANSCRIBER:
        return reportTranscriberJob;
      case QUALITY_CHECKER:
        return reportQCJob;
      default:
        return null;
    }
  },
});
