import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { createGoogleSSOSessionCookie } from '../utils/googleSSO';
import { setupFirebaseAuthentication } from './firebaseAuthentication';
import { storeGoogleSSOUserDetailsToLocalStorage } from '../utils/apiHelper';
import { getUser } from '../utils/api';
import { trackSentryErrorByTag } from '../sentry/common';
/**
 * Creates the Google SSO cookie after signing in with the redirect method
 * @returns {Promise<object>} isNewUser
 */
export async function getGoogleSSOResult(
  userCredential = null,
  setGoogleSSOUser,
) {
  try {
    if (userCredential) {
      GoogleAuthProvider.credentialFromResult(userCredential);

      const { user } = userCredential;

      const idToken = await user.getIdToken();
      await createGoogleSSOSessionCookie(idToken);

      // Gets the user details (username, roles)
      const loggedUser = await getUser();
      if (loggedUser?.data) {
        const { userName, roles } = loggedUser.data;
        storeGoogleSSOUserDetailsToLocalStorage(userName, idToken, roles);
        setGoogleSSOUser(loggedUser.data);
      }
    }
    return null;
  } catch (error) {
    trackSentryErrorByTag(error, 'google-sso-sign-in-redirect-result');
    throw error;
  }
}

/**
 * Redirects to the dashboard if signing in successfully
 */
export async function redirectWithGoogleSSOSignIn(cred, setGoogleSSOUser) {
  try {
    await setupFirebaseAuthentication();
    await getGoogleSSOResult(cred, setGoogleSSOUser);
  } catch (error) {
    trackSentryErrorByTag(error, 'google-sso-sign-in-navigate-to-dashboard');
    throw error;
  }
}

/**
 * Redirects to the Google SSO page
 */
export async function signInWithGoogleSSORedirect(setGoogleSSOUser) {
  try {
    await setupFirebaseAuthentication();
    const provider = new GoogleAuthProvider();
    const auth = getAuth();

    const cred = await signInWithPopup(auth, provider);
    return await redirectWithGoogleSSOSignIn(cred, setGoogleSSOUser);
  } catch (error) {
    trackSentryErrorByTag(error, 'google-sso-sign-in-redirect');
    return null;
  }
}
/*
export async function signInWithGoogleSSORedirect() {
  try {
    await initializeFirebase();
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    // Redirects to Sign in with Google account
    await signInWithRedirect(auth, provider);
  } catch (error) {
    trackSentryErrorByTag(error, 'google-sso-sign-in-redirect');
  }
}
  */
