import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LangMenu from '../components/header/LangMenu';
import ProfileMenu from '../components/header/ProfileMenu';
import Logo from '../components/ui/Logo';
import AmberOutlinedButton from "../components/buttons/AmberOutlinedButton";
import {useHistory} from "react-router-dom";
import {getRoles} from "../utils/auth";
import { QUALITY_CHECKER } from '../roles';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1000,
    display: 'flex',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: theme.mixins.toolbar.minHeight,
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.background.appBar,
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  middle: {},
  right: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
    fontSize: 20,
    textAlign: 'center',
    color: theme.palette.primary.default,
    padding: theme.mixins.gutters(0, theme.spacing(1), 0, theme.spacing(2)),
  },
  [theme.breakpoints.up('xs')]: {
    title: {
      fontSize: 26,
    },
  },
}));
let isQC = false;
function Header(props) {
  const { account, resetRecoilState } = props;
  const classes = useStyles();
  const history = useHistory();
  const roles = getRoles();
  isQC = roles.includes(QUALITY_CHECKER);
  const [t] = useTranslation();
  const isAdminOrPM = () => {
    return roles.includes("ADMIN") || roles.includes("PERFECT_JOB_MANAGER");
  };


  const handleDashboardClick = () => {
    history.push('/transcriber/dashboard');
  };
  const handleInvoicesClick = () => {   
    if (isQC) { history.push('/quality-controller/invoices');}
    else {  history.push('/transcriber/invoices'); }
  };

  return (
    <header id="Header" className={classes.root}>
      <div className={classes.left}>
        <Logo tooltip="Open jobs" />
        {!isAdminOrPM() && (
            <>
              <AmberOutlinedButton
                  fullWidth
                  text={'Dashboard'}
                  onClick={handleDashboardClick}
                  style={{ marginRight: '8px' }}
              />
              <AmberOutlinedButton
                  fullWidth
                  text=  {isQC ? t('transcriberDashBoard.CheckedJobsTitle') : t('transcriberDashBoard.approvedJobsTitle')}
                  onClick={handleInvoicesClick}
              />
            </>
        )}
      </div>
      <div className={classes.right}>
        <LangMenu account={account} />
        <ProfileMenu
          user={account}
          history={history}
          resetRecoilState={resetRecoilState}
        />
      </div>
    </header>
  );
}

Header.propTypes = {
  account: PropTypes.objectOf(PropTypes.any),
  resetRecoilState: PropTypes.func,
  history: PropTypes.objectOf(PropTypes.any),
};

Header.defaultProps = {
  account: null,
  resetRecoilState: null,
  history: null,
};
export default Header;
