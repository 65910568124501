import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import SelectAutosuggest from '../common/fields/selectautosuggest';

export default function AssignDialogManagers(props) {
  const { open, handleAssign, managersList, selectedjobIds } = props;
  const [selectedManager, setSelectedManager] = React.useState(null);
  const [t, i18n] = useTranslation();

  function assignHandler() {
    handleAssign(selectedjobIds, selectedManager.value);
    handleClose();
  }

  function handleSelectTranscriber(value) {
    setSelectedManager(value);
  }

  function handleClose() {
    props.handleClose();
    setSelectedManager(null);
  }

  function getManagersList() {
    const suggestions = [
      {
        value: null,
        label: 'none',
      },
    ];
    for (const m of managersList) {
      suggestions.push({
        value: m,
        label: m,
      });
    }
    return suggestions;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="assign-dialog-title"
      fullWidth={true}
    >
      <DialogTitle id="assign-dialog-title">
        {t('managerDashboard.assignDialog.titleManagers')}
      </DialogTitle>
      <DialogContent>
        <SelectAutosuggest
          isMulti={false}
          label="Manager"
          value={selectedManager}
          onChange={handleSelectTranscriber}
          placeholder="Select manager"
          suggestions={getManagersList()}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t('common.button.close')}
        </Button>
        <Button
          onClick={assignHandler}
          disabled={selectedManager === null || selectedManager.length === 0}
          color="primary"
        >
          {t('common.button.assign')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AssignDialogManagers.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAssign: PropTypes.func.isRequired,
  transcribers: PropTypes.arrayOf(PropTypes.string),
  selectedjobIds: PropTypes.array.isRequired,
};
