import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import { completedJobsAtom } from '../../../../store/qualityControl';
import withQualityControlLoading from '../../../../containers/withQualityControlLoading';
import CompletedJob from './completedJob';
import AmberSimpleCollapse from '../../../collapse/AmberSimpleCollapse';
import CollapseControl from '../collapseControl';
import { RECOIL_LOADABLE_LOADING_STATE } from '../../../../constants/constants';

const useStyles = makeStyles(() => ({
  root: { display: 'flex', flexDirection: 'column' },
  completedJobsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 1 90%',
  },
}));

function OpenJobList({ setIsLoading }) {
  const [t] = useTranslation();
  const completedJobs = useRecoilValueLoadable(completedJobsAtom);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    setIsLoading(completedJobs.state === RECOIL_LOADABLE_LOADING_STATE);
  }, [completedJobs]);

  const handleCollapse = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  });

  return (
    completedJobs.state !== RECOIL_LOADABLE_LOADING_STATE && (
      <div className={classes.completedJobsContainer}>
        <CollapseControl
          handleCollapse={handleCollapse}
          isCollapsed={isCollapsed}
          collapseControlTitle={t(
            'qualityControlDashboard.collapsibleCompletedListTitle',
          )}
        />
        <AmberSimpleCollapse
          onClick={handleCollapse}
          open={isCollapsed}
          wrapperClasses={classes.root}
          title={t('qualityControlDashboard.collapsibleListTitle')}
        >
          {completedJobs.contents.map((completedJob) => (
            <CompletedJob job={completedJob} key={completedJob.recordId} />
          ))}
        </AmberSimpleCollapse>
      </div>
    )
  );
}

OpenJobList.propTypes = {
  setIsLoading: PropTypes.func.isRequired,
};

export default withQualityControlLoading(OpenJobList);
