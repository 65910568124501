import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

function JobEmptyState(props) {
  const { stringBold, stringSecondary } = props;

  return (
    <Box border="2px dashed lightGray" display="flex" p={4} mb={4}>
      <Box fontWeight="bold" mr={0.5} data-testid="job-empty-string1">
        {stringBold}
      </Box>
      <Box data-testid="job-empty-string2">{stringSecondary}</Box>
    </Box>
  );
}

export default JobEmptyState;

JobEmptyState.propTypes = {
  stringBold: PropTypes.string.isRequired,
  stringSecondary: PropTypes.string.isRequired,
};
