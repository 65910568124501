import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from '@material-ui/core';
import SelectAutosuggest from '../common/fields/selectautosuggest';
import { useTranslation } from 'react-i18next';

export default function AssignDialog(props) {
  const {
    open,
    handleAssign,
    transcribersOptions,
    selectPlaceholder,
    selectedjobIds,
    dialogTitle,
    selectorHelper,
    handleSetSkipQualityControl,
    isQC
  } = props;
  const [selectedTranscriber, setSelectedTranscriber] = React.useState(null);
  const [t, i18n] = useTranslation();

  function assignHandler() {
    handleAssign(selectedTranscriber.value, selectedjobIds);
    handleClose();
  }

  function handleSelectTranscriber(value) {
    setSelectedTranscriber(value);
  }

  function handleClose() {
    props.handleClose();
    setSelectedTranscriber(null);
  }
  function handleSkipQC(){
    handleSetSkipQualityControl(selectedjobIds, true, true)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="assign-dialog-title"
      fullWidth={true}
    >
      <DialogTitle id="assign-dialog-title">
        {dialogTitle || t('managerDashboard.assignDialog.titleTranscriber')}
      </DialogTitle>
      <DialogContent>
        <SelectAutosuggest
          isMulti={false}
          label={selectorHelper || 'Transcriber'}
          value={selectedTranscriber}
          onChange={handleSelectTranscriber}
          placeholder={selectPlaceholder || 'Select transcriber'}
          suggestions={transcribersOptions.map((suggestion) => ({
            value: suggestion,
            label: suggestion,
          }))}
        />
      </DialogContent>
      <DialogActions>
        {isQC && (
          <Box sx={{ flexGrow: 1 }}>
            <Button onClick={handleSkipQC} color="primary">
              {t('common.button.skipQualityControl')}
            </Button>
          </Box>
        )}
        <Button onClick={handleClose} color="secondary">
          {t('common.button.close')}
        </Button>
        <Button
          onClick={assignHandler}
          disabled={
            selectedTranscriber === null || selectedTranscriber.length === 0
          }
          color="primary"
        >
          {t('common.button.assign')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AssignDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAssign: PropTypes.func.isRequired,
  transcribers: PropTypes.arrayOf(PropTypes.string),
  selectedjobIds: PropTypes.array.isRequired,
};
