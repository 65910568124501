import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { de, en, nl } from './assets/locales';

const options = {
  interpolation: {
    escapeValue: false, // not needed for react!!
  },

  debug: true,

  resources: {
    nl: {
      common: nl.nl,
    },
    en: {
      common: en.en,
    },
    de: {
      common: de.de,
    },
  },

  fallbackLng: 'en',

  ns: ['common'],

  defaultNS: 'common',

  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default',
  },
};

i18n
  .use(initReactI18next) // bind react-i18next to the instance
  .init(options);

export default i18n;
