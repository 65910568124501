import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';

export default function UploadConfirmationModal(props) {
  const { open, setOpen, onOkayClick, title, description } = props;

  const [t, i18n] = useTranslation();

  function handleClose() {
    setOpen(false);
  }

  function handleStartUploading() {
    handleClose();
    onOkayClick();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t('managerDashboard.table.resetStatus.cancel')}
        </Button>
        <Button
          onClick={handleStartUploading}
          color="primary"
          variant="contained"
          autoFocus
        >
          {t('managerDashboard.table.resetStatus.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
