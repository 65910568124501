import { createTheme, responsiveFontSizes } from '@material-ui/core';

import {
  AMBER_PRIMARY,
  AMBER_SECONDARY,
  AMBER_RED_COLOR,
  AMBER_INFO_GRAY,
  PIG_PINK,
  CHARCOAL_GRAY,
  AMBER_LIGHT_GRAY,
  PURPLISH_GRAY,
  DARK_GREEN_COLOR,
  GREEN_COLOR,
  DARK_GRAY_COLOR,
  PINKISH_RED,
  WHITE_TWO,
  WHITE,
  LIGHER_GRAY,
  BLACK,
  MANGO,
  LIGHT_GRAY,
  LIGHT_MANGO,
} from '../../constants/colours';

const spacing = 8;

const theme = responsiveFontSizes(
  createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    app: {
      footerHeight: 48,
    },
    background: {
      appBar: '#f7f7f7',
    },
    palette: {
      primary: {
        main: AMBER_PRIMARY,
        white: WHITE,
        black: BLACK,
      },
      secondary: {
        main: AMBER_SECONDARY,
      },
      error: {
        main: AMBER_RED_COLOR,
      },
      success: {
        main: GREEN_COLOR,
      },
      custom: {
        pinkishRed: PINKISH_RED,
        pigPink: PIG_PINK,
        charcoalGray: CHARCOAL_GRAY,
        infoMessage: AMBER_INFO_GRAY,
        lightGray: AMBER_LIGHT_GRAY,
        purplishGray: PURPLISH_GRAY,
        darkGreenColor: DARK_GREEN_COLOR,
        darkGrey: DARK_GRAY_COLOR,
        basicLightGray: LIGHT_GRAY,
        lighterGray: LIGHER_GRAY,
        whiteTwo: WHITE_TWO,
        mango: MANGO,
        lightMango: LIGHT_MANGO,
      },
    },
    mixins: {
      toolbar: {
        minHeight: 64,
      },
    },
    overrides: {
      MuiButton: {
        root: {
          border: 0,
          boxShadow: 'none',
          textTransform: 'none',
        },
      },
      MuiFormControl: {
        root: {
          marginBottom: spacing * 4,
        },
      },
    },
  }),
);

export default theme;
