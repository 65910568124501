import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Item from './item';

export default function Items(props) {
  const { items } = props;

  const [t] = useTranslation();

  const itemsNotEmpty = () => items && items.length !== 0;

  return (
    <>
      {itemsNotEmpty() && (
        <Box display="flex" flexDirection="column" gridRowGap={12}>
          <span>{t('common.glossary.terms')}</span>
          <Box
            display="flex"
            flexDirection="column"
            gridRowGap={4}
            padding={2}
            border="1px solid #c4c4c4"
            borderRadius={4}
          >
            {itemsNotEmpty() &&
              items.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Item item={item} key={`glossary-item-${index}`} />
              ))}
          </Box>
        </Box>
      )}
    </>
  );
}

Items.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
  ).isRequired,
};
