import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useRecoilState,
  useRecoilStateLoadable,
  useRecoilValue,
  useRecoilValueLoadable,
} from 'recoil';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import useSnackbar from '../../utils/useSnackbar';
import IssueReports from '../adminPanels/issueReportsPanel';
import {
  RECOIL_LOADABLE_DONE_STATE,
  RECOIL_LOADABLE_ERROR_STATE,
  RECOIL_LOADABLE_LOADING_STATE,
} from '../../constants/constants';
import {
  canEditNotesSelectorFamily,
  jobDetailsAtomFamily,
  jobTranscriptSelectorFamily,
  qualityChecksAtomFamily,
  refreshTableAtom,
} from '../../store/jobManagerDashboard';
import { currentUserLoginDetailsAtom } from '../../store/user';
import { getUTCEpochTime } from '../../utils/timeUtils';

import JobItemDialog from '../../components/common/job_item_dialog/jobItemDialog';
import { ADMIN, PERFECT_JOB_MANAGER } from '../../roles';
import ExportComponents from './exportComponents';
import JobReportingDialog from '../../components/common/job_report_dialog/jobReportDialog';
import FileReportAction from './fileReportAction';

const parseRole = (role) => {
  if (Array.isArray(role) && role.length === 1) {
    return role[0];
  }
  return null;
};

const getTabPanels = (job, role) => {
  const { jobIssueComments } = job;
  const roleString = parseRole(role);
  switch (roleString) {
    case PERFECT_JOB_MANAGER:
    case ADMIN:
      return [<IssueReports issueReports={jobIssueComments} />];
    default:
      return [];
  }
};

const getTabs = (t, role) => {
  const roleString = parseRole(role);
  switch (roleString) {
    case PERFECT_JOB_MANAGER:
    case ADMIN:
      return [{ label: t('common.jobItemDialog.issueReports'), icon: null }];
    default:
      return [];
  }
};

const getStateValues = (recoilState) => {
  if (recoilState.state === RECOIL_LOADABLE_DONE_STATE) {
    return recoilState.contents;
  }
  return null;
};

const getJobDetailsValues = (jobDetails, canEditNotes, glossaryId) => {
  const jobDetailsContents = getStateValues(jobDetails);
  const canEditNotesContents = getStateValues(canEditNotes);
  if (jobDetailsContents && canEditNotesContents !== null) {
    const detailsDeepClone = JSON.parse(JSON.stringify(jobDetailsContents));
    detailsDeepClone.jobDetails.canEditNotes = canEditNotesContents;

    detailsDeepClone.jobDetails.glossaryId = glossaryId;
    return detailsDeepClone;
  }
  return null;
};

const useStyles = makeStyles(() => ({
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

const DialogActions = (props) => {
  const { job, tabValue, handleOnClickReport } = props;
  const classes = useStyles();
  return (
    <div className={classes.actionsContainer}>
      <ExportComponents job={job} tabValue={tabValue} />
      {handleOnClickReport && (
        <FileReportAction handleOnClickReport={handleOnClickReport} />
      )}
    </div>
  );
};

DialogActions.propTypes = {
  job: PropTypes.shape({
    _id: PropTypes.string,
    recordId: PropTypes.string,
    transcriptionType: PropTypes.string,
    sourceJobId: PropTypes.string,
  }),
  tabValue: PropTypes.number,
  handleOnClickReport: PropTypes.func.isRequired,
};
DialogActions.defaultProps = {
  job: {},
  tabValue: 0,
};

function JobDetailsContainer(props) {
  const {
    closeJobDetailsHandler,
    jobId,
    openDialog,
    refreshJobsTable,
    tabValue,
    glossaryId,
    handleOnClickReport,
    tabIndex,
  } = props;
  const { showError } = useSnackbar();
  const [t] = useTranslation();
  const [isReportingOpen, setReportingOpen] = useState(false);

  const handleOpenReport = () => {
    setReportingOpen(true);
  };
  const handleCloseReporting = () => {
    setReportingOpen(false);
  };
  const handleReport = (issueType, note) => {
    setReportingOpen(false);
    if (openDialog) {
      closeJobDetailsHandler();
    }
    handleOnClickReport(jobId, issueType, note);
  };

  const [jobDetails, setNewJobDetails] = useRecoilStateLoadable(
    jobDetailsAtomFamily({ jobId, tabValue }),
  );
  const jobTranscript = useRecoilValueLoadable(
    jobTranscriptSelectorFamily({ jobId, tabValue }),
  );
  const qualityCheck = useRecoilValueLoadable(qualityChecksAtomFamily(jobId));

  const [, setRefreshFunction] = useRecoilState(refreshTableAtom);
  const currentUserDetails = useRecoilValue(currentUserLoginDetailsAtom);
  const canEditNotes = useRecoilValueLoadable(
    canEditNotesSelectorFamily({ jobId, tabValue }),
  );

  const [shouldDialogLoad, setShouldDialogLoad] = useState(true);

  const handleStateUpdate = (notes) => {
    const newJob = JSON.parse(JSON.stringify(jobDetails.contents));
    if (newJob.jobDetails.historyLog) {
      newJob.jobDetails.historyLog.push({
        message: `Changed notes to ${notes}`,
        created: getUTCEpochTime(),
        userName: currentUserDetails.userName,
      });
    }

    newJob.jobDetails.notes = notes;

    setNewJobDetails({ ...newJob });
    if (refreshJobsTable) {
      refreshJobsTable();
    }
  };

  const isDataLoading = () =>
    jobDetails.state === RECOIL_LOADABLE_LOADING_STATE ||
    qualityCheck.state === RECOIL_LOADABLE_LOADING_STATE ||
    jobTranscript.state === RECOIL_LOADABLE_LOADING_STATE ||
    canEditNotes.state === RECOIL_LOADABLE_LOADING_STATE;

  const showDataError = () => {
    if (jobDetails.state === RECOIL_LOADABLE_ERROR_STATE) {
      showError(jobDetails.contents.response.data.message);
    }
    if (qualityCheck.state === RECOIL_LOADABLE_ERROR_STATE) {
      showError(qualityCheck.contents.response.data.message);
    }
    if (jobTranscript.state === RECOIL_LOADABLE_ERROR_STATE) {
      showError(jobTranscript.contents.response.data.message);
    }
    if (canEditNotes.state === RECOIL_LOADABLE_ERROR_STATE) {
      showError(canEditNotes?.contents?.response?.data?.message);
    }
  };

  useEffect(() => {
    setShouldDialogLoad(isDataLoading());
    if (!isDataLoading()) {
      setRefreshFunction({ func: handleStateUpdate });
    }
    showDataError();
  }, [jobDetails, qualityCheck, jobTranscript, canEditNotes]);

  const getJobForQualityReport = () => {
    const job = getJobDetailsValues(
      jobDetails,
      canEditNotes,
      glossaryId,
    )?.jobDetails;

    if (!job) {
      return null;
    }
    return {
      status: job.jobStatus,
    };
  };

  return (
    <div>
      <JobItemDialog
        job={
          getJobDetailsValues(jobDetails, canEditNotes, glossaryId)?.jobDetails
        }
        open={openDialog}
        handleClose={closeJobDetailsHandler}
        shouldDialogLoad={shouldDialogLoad}
        media={{
          contents: {
            ...getStateValues(jobDetails)?.media,
            transcript: getStateValues(jobTranscript),
          },
        }}
        DialogActions={DialogActions}
        handleOnClickReport={handleOnClickReport ? handleOpenReport : null}
        qualityCheck={qualityCheck.contents}
        extraTabs={getTabs(t, currentUserDetails.role)}
        extraTabPanels={getTabPanels(
          jobDetails.contents.jobDetails || {},
          currentUserDetails.role,
        )}
        tabIndex={tabIndex}
      />
      {getJobForQualityReport() && (
        <JobReportingDialog
          handleClose={handleCloseReporting}
          handleOnClickAction={handleReport}
          open={isReportingOpen}
          job={getJobForQualityReport()}
        />
      )}
    </div>
  );
}

export default JobDetailsContainer;

JobDetailsContainer.propTypes = {
  jobId: PropTypes.string.isRequired,
  closeJobDetailsHandler: PropTypes.func.isRequired,
  openDialog: PropTypes.bool,
  refreshJobsTable: PropTypes.func,
  tabValue: PropTypes.number.isRequired,
  glossaryId: PropTypes.string,
  tabIndex: PropTypes.number,
  handleOnClickReport: PropTypes.func,
};

JobDetailsContainer.defaultProps = {
  openDialog: false,
  refreshJobsTable: null,
  glossaryId: null,
  tabIndex: 0,
  handleOnClickReport: null,
};
