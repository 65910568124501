import React, { useState, useEffect } from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Box, Container, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getApprovedJobs } from '../utils/api';
import { getRoles, getUserName } from '../utils/auth';
import AmberStickySortableTable from '../components/common/table/AmberStickySortableTable';
import useSnackbar from "../utils/useSnackbar";
import { createTheme } from "@mui/material/styles";
import {AMBER_PRIMARY} from "../constants/colours";
import moment from "moment/moment";
import { QUALITY_CHECKER } from '../roles';
import { getDateTimeAmsterdam } from '../helpers/timeUtils';

const theme = createTheme();

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: 110,
        marginBottom: 40,
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(5),
    },
    jobTitle: {
        fontSize: '1.75rem',
    },
    jobCount: {
        display: 'flex',
        alignItems: 'baseline',
    },
    countText: {
        marginRight: theme.spacing(1),
    },
    section: {
        marginTop: theme.spacing(5),
    },
}));

function TranscriberInvoiceDashboard() {
    const classes = useStyles();
    const [t] = useTranslation();

    const email = getUserName();
    const { showError } = useSnackbar();

    const [invoices, setInvoices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [invoiceCount, setInvoiceCount] = useState(0);
    const roles = getRoles();
    const PAGINATION_OPTIONS = [50];
    const isQC = roles.includes(QUALITY_CHECKER);
    useEffect(() => {
        async function fetchAndSortInvoices() {
            setIsLoading(true);
            try {
                const response = await getApprovedJobs(email);
                const sortedInvoices = response.data.items.sort((a, b) => {
                    const dateA = new Date(a.publishDate);
                    const dateB = new Date(b.publishDate);
                    return dateB - dateA;
                });
                setInvoices(sortedInvoices);
                setInvoiceCount(sortedInvoices.length);
            } catch (error) {
                showError(error.message);
            } finally {
                setIsLoading(false);
            }
        }

        fetchAndSortInvoices();
    }, [email]);

    function convertStringToDDMMYYYY(dateString) {
        const timestamp = Date.parse(dateString);
        if (!isNaN(timestamp)) {
            return moment(new Date(timestamp)).format('DD-MM-YYYY');
        }
        return '';
    }

    function formatDuration(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    }

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.container}>
                <Container maxWidth="xl">
                    <Box className={classes.header}>
                        <Typography variant="h4" className={classes.jobTitle}>
                            {isQC ? t('transcriberDashBoard.CheckedJobsTitle') : t('transcriberDashBoard.approvedJobsTitle')}                          
                        </Typography>
                        <Box className={classes.jobCount}>
                            <Typography
                                variant="h5"
                                id="tableTitle"
                                style={{ color: AMBER_PRIMARY, fontWeight: 'bold', marginRight: '8px' }}
                            >
                                {invoiceCount >= 200 ?  "200+" : invoiceCount}
                            </Typography>
                            <Typography variant="h5" className={classes.countText}>
                                {isQC ? t('transcriberDashBoard.jobsChecked'): t('transcriberDashBoard.jobsAccepted')}
                            </Typography>
                        </Box>
                    </Box>

                    <Box className={classes.section}>
                        {/* Shown Jobs Section */}
                        <Typography variant="h6" gutterBottom>
                            {isQC ? t('transcriberDashBoard.shownJobsChecked') : t('transcriberDashBoard.shownJobs')}
                        </Typography>
                        {/* Include your component or content for shown jobs here */}
                    </Box>

                    {/* Table Section */}
                    <Box className={`${classes.section} custom-section`}>
                        <AmberStickySortableTable
                            headCells={[
                                { key: 'jobId', label: 'JobID', width: { xs: '50%', normal: '50%' }, displayer: (task) => task.jobId },
                                { key: 'publishDate', label: 'Approval', width: { xs: '50%', normal: '50%' }, displayer: (task) => convertStringToDDMMYYYY(getDateTimeAmsterdam(task.publishDate)), },
                                { key: 'displayFileName', label: 'Filename', width: { xs: '50%', normal: '50%' }, displayer: (task) => task.displayFileName },
                                { key: 'amountCentsTotal', label: 'Total paid', width: { xs: '50%', normal: '50%' }, displayer: (task) => task.rate ? (task.rate.amountCentsTotal / 100).toFixed(2) + ' €' : '' },
                                { key: 'amountCentsPerMinute', label: 'Per minute', width: { xs: '50%', normal: '50%' }, displayer: (task) => task.rate ? (task.rate.amountCentsPerMinute / 100).toFixed(2) + ' €' : '' },
                                { key: 'nrAudioSeconds', label: 'Duration', width: { xs: '50%', normal: '50%' }, displayer: (task) => formatDuration(task.nrAudioSeconds) },
                                { key: 'transcriptionType', label: 'Type', width: { xs: '50%', normal: '50%' }, displayer: (task) =>task.transcriptionType  + (task.role === "QUALITY_CONTROLLER" ? "\\ QC": "") },
                                { key: 'workflowStyle', label: 'Style', width: { xs: '50%', normal: '50%' }, displayer: (task) => task.workflowStyle }
                            ]}
                            dataToDisplay={ invoices }
                            isLoading={isLoading}
                            paginationOptions={{
                                page: 0,
                                pageSize: PAGINATION_OPTIONS[0],
                                sortProperty: '',
                                sortDirection: 'asc',
                            }}
                            updatePaginationOptions={() => {}}
                            canRowBeClicked={() => false}
                            rowsPerPageOptions={PAGINATION_OPTIONS}
                        />
                    </Box>
                </Container>
            </div>
        </ThemeProvider>
    );
}

export default TranscriberInvoiceDashboard;
