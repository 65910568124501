import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { DIFFICULTY } from '../../../constants/ratingCategories';
import RatingSection from './ratingSection';
import AmberSectionTitle from '../../labels/AmberSectionTitle';
import RatingAverageNumber from '../fields/ratingAverageNumber';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  averageSectionContainer: {
    paddingTop: '0.5em',
    paddingBottom: '1.5em',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  averageTitle: {
    color: theme.palette.custom.charcoalGray,
    marginBottom: 0,
  },
}));

const calculateAverage = (ratings, ratingKeys) => {
    const total = ratingKeys.reduce((acc, key) => {
        const { selected } = ratings[key];
        return acc + (selected || 0);
    }, 0);
    return parseFloat((total / ratingKeys.length).toFixed(2));
};

function RatingForm(props) {
  const { handleRatingChange, id, customRatings } = props;
  const [t] = useTranslation();
  const [ratingDefaults, setRatingDefault] = useState(customRatings);
  const [average, setAverage] = useState(0);
  const classes = useStyles();
  const ratingKeys = Object.keys(ratingDefaults).filter(
    (key) => key !== DIFFICULTY,
  );

  const onClick = useCallback((event) => {
    const {
      target: { sectionKey, value },
    } = event;
    const newRatings = { ...ratingDefaults };
    newRatings[sectionKey].selected = value;
    const newAverage = calculateAverage(newRatings, ratingKeys);
    setAverage(newAverage);
    setRatingDefault(newRatings);
    handleRatingChange({ target: { value: newRatings, id } });
  });

  return (
    <div className={classes.formContainer}>
      {ratingKeys.map((key) => {
        const { label, tooltip, min, max } = ratingDefaults[key];
        return (
          <RatingSection
            key={key}
            tooltip={tooltip}
            label={label}
            sectionKey={key}
            currentSelected={ratingDefaults[key].selected}
            min={min}
            max={max}
            onClick={onClick}
          />
        );
      })}
      <div className={classes.averageSectionContainer}>
        <AmberSectionTitle
          text={t('dialogJobFeedback.total')}
          typographyVariant="inherit"
          typographyClasses={{
            root: classes.averageTitle,
          }}
        />
        <RatingAverageNumber average={average} />
      </div>
      <RatingSection
        sectionKey={DIFFICULTY}
        label={ratingDefaults[DIFFICULTY].label}
        min={ratingDefaults[DIFFICULTY].min}
        max={ratingDefaults[DIFFICULTY].max}
        onClick={onClick}
        currentSelected={ratingDefaults[DIFFICULTY].selected}
        explanation={t('dialogJobFeedback.difficultyExplanation')}
      />
    </div>
  );
}

RatingForm.propTypes = {
  handleRatingChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  customRatings: PropTypes.objectOf(PropTypes.object).isRequired,
};
export default RatingForm;
