export const ADMIN = 'ADMIN';
export const PERFECT_JOB_MANAGER = 'PERFECT_JOB_MANAGER';
export const TRANSCRIBER = 'TRANSCRIBER';
export const QUALITY_CHECKER = 'QUALITY_CONTROLLER';
export const ROLES_ALLOWED = [
  ADMIN,
  TRANSCRIBER,
  PERFECT_JOB_MANAGER,
  QUALITY_CHECKER,
];
