import React from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import StyledMenu from './styledMenu';
import StyledMenuItem from './styledMenuItem';

import { LangIcon } from '../ui/Icons';
import { currentUserLanguageAtom } from '../../store/user';
import './header.scss';
import '../ui/HeaderButton.scss';

const useStyles = makeStyles(() => ({
  popperClose: {
    pointerEvents: 'none',
  },
}));

function LangMenu() {
  const classes = useStyles();
  const [t, i18n] = useTranslation();
  const i18nLanguage = 'En';
  const [currentLanguage, setCurrentLanguage] = React.useState(i18nLanguage);
  localStorage.setItem('lang', currentLanguage);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [, setUserLanguage] = useRecoilState(currentUserLanguageAtom);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function changeLanguage(language) {
    localStorage.setItem('lang', language);
    setCurrentLanguage(language.toLowerCase());
    setUserLanguage(language);
    i18n.changeLanguage(language);
    handleClose();
  }

  return (
    <>
      <Tooltip title={t('nav.tooltipLanguage')} placement="bottom">
        <Button
          className="HeaderButton"
          color="primary"
          aria-haspopup="true"
          aria-controls="customized-menu"
          variant="contained"
          onClick={handleClick}
        >
          <i>
            <LangIcon />
          </i>
          <strong>{currentLanguage}</strong>
        </Button>
      </Tooltip>

      <StyledMenu
        className={classes.menuList}
        id="LangMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={() => changeLanguage('en')}>
          <ListItemText primary={t('common.languages.en')} />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => changeLanguage('nl')}>
          <ListItemText primary={t('common.languages.nl')} />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => changeLanguage('de')}>
          <ListItemText primary={t('common.languages.de')} />
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
}

export default LangMenu;
