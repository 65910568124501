import PropTypes from 'prop-types';
import Login from './login';
import LoginFirebase from './FirebaseLogin';

function LoginSwitch(props) {
  const NEW_LOGIN_ENABLED =
    process.env.REACT_APP_FF_NEW_LOGIN !== undefined
      ? JSON.parse(process.env.REACT_APP_FF_NEW_LOGIN)
      : false;

  if (NEW_LOGIN_ENABLED) {
    return LoginFirebase(props);
  }
  return Login(props);
}

LoginSwitch.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func, push: PropTypes.func })
    .isRequired,
};

export default LoginSwitch;
