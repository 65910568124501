import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import JobManagerDashboard from './JobManagerDashboard';
import ManageTranscribersTab from './ManageTranscribersTab';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '64px',
    marginBottom: 40,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  currenJobs: {
    width: '80%',
    margin: 'auto',
  },
  invitations: {
    width: '80%',
    marginTop: theme.spacing(1),
    margin: 'auto',
  },
  acceptedJobs: {
    width: '80%',
    marginTop: theme.spacing(1),
    margin: 'auto',
  },
}));

function AdminDashboard() {
  const classes = useStyles();
  const [t] = useTranslation();
  const [tabValue, setTabValue] = useState(0);

  return (
    <div className={classes.container}>
      <Tabs
        value={tabValue}
        onChange={(event, newValue) => setTabValue(newValue)}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="admin tabs"
      >
        <Tab label={t('adminDashboard.transcribersManagersTab')} />
        <Tab label={t('adminDashboard.jobsTab')} />
      </Tabs>
      {tabValue === 0 && <JobManagerDashboard tabValue={tabValue} />}
      {tabValue === 1 && <ManageTranscribersTab />}
    </div>
  );
}

export default AdminDashboard;
