export const CURRENT_USER_DETAILS = 'CurrentUserDetails';
export const CURRENT_USER_ROLES = 'CurrentUserRoles';
export const CURRENT_USER_LOGIN_STATUS = 'CurrentUserLoginStatus';
export const CURRENT_USER_LOGIN_DETAILS = 'CurrentUserLoginDetails';
export const CURRENT_USER_QUERY = 'GetCurrentUserQuery';
export const CAN_EDIT_NOTES = 'CanUserEditNotes';
export const OPEN_JOBS_QUERY = 'GetOpenJobsQuery';
export const ACTIVE_JOBS_QUERY = 'GetActiveJobsQuery';
export const COMPLETED_JOBS_QUERY = 'GetCompletedJobsQuery';
export const OPEN_JOBS_LIST = 'OpenJobsList';
export const COMPLETED_JOBS_LIST = 'CompletedJobsList';
export const ACTIVE_JOBS_LIST = 'ActiveJobsList';
export const CURRENT_VIEW = 'CurrentUserView';
export const NUMBER_OPEN_ACTIVE_JOBS = 'NumberOpenActiveJobs';
export const NUMBER_ACTIVE_JOBS = 'NumberActiveJobs';
export const NUMBER_OPEN_JOBS = 'NumberOpenJobs';
export const NUMBER_COMPLETED_JOBS = 'NumberCompletedJobs';
export const CURRENT_USER_LANGUAGE = 'CurrentUserLanguage';
export const CURRENT_JOB_VIEW = 'JobDetailsInView';
export const CURRENT_JOB_VIEW_MEDIA = 'JobDetailsInViewMedia';
export const REFRESH_ADMIN_TABLE = 'RefreshAdminTable';
export const CURRENT_JOB_DETAILS_ADMIN_DASHBOARD_ATOM =
  'CURRENT_JOB_DETAILS_ATOM';
export const CURRENT_JOB_IN_VIEW_QUALITY_CHECKS = 'JobQualityChecksInView';
export const CURRENT_JOB_DETAILS_ADMIN_DASHBOARD_DATA =
  'JobDetailsInViewAdminDashboardData';
export const CURRENT_JOB_DETAILS_ADMIN_DASHBOARD_EXPORT_OPTIONS =
  'JobDetailsInViewAdminDashboardExportOptions';
export const CURRENT_JOB_QUALITY_CHECK_OBJECTS = 'JobQualityChecks';
export const CURRENT_JOB_QUALITY_CHECK_OBJECTS_WRITABLE =
  'JobQualityChecksWritable';
export const CURRENT_JOB_TRANSCRIPT = 'JobDetailsInViewTranscript';

export const CURRENT_FILTER_STATE = 'CurrentFilterInState';

export const DONT_SHOW_EXPORT_DIALOG = 'DontShowExportDialog';
export const REPORT_FUNCTION_ACTIONS = 'ReportFunctionActions';

export const SRT_MISSING = 'SrtMissing';

export const GOOGLE_SSO_CURRENT_USER_DETAILS = 'GoogleSSOCurrentUserDetails';
export const GOOGLE_SSO_LOADING = 'GoogleSSOLoading';
