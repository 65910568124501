import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { RecoilRoot } from 'recoil';
import RecoilizeDebugger from 'recoilize';

import i18n from './i18n';
import { SnackbarProvider } from './utils/snackbarProvider';
import App from './App';
import { getLoginDetails } from './utils/auth';
import { currentUserLoginDetailsAtom, currentUserRoleAtom } from './store/user';
import './index.scss';
import { ENV } from './settings';

const initializeState = ({ set }) => {
  const localLoginDetails = getLoginDetails();
  set(currentUserLoginDetailsAtom, { ...localLoginDetails });
  set(currentUserRoleAtom, localLoginDetails.role);
};

const ContextedApp = () => {
  const [recoilKey, setRecoilKey] = useState(0);

  function isEnvLocal() {
    return ENV === 'local';
  }
  return (
    <RecoilRoot key={recoilKey} initializeState={initializeState}>
      {isEnvLocal() && <RecoilizeDebugger />}
      <I18nextProvider i18n={i18n}>
        <SnackbarProvider>
          <App resetRecoilState={() => setRecoilKey((key) => key + 1)} />
        </SnackbarProvider>
      </I18nextProvider>
    </RecoilRoot>
  );
};

ReactDOM.render(
  // eslint-disable-next-line no-undef
  <ContextedApp />,
  document.getElementById('root'),
);
