import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';



function CancelMultipleJobDialog(props) {
  const { open, handleClose, handleCancelJob, selectedjobIds } = props;
  const [t, i18n] = useTranslation();  
  
  function handleCancel() {
    handleCancelJob(selectedjobIds);
    handleClose();
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="assign-dialog-title"
      fullWidth={true}
    >
     <DialogTitle id="assign-dialog-title">
        {t('managerDashboard.table.cancelJob.multipleDescription', { count: selectedjobIds.length })}
      </DialogTitle>     
      <DialogActions>
      <Button onClick={handleClose} color="secondary">
          {t('managerDashboard.table.cancelJob.no')}
        </Button>
        <Button onClick={() => handleCancel()} color="primary" autoFocus>
          {t('managerDashboard.table.cancelJob.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
  



}

export default CancelMultipleJobDialog;
