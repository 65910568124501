import {
  ADMIN,
  PERFECT_JOB_MANAGER,
  QUALITY_CHECKER,
  TRANSCRIBER,
} from '../roles';

function getSymbolByLocationSearch() {
  if (window.location.search.trim() === '') {
    return '?';
  } else {
    return '&';
  }
}

export function clearParamsInUrl(history) {
  history.replace({
    pathname: window.location.pathname,
    search: ``,
  });
}

export function addParamInUrl(history, key, value) {
  history.replace({
    pathname: window.location.pathname,
    search: `${
      window.location.search
    }${getSymbolByLocationSearch()}${key}=${value}`,
  });
}

export function redirectToDashBoard(history, userRoles) {
  const userType = userRoles && userRoles[0];
  switch (userType) {
    case ADMIN:
      history.push('admin/dashboard');
      break;
    case PERFECT_JOB_MANAGER:
      history.push('manager/dashboard');
      break;
    case QUALITY_CHECKER:
      history.push('quality-controller/dashboard');
      break;
    case TRANSCRIBER:
      history.push('transcriber/dashboard');
      break;
    default:
      break;
  }
}
