import React from 'react';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/nl';
import { Tooltip } from '@material-ui/core';
import { getDateTime } from '../../helpers/timeUtils';

function RemainingDeadline({ deadline, noStyle, noTooltip }) {
  // Will be red if it is less than or equal to TWO DAYS!
  function getDeadlineColor(deadlineDate) {
    const diff = moment().diff(deadlineDate, 'days');
    if (diff < -1) {
      return 'rgba(0, 0, 0, 0.87)'; // black
    }
    return '#ff3d00'; // red
  }

  // Will be bold if it is less than or equal to ONE DAY!
  function getDeadlineFontWeight(deadlineDate) {
    const diff = moment().diff(deadlineDate, 'days');
    if (diff < 0) {
      return 400;
    }
    return 600;
  }

  function getDateFromNow() {
    const lang = localStorage.getItem('lang');

    return moment(getDateTime(deadline)).locale(lang).fromNow();
  }

  if (noTooltip) {
    return (
      <div>
        {noStyle ? (
          <span
            style={{
              color: 'rgba(0, 0, 0, 0.87)',
              fontSize: 14,
              fontWeight: 400,
            }}
          >
            {getDateFromNow()}
          </span>
        ) : (
          <span
            style={{
              color: getDeadlineColor(getDateTime(deadline)),
              fontSize: 14,
              fontWeight: getDeadlineFontWeight(getDateTime(deadline)),
            }}
          >
            {getDateFromNow()}
          </span>
        )}
      </div>
    );
  }
  return (
    <Tooltip disableFocusListener title={getDateTime(deadline)}>
      {noStyle ? (
        <span
          style={{
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          {getDateFromNow()}
        </span>
      ) : (
        <span
          style={{
            color: getDeadlineColor(getDateTime(deadline)),
            fontSize: 14,
            fontWeight: getDeadlineFontWeight(getDateTime(deadline)),
          }}
        >
          {getDateFromNow()}
        </span>
      )}
    </Tooltip>
  );
}

export default RemainingDeadline;
