import {
  PERFECT_AWAITING_CONFIRMATION_TRANSCRIBER,
  PERFECT_EDITING,
  PERFECT_IN_REVIEW,
  PERFECT_TO_BE_REVIEWED,
} from './jobStatuses';

export const DEADLINE_EXTENSION = 'DEADLINE_EXTENSION';
export const SYSTEM_MALFUNCTION = 'SYSTEM_MALFUNCTION';
export const SUBTITLE_ALIGNMENT = 'SUBTITLE_ALIGNMENT';
export const DIFFICULT_CONTEXT = 'DIFFICULT_CONTEXT';
export const INCOMPREHENSIBLE = 'INCOMPREHENSIBLE';
export const FILE_NOT_FINISHED = 'FILE_NOT_FINISHED';
export const INVALID_QUALITY = 'INVALID_QUALITY';
export const REQUEST_ALIGNMENT = 'ALIGNMENT_REQUEST';
export const OTHER = 'OTHER';
export const MULTIPLE_LANGUAGES_SPOKEN = 'MULTIPLE_LANGUAGES_SPOKEN';
export const WORKFLOW_DETAILS_UNCLEAR = 'WORKFLOW_DETAILS_UNCLEAR';
export const REASSIGN_TO_DIFFERENT_TRANSCRIBER =
  'REASSIGN_TO_DIFFERENT_TRANSCRIBER';
export const WRONG_LANGUAGE = 'WRONG_LANGUAGE';

const sortIssueTypesByValue = (a, b) => {
  if (a.value < b.value) {
    return -1;
  }
  if (a.value > b.value) {
    return 1;
  }
  return 0;
};

export const getQCIssueTypes = () => [
  SYSTEM_MALFUNCTION,
  DIFFICULT_CONTEXT,
  INCOMPREHENSIBLE,
  REASSIGN_TO_DIFFERENT_TRANSCRIBER,
  REQUEST_ALIGNMENT,
  WRONG_LANGUAGE,
];

export const getDefaultIssueTypesArray = () => [
  DEADLINE_EXTENSION,
  SUBTITLE_ALIGNMENT,
  INVALID_QUALITY,
  FILE_NOT_FINISHED,
  SYSTEM_MALFUNCTION,
  DIFFICULT_CONTEXT,
  INCOMPREHENSIBLE,
  MULTIPLE_LANGUAGES_SPOKEN,
  REASSIGN_TO_DIFFERENT_TRANSCRIBER,
  WORKFLOW_DETAILS_UNCLEAR,
  REQUEST_ALIGNMENT,
  WRONG_LANGUAGE,
];

export const getAllIssueTypesMap = (t) => {
  const issueTypesMap = [
    ...getDefaultIssueTypesArray().map((issueType) => ({
      value: issueType,
      label: t(`jobIssueTypes.${issueType}`),
    })),
  ].sort(sortIssueTypesByValue);
  issueTypesMap.push({
    value: OTHER,
    label: t(`jobIssueTypes.${OTHER}`),
  });
  return issueTypesMap;
};

const getTranscriberJobIssues = () => [
  SYSTEM_MALFUNCTION,
  DIFFICULT_CONTEXT,
  REQUEST_ALIGNMENT,
  WORKFLOW_DETAILS_UNCLEAR,
  MULTIPLE_LANGUAGES_SPOKEN,
  WRONG_LANGUAGE,
];

const getTranslatedTranscriberJobIssues = (t) =>
  getTranscriberJobIssues()
    .map((issueType) => ({
      value: issueType,
      label: t(`jobIssueTypes.${issueType}`),
    }))
    .sort(sortIssueTypesByValue);

const getTranslatedQCJobIssues = (t) =>
  getQCIssueTypes()
    .map((issueType) => ({
      value: issueType,
      label: t(`jobIssueTypes.${issueType}`),
    }))
    .sort(sortIssueTypesByValue);

export const getTranslatedIssueTypesMap = (t, jobStatus) => {
  let returnValue;
  switch (jobStatus) {
    case PERFECT_IN_REVIEW:
    case PERFECT_TO_BE_REVIEWED:
      returnValue = getTranslatedQCJobIssues(t);
      break;
    case PERFECT_EDITING:
    case PERFECT_AWAITING_CONFIRMATION_TRANSCRIBER:
      returnValue = getTranslatedTranscriberJobIssues(t);
      break;
    default:
      returnValue = getDefaultIssueTypesArray()
        .map((issueType) => ({
          value: issueType,
          label: t(`jobIssueTypes.${issueType}`),
        }))
        .sort(sortIssueTypesByValue);
      returnValue.push({
        value: OTHER,
        label: t(`jobIssueTypes.${OTHER}`),
      });
      break;
  }

  return returnValue;
};
