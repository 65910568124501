import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { Box } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Assignment, CollectionsBookmark, VolumeUp } from '@material-ui/icons';
import { uniqueId } from 'lodash';
import {
  getDateFromNow,
  getDateTimeAmsterdam,
  toHHMMSS,
} from '../../../helpers/timeUtils';
import AmberHeading from '../../labels/AmberHeading';
import AmberHeadingBold from '../../labels/AmberHeadingBold';
import AmberBodyText from '../../labels/AmberBodyText';
import { TRANSCRIBER } from '../../../roles';
import { currentUserLoginDetailsAtom } from '../../../store/user';
import CURRENCIES from '../../../constants/currencies';
import { getTagsForJob } from '../../../helpers/jobTags';
import AmberSimpleTooltip from '../tooltips/AmberSimpleTooltip';
import getTopicIcon from '../../../helpers/jobTopicIcon';

const useStyles = makeStyles(() => ({
  iconStyle: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px',
  },
  svgStyle: {
    width: '20px',
  },
}));

function JobDetailsCard(props) {
  const { showJobDetails, jobToDisplay, actionButtons, fromInvitationTable } =
    props;
  const [t] = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const roles = useRecoilValue(currentUserLoginDetailsAtom)?.role;
  const jobTags = getTagsForJob(jobToDisplay);
  const TopicIcon = getTopicIcon(jobToDisplay);

  const getTranscriptionStyle = () =>
    t(
      `common.jobFields.${
        jobToDisplay?.jobOptions?.transcriptionStyle || 'verbatim'
      }`,
    );

  function getDeadline() {
    return jobToDisplay.transcriberDeadline || jobToDisplay.deadline;
  }

  const getFileType = () =>
    t(`common.jobFields.${jobToDisplay.transcriptionType}`);

  const getNumberOfSpeakers = () =>
    jobToDisplay.numberOfSpeakers === 1
      ? `1 ${t('transcriberDashBoard.currentJobCard.speaker')}`
      : `${jobToDisplay.numberOfSpeakers} ${t(
          'transcriberDashBoard.currentJobCard.speakers',
        )}`;

  const getWorkflowStyle = () =>
    jobToDisplay.workflowStyle &&
    t(`common.jobFields.${jobToDisplay.workflowStyle}`);

  function getRoleRate() {
    return jobToDisplay?.rates?.find((x) => x.userRole === TRANSCRIBER);
  }

  function getRatePerMinute() {
    const rate = getRoleRate();

    if (rate) {
      return `${CURRENCIES[rate.currency]}${
        rate.amountCentsPerMinute / 100
      } / ${t('common.jobFields.minute')}`;
    }

    return '-';
  }

  function getRateTotal() {
    const rate = getRoleRate();

    if (rate) {
      return `${CURRENCIES[rate.currency]}${rate.amountCents / 100} ${t(
        'common.jobFields.total',
      )}`;
    }

    return '-';
  }

  function getRevision() {
    const qualityCheckRejectionCount = jobToDisplay?.qualityCheckRejectionCount;
    return qualityCheckRejectionCount > 0
      ? `(${t(
          'transcriberDashBoard.currentJobCard.revision',
        )}: ${qualityCheckRejectionCount})`
      : '';
  }

  function displayLanguage() {
    const { language, targetLanguage } = jobToDisplay;
    if (!language) {
      return '';
    }
    return targetLanguage
      ? `${language.toUpperCase()} → ${targetLanguage.toUpperCase()}`
      : language.toUpperCase();
  }

  return (
    <>
      <Box
        bgcolor="white"
        p={2}
        display="flex"
        border="1px solid lightGray"
        mb={2}
      >
        {/* Name and details */}
        <Box width="15%">
          <Box
            onClick={() =>
              showJobDetails(jobToDisplay.jobId, jobToDisplay.glossaryId, 0)
            }
            style={{ cursor: 'pointer' }}
          >
            <AmberHeadingBold
              overflow
              testid="job-card-title"
              text={jobToDisplay.displayFileName}
            />
          </Box>
          <Box display="flex">
            <AmberBodyText
              testid="job-card-length"
              color="textSecondary"
              text={toHHMMSS(jobToDisplay.nrAudioSeconds)}
            />

            <Box mx={1}>
              <AmberBodyText color="textSecondary" text="•" />
            </Box>
            <AmberBodyText
              testid="job-card-speakers"
              color="textSecondary"
              text={getNumberOfSpeakers()}
            />
          </Box>
        </Box>

        {/* File details */}
        <Box width="15%">
          <AmberHeading testid="job-card-type" text={getFileType()} />

          <Box display="flex">
            <AmberBodyText
              testid="job-card-transcType"
              color="textSecondary"
              text={getTranscriptionStyle()}
            />
            {getWorkflowStyle() && (
              <>
                <Box mx={1}>
                  <AmberBodyText color="textSecondary" text="•" />
                </Box>
                <AmberBodyText
                  testid="job-card-transcStyle"
                  color="textSecondary"
                  text={getWorkflowStyle()}
                />
              </>
            )}
          </Box>
        </Box>

        {/* Topic Icon */}
        {fromInvitationTable && TopicIcon && (
          <Box classes={{ root: classes.iconStyle }}>
            <AmberSimpleTooltip title={t(TopicIcon.tooltip)}>
              <TopicIcon.Icon width="25px" height="25px" />
            </AmberSimpleTooltip>
          </Box>
        )}

        {/* Language */}
        <Box width="8%" marginLeft="15px" display="flex" alignItems="center">
          <AmberHeadingBold testid="job-card-lang" text={displayLanguage()} />
        </Box>

        {/* Rate */}
        {roles.includes(TRANSCRIBER) && (
          <Box width="17%">
            <AmberHeading text={getRatePerMinute()} />
            <AmberBodyText
              color="textSecondary"
              text={`${getRateTotal()} ${getRevision()}`}
            />
          </Box>
        )}

        {/* Date */}
        <Box width="12.5%">
          <AmberHeading
            color="error"
            testid="job-card-deadline"
            text={getDateFromNow(getDeadline(jobToDisplay))}
          />
          <AmberBodyText
            testid="job-card-deadlineDate"
            color="textSecondary"
            text={getDateTimeAmsterdam(getDeadline(jobToDisplay))}
          />
        </Box>

        {/* Icons */}
        <Box width="12.5%" alignSelf="center">
          <Box display="inline" mr={2} color="gray">
            <Assignment
              onClick={() =>
                showJobDetails(jobToDisplay.jobId, jobToDisplay.glossaryId, 1)
              }
              style={{ cursor: 'pointer', fill: theme.palette.secondary.main }}
            />
          </Box>
          <Box display="inline" mr={2} color="gray">
            <CollectionsBookmark
              onClick={() =>
                showJobDetails(jobToDisplay.jobId, jobToDisplay.glossaryId, 4)
              }
              style={{ cursor: 'pointer' }}
            />
          </Box>
          <Box display="inline" color="gray">
            <VolumeUp
              onClick={() =>
                showJobDetails(jobToDisplay.jobId, jobToDisplay.glossaryId, 2)
              }
              style={{ cursor: 'pointer' }}
            />
          </Box>
        </Box>

        {/* Job tags */}
        {jobTags &&
          jobTags.length > 0 &&
          jobTags.map((jobTag) => (
            <Box key={uniqueId()} classes={{ root: classes.iconStyle }}>
              <AmberSimpleTooltip title={t(jobTag.tooltip)}>
                <jobTag.icon width="25px" height="25px" />
              </AmberSimpleTooltip>
            </Box>
          ))}

        {/* Action */}
        <Box width="25%" textAlign="end" alignSelf="center">
          {actionButtons}
        </Box>
      </Box>
    </>
  );
}

export default JobDetailsCard;

JobDetailsCard.propTypes = {
  actionButtons: PropTypes.node.isRequired,
  jobToDisplay: PropTypes.shape().isRequired,
  showJobDetails: PropTypes.func.isRequired,
  fromInvitationTable: PropTypes.bool,
};

JobDetailsCard.defaultProps = {
  fromInvitationTable: false,
};
