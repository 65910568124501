import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import JobCompletedStatus from './jobCompletedStatus';
import JobTextVisuals from '../jobTextVisuals';

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex' },
  jobItem: {
    display: 'flex',
    flexDirection: 'row',
    flex: '0 1 90%',
    border: `1px solid ${theme.palette.custom.darkGrey}`,
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    borderRadius: '0.3em',
    marginBottom: '1em',
    background: theme.palette.primary.white,
  },
  languageSection: {
    fontWeight: 'bolder',
    color: theme.palette.custom.charcoalGray,
    padding: '1.1em',
    margin: 0,
    alignSelf: 'center',
  },
  lastChild: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
  },
}));

function CompletedJob(props) {
  const classes = useStyles();

  const {
    job,
    job: { status },
  } = props;

  return (
    <div className={classes.jobItem}>
      <JobTextVisuals job={job} isCompleted />
      <div className={classes.lastChild}>
        <JobCompletedStatus status={status} />
      </div>
    </div>
  );
}

CompletedJob.propTypes = {
  job: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
};

export default CompletedJob;
