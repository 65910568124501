import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';

function ResetMultipleJobDialog(props) {
  const { open, handleClose, handleReopenJobs, selectedjobIds } = props;
  const [t, i18n] = useTranslation();

  function handleReset() {
    handleReopenJobs(selectedjobIds);
    handleClose();
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="assign-dialog-title"
      fullWidth={true}
    >
      <DialogTitle id="assign-dialog-title">
        {t('managerDashboard.table.resetStatus.multipleDescription', {
          count: selectedjobIds.length,
        })}
      </DialogTitle>
      <DialogContent>
        {t('managerDashboard.table.resetStatus.descriptionAdmin')}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t('managerDashboard.table.resetStatus.cancel')}
        </Button>
        <Button onClick={() => handleReset()} color="primary" autoFocus>
          {t('managerDashboard.table.resetStatus.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ResetMultipleJobDialog;
