import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { NOTES_MAX_ROWS } from './job_item_dialog/constants';
import AmberSimpleTextField from '../textfields/amberSimpleTextfield';
import AmberLoadingButton from '../buttons/AmberLoadingButton';
import AmberNotesTextArea from '../textfields/AmberNotesTextArea';
import AmberFormHelper from '../labels/AmberFormHelper';
import parseLines from '../../utils/stringUtils';

const useStyles = makeStyles((theme) => ({
   
    addNoteButton: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      paddingTop: '0.5em',
      color: theme.palette.primary.main,
      border: 'none',
    },
    textField: {
      border: `2px solid ${theme.palette.custom.darkGrey}`,
      color: theme.palette.custom.purplishGray,
    },
    editableNotesFormHelper: {
      paddingLeft: '2em',
    },
    editableTextFieldContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    addNoteContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: theme.palette.custom.whiteTwo,
      border: `2px solid ${theme.palette.custom.darkGrey}`,
      borderTop: 0,
      borderRadius: '0 0 0.5em 0.5em',
    },
    readonyInput:{
      border: "none",
      outline: "none",
      '& .MuiInputBase-root.Mui-disabled': {
        color: 'black',
        fontSize: theme.spacing(2.125),
        fontWeight: 800,       
        padding: '1em',
        margin: 0,
      },
    },
    input: {
      '& .MuiInputBase-root.Mui-disabled': {
        color: 'black',
        fontSize: theme.spacing(2.125),
        fontWeight: 800,
        backgroundColor: theme.palette.custom.lightMango,
  
        border: `2px solid ${theme.palette.custom.darkGrey}`,
        borderRadius: '0.5em',
        padding: '1em',
        margin: 0,
      },
    },
  }));
  
function Notes(props) {
    const { notes, canEditNotes, onChange, onClickSave,isReadonly, ...rest } = props;
  
    const classes = useStyles();
  
    return (
      <>
        {!canEditNotes && (
          <AmberSimpleTextField
            multiline
            value={notes ? parseLines(notes) : ''}
            disabled
            fullWidth
            maxRows={NOTES_MAX_ROWS}
            variant = "standard"
            InputProps={{
              disableUnderline: true,
            }}
            inputClass={notes ? (isReadonly ? classes.readonyInput : classes.input) : null}
          />
        )}
        
        {canEditNotes && (
          <EditableTextField
            onClickSave={onClickSave}
            notes={notes}
            onChange={onChange}
            {...rest}
          />
        )}
      </>
    );
  }
  
  Notes.propTypes = {
    notes: PropTypes.string,
    canEditNotes: PropTypes.bool,
    onChange: PropTypes.func,
    onClickSave: PropTypes.func,
  };
  Notes.defaultProps = {
    notes: '',
    onChange: null,
    canEditNotes: false,
    onClickSave: null,
  };
  //=================================================

  function EditableTextField(props) {
    const { notes, onChange, onClickSave, isRequestLoading } = props;
    const [newNotes, setNewNotes] = useState(notes);
    const [t] = useTranslation();
    const classes = useStyles();
  
    const handleOnKeyChange = (event) => {
      const { value } = event;
      setNewNotes(value);
    };
  
    const handleBlur = () => {
      const syntethicEvent = {
        value: newNotes,
      };
      onChange(syntethicEvent);
    };
    return (
      <div className={classes.editableTextFieldContainer}>
        <AmberNotesTextArea
          multiline
          disabled={isRequestLoading}
          value={newNotes ? parseLines(newNotes) : ''}
          handleChange={handleOnKeyChange}
          onBlur={handleBlur}
          placeholder={t('dialogJobFeedback.textAreaPlaceholder')}
          fullWidth
        />
        <div className={classes.addNoteContainer}>
          <AmberFormHelper
            className={classes.editableNotesFormHelper}
            text={t('common.jobItemDialog.editableNotesExplanation')}
          />
          <AmberLoadingButton
            loading={isRequestLoading}
            disabled={isRequestLoading}
            variant="text"
            classes={{
              root: classes.addNoteButton,
            }}
            onClick={onClickSave}
            text={t('common.jobItemDialog.addNote')}
          />
        </div>
      </div>
    );
  }
  
  EditableTextField.propTypes = {
    notes: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onClickSave: PropTypes.func.isRequired,
    isRequestLoading: PropTypes.bool,
  };
  EditableTextField.defaultProps = {
    isRequestLoading: false,
  };
//=================================================  
  export default Notes;