import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Checkbox,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { getFormattedDate } from '../../../utils/timeUtils';
import { QUALITY_CHECKER, TRANSCRIBER } from '../../../roles';
import getRoles from './getRolesConstants';
import generateKey from '../../../utils/generateReactKey';
import { getGMTDate } from '../../../helpers/timeUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  detailLabel: {
    display: 'flex',
    alignItems: 'center',
    height: 32,
    color: theme.palette.primary[400],
    fontSize: 14,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  warningIcon: {
    color: '#e7a910',
    marginRight: 4,
  },
  warningRoot: {
    display: 'flex',
    alignItems: 'center',
    color: 'black',
  },
  textField: {
    marginBottom: 0,
  },
}));

function DatePicker({ newDeadline, setNewDeadline, setDeadlineModified }) {
  const classes = useStyles();

  function changeNewDeadline(event) {
    const { value } = event.target;
    setNewDeadline(value);
    setDeadlineModified(true);
  }

  return (
    <div className={classes.container} noValidate>
      <TextField
        id="datetime-local"
        type="datetime-local"
        defaultValue={newDeadline}
        onChange={changeNewDeadline}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
  );
}

DatePicker.propTypes = {
  newDeadline: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  setNewDeadline: PropTypes.func.isRequired,
  setDeadlineModified: PropTypes.func.isRequired,
};

DatePicker.defaultProps = {
  newDeadline: '',
};

function DeadlineComponent(props) {
  const {
    newDeadline,
    setNewDeadline,
    setDeadlineModified,
    editableRole,
    deadline,
    componentRole,
  } = props;

  if (editableRole === componentRole) {
    return (
      <Grid item xs={8}>
        <DatePicker
          newDeadline={newDeadline}
          setNewDeadline={setNewDeadline}
          setDeadlineModified={setDeadlineModified}
          style={{ marginBottom: 0 }}
        />
      </Grid>
    );
  }
  return (
    <Grid item xs={8}>
      {getFormattedDate(deadline)}
    </Grid>
  );
}

DeadlineComponent.propTypes = {
  newDeadline: PropTypes.string,
  deadline: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setNewDeadline: PropTypes.func.isRequired,
  setDeadlineModified: PropTypes.func.isRequired,
  editableRole: PropTypes.string,
  componentRole: PropTypes.string,
};

DeadlineComponent.defaultProps = {
  newDeadline: '',
  deadline: null,
  editableRole: null,
  componentRole: null,
};

const getEditableDeadline = (editableRole, deadlines) => {
  const { transcriberDeadline, clientDeadline, qualityControlDeadline } =
    deadlines;
  switch (editableRole) {
    case TRANSCRIBER:
      return transcriberDeadline;
    case QUALITY_CHECKER:
      return qualityControlDeadline;
    default:
      return clientDeadline;
  }
};

const getComponentDeadline = (componentRole, deadlines) => {
  const { transcriberDeadline, clientDeadline, qualityControlDeadline } =
    deadlines;
  const { role } = componentRole;
  switch (role) {
    case TRANSCRIBER:
      return transcriberDeadline;
    case QUALITY_CHECKER:
      return qualityControlDeadline;
    default:
      return clientDeadline;
  }
};

function DeadlineDialog(props) {
  const {
    handleClose,
    editDeadline: {
      clientDeadline,
      transcriberDeadline,
      qualityControlDeadline,
      deadlineRole,
      show,
      jobId,
      filename,
    },
    handleDeadlineEdit,
    dialogTitle,
  } = props;

  const [newDeadline, setNewDeadline] = useState(
    getFormattedDate(
      getEditableDeadline(deadlineRole, {
        transcriberDeadline,
        qualityControlDeadline,
        clientDeadline,
      }),
      'YYYY-MM-DDTHH:mm',
    ),
  );
  const [deadlineModified, setDeadlineModified] = useState(false);
  const [notifyClient, setNotifyClient] = useState(false);
  const [t] = useTranslation();
  const classes = useStyles();

  function updateDeadline() {
    const newDate = new Date(newDeadline);
    const newDateUTC = getGMTDate(newDate);
    handleDeadlineEdit(jobId, newDateUTC, deadlineRole, notifyClient);
  }

  if (!show) return null;

  return (
    <Dialog open={show} aria-labelledby="assign-dialog-title" fullWidth>
      <DialogTitle id="assign-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <div className={classes.root}>
          <Grid container>
            <Grid item xs={4} className={classes.detailLabel}>
              {t('common.jobFields.jobId')}
            </Grid>
            <Grid item xs={8}>
              {jobId}
            </Grid>
            <Grid item xs={4} className={classes.detailLabel}>
              {t('common.jobFields.filename')}
            </Grid>
            <Grid item xs={8}>
              {filename}
            </Grid>

            {getRoles(t).map((role) => (
              <>
                <Grid
                  key={generateKey(role.deadlineFieldName)}
                  item
                  xs={4}
                  className={classes.detailLabel}
                >
                  {role.deadlineFieldName}
                </Grid>
                <DeadlineComponent
                  key={generateKey(role.role)}
                  componentRole={role.role}
                  editableRole={deadlineRole}
                  newDeadline={newDeadline}
                  setNewDeadline={setNewDeadline}
                  setDeadlineModified={setDeadlineModified}
                  deadline={getComponentDeadline(role, {
                    clientDeadline,
                    transcriberDeadline,
                    qualityControlDeadline,
                  })}
                />
                {role.role === deadlineRole &&
                !deadlineRole &&
                deadlineModified ? (
                  <>
                    <Grid item xs={4} className={classes.detailLabel}>
                      {t('common.changeDeadline.notify_client')}
                    </Grid>
                    <Grid item xs={8}>
                      <Checkbox
                        onChange={() => setNotifyClient(!notifyClient)}
                        checked={notifyClient}
                      />
                    </Grid>
                  </>
                ) : (
                  ''
                )}
              </>
            ))}
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t('common.button.cancel')}
        </Button>
        <Button onClick={updateDeadline} color="primary">
          {t('common.button.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
DeadlineDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  editDeadline: PropTypes.shape({
    clientDeadline: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
      PropTypes.number,
    ]),
    transcriberDeadline: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
      PropTypes.number,
    ]),
    qualityControlDeadline: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
      PropTypes.number,
    ]),
    deadlineRole: PropTypes.string,
    show: PropTypes.bool,
    jobId: PropTypes.string,
    filename: PropTypes.string,
  }),
  handleDeadlineEdit: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
};
DeadlineDialog.defaultProps = {
  editDeadline: {},
};
export default DeadlineDialog;
