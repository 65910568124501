import { ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import React from 'react';

const theme = createTheme();

export default function useMediaQueries() {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  return [isSmallScreen, isLargeScreen];
}

function App() {
  return (
      <ThemeProvider theme={theme}>
        <YourComponent />
      </ThemeProvider>
  );
}
