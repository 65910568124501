import { atom, selector, selectorFamily } from 'recoil';

import {
  CURRENT_JOB_VIEW,
  CURRENT_JOB_VIEW_MEDIA,
  CURRENT_JOB_IN_VIEW_QUALITY_CHECKS,
} from './constants';
import { getTranscript, getAudioLink, getQualityChecks } from '../utils/api';

export const currentJobViewAtom = atom({
  key: CURRENT_JOB_VIEW,
  default: null,
});

export const currentJobViewMediaSelector = selector({
  key: CURRENT_JOB_VIEW_MEDIA,
  get: async ({ get }) => {
    const currentJobView = get(currentJobViewAtom);
    if (currentJobView && currentJobView._id) {
      const transcript = await getTranscript(currentJobView._id);
      const audioLink = await getAudioLink(currentJobView._id);
      return { transcript: transcript.data, audioLink: audioLink.data };
    }
    return null;
  },
});
export const getQualityChecksSelector = selector({
  key: CURRENT_JOB_IN_VIEW_QUALITY_CHECKS,
  get: async ({ get }) => {
    const currentJobView = get(currentJobViewAtom);
    if (currentJobView && currentJobView._id) {
      const qualityChecks = await getQualityChecks(
        null,
        null,
        currentJobView._id,
        0,
        5
      );
      return qualityChecks.data.content;
    }
    return null;
  },
});
