import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FormHelperText } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  helperTextRoot: {
    fontSize: 'x-small',
  },
  helperTextRootError: {
    fontSize: 'x-small',
    color: theme.palette.error.main,
  },
}));

function AmberFormHelper(props) {
  const { error, text, component, ...rest } = props;
  const classes = useStyles();

  const helperTextClass = clsx({
    [classes.helperTextRoot]: !error,
    [classes.helperTextRootError]: error,
  });

  return (
    <FormHelperText
      error={error}
      classes={{ root: helperTextClass }}
      component={component}
      {...rest}
    >
      {text}
    </FormHelperText>
  );
}
AmberFormHelper.propTypes = {
  text: PropTypes.string.isRequired,
  error: PropTypes.bool,
  component: PropTypes.string,
};

AmberFormHelper.defaultProps = {
  error: false,
  component: 'span',
};

export default AmberFormHelper;
