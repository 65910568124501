import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Loading from '../common/loading';
import JobDetailsCard from '../common/job_details_card/JobDetailsCard';
import AmberTextButton from '../buttons/AmberTextButton';
import AmberButton from '../common/amberbutton';
import JobEmptyState from '../quality_control/jobs/jobEmptyState/jobEmptyState';
import CutOffDialog from '../common/cut_off_time_dialog/CutOffTimeDialog';

function InvitationsTable(props) {
  const {
    requests,
    acceptRequest,
    denyRequest,
    showJobDetails,
    openCutOffDialog,
    setOpenCutOffDialog,
    jobForCutOffDialog,
    acceptJob,
  } = props;
  const { isLoading, loaded, data } = requests;
  const [t] = useTranslation();

  function getActionButtons(jobToDisplay, record, index) {
    const shouldBeDisabled = index > 60;

    return (
      <>
        <AmberTextButton
          test-id="details-tab-button"
          onClick={() =>
            showJobDetails(jobToDisplay.jobId, jobToDisplay.glossaryId)
          }
          text={t('common.jobItemDialog.detailsTab')}
        />
        <AmberButton
          testid="accept-job-button"
          disabled={shouldBeDisabled}
          onClick={() => acceptRequest(record)}
        >
          {t('common.button.accept')}
        </AmberButton>
      </>
    );
  }

  return (
    <>
      {isLoading && <Loading thickness={5} size={40} />}
      {loaded && data.length === 0 && (
        <JobEmptyState
          stringBold={t(
            'transcriberDashBoard.invitationsTable.noInvitationsJobsTitle',
          )}
          stringSecondary={t(
            'transcriberDashBoard.invitationsTable.noInvitationsJobsTitle1',
          )}
        />
      )}
      {loaded &&
        data.sort((a, b) => {
          const deadlineA = a.transcriberInvitationJobs[0]?.created || 0;
          const deadlineB = b.transcriberInvitationJobs[0]?.created || 0;
          return deadlineA - deadlineB})
          .map((record, index) =>
            record.transcriberInvitationJobs.map((jobToDisplay) => (
              <JobDetailsCard
                requests={requests}
                acceptRequest={acceptRequest}
                denyRequest={denyRequest}
                showJobDetails={showJobDetails}
                jobToDisplay={jobToDisplay}
                fromInvitationTable
                actionButtons={getActionButtons(jobToDisplay, record, index)}
              />
          )))}
      <CutOffDialog
        open={openCutOffDialog}
        jobForCutOffDialog={jobForCutOffDialog}
        setOpenCutOffDialog={setOpenCutOffDialog}
        acceptJob={acceptJob}
      />
    </>
  );
}

export default InvitationsTable;

InvitationsTable.propTypes = {
  requests: PropTypes.object.isRequired,
  acceptRequest: PropTypes.func.isRequired,
  denyRequest: PropTypes.func.isRequired,
  showJobDetails: PropTypes.func.isRequired,
};
