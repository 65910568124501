import React from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// Style(s)
const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    height: 'max-content',
  },
}));

function AmberPaper(props) {
  const { children, classes } = props;
  const defaultClasses = useStyles();

  return (
    <Paper
      classes={classes ? { ...classes } : { root: defaultClasses.paper }}
      elevation={5}
    >
      {children}
    </Paper>
  );
}

AmberPaper.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
};
AmberPaper.defaultProps = {
  classes: null,
};
export default AmberPaper;
