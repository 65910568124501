import React, { forwardRef } from 'react';
import AmberSectionTitle from './AmberSectionTitle';

const AmberSectionTitleRef = forwardRef((props, ref) => (
  <div ref={ref}>
    <AmberSectionTitle {...props} />
  </div>
));

export default AmberSectionTitleRef;
