import React from 'react';
import { Button } from '@material-ui/core';

function AmberButton({ children, onClick, disabled, testid }) {
  return (
    <Button
      data-testid={testid}
      disabled={disabled}
      onClick={onClick}
      variant="contained"
      color="primary"
    >
      {children}
    </Button>
  );
}

export default AmberButton;
