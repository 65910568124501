import React from 'react';
import { Box, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import PropTypes from 'prop-types';

// Style(s)
const useStyles = makeStyles((theme) => ({
  closeButton: {
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  backButton: {
    left: '-1ch',
    top: '0.5ch',
    color: theme.palette.grey[500],
  },
}));

// Component(s)
function AmberDialogTitle(props) {
  const {
    title,
    shouldDisplayCloseButton,
    shouldDisplayBackButton,
    handleBack,
    handleClose,
    titleProps,
  } = props;
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      {/* Back button */}
      {shouldDisplayBackButton && (
        <IconButton
          disableFocusRipple
          disableRipple
          className={classes.backButton}
          onClick={handleBack}
          aria-label="back"
          style={{ backgroundColor: 'transparent' }}
        >
          <ArrowBackIosRoundedIcon />
        </IconButton>
      )}

      {/* Title */}
      <DialogTitle id="form-dialog-title">
        <Box component="span" fontWeight="600" {...titleProps}>
          {title}
        </Box>
      </DialogTitle>

      {/* Close button */}
      {shouldDisplayCloseButton && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
}
AmberDialogTitle.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  shouldDisplayCloseButton: PropTypes.bool,
  shouldDisplayBackButton: PropTypes.bool,
  handleBack: PropTypes.func,
  titleProps: PropTypes.objectOf(PropTypes.string),
};
AmberDialogTitle.defaultProps = {
  open: false,
  shouldDisplayCloseButton: true,
  shouldDisplayBackButton: false,
  handleBack: () => {},
  titleProps: {},
  children: null,
};
export default AmberDialogTitle;
