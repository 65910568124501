import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, InputBase, makeStyles, styled } from '@material-ui/core';

const CustomDivider = styled(Divider)(({ theme }) => ({
  maxHeight: theme.spacing(2),
}));

const useStyles = makeStyles(() => ({
  name: {
    maxWidth: 200,
    fontSize: 14,
  },
  description: {
    fontSize: 14,
  },
}));

export default function Item(props) {
  const { item } = props;
  const classes = useStyles();

  function VerticalDivider() {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CustomDivider orientation="vertical" />
      </Box>
    );
  }

  return (
    <Box display="flex" width="100%" gridColumnGap={16}>
      <Box flex={1}>
        <InputBase
          id="term-name-field"
          value={item.name}
          className={classes.name}
          fullWidth
          readOnly
        />
      </Box>
      <VerticalDivider />
      <Box flex={3}>
        <InputBase
          value={item.description || ''}
          className={classes.description}
          fullWidth
          readOnly
        />
      </Box>
    </Box>
  );
}

Item.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
};
