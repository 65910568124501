import * as firebase from 'firebase/app';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  inMemoryPersistence,
  signOut,
} from 'firebase/auth';
import { getUser } from '../utils/api';
import apiClient from '../utils/apiClient';

import {
  STORAGE_USER_LOGGED_IN,
  STORAGE_USER_TYPE,
  STORAGE_USER_NAME,
} from '../utils/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_AUTH_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
};

export async function setupFirebaseAuthentication() {
  const firebaseApps = firebase.getApps();
  const isFirebaseInitialized = firebaseApps.length > 0;
  if (!isFirebaseInitialized) {
    initializeApp(firebaseConfig);
  }
  await getAuth().setPersistence(inMemoryPersistence);
}
export async function signInWithEmailPassword(username, password) {
  try {
    const auth = getAuth();

    const userCredential = await signInWithEmailAndPassword(
      auth,
      username,
      password,
    );
    const { user } = userCredential;
    if (user) {
      const idToken = await user.getIdToken();

      const formData = new FormData();
      formData.append('idToken', idToken);

      await apiClient.post('session/login', formData, {
        'Content-Type': 'multipart/form-data',
        withCredentials: true,
      });

      await signOut(auth);

      return new Promise((resolve, reject) => {
        getUser()
          .then((response) => {
            localStorage.setItem(STORAGE_USER_LOGGED_IN, 'true');
            localStorage.setItem(STORAGE_USER_NAME, response.data.userName);
            localStorage.setItem(
              STORAGE_USER_TYPE,
              JSON.stringify(response.data.roles),
            );
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
    return Promise.resolve(null);
  } catch (error) {
    return Promise.reject(error);
  }
}
