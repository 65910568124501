import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import AmberSectionTitle from '../../labels/AmberSectionTitle';

const useStyles = makeStyles((theme) => ({
  selectedView: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.white,
    fontWeight: 'bolder',
    border: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '1em 1em 1em 0.6em',
    lineHeight: '1.3em',
    fontSize: 'large',
  },
  numberIndicatorSelected: {
    fontWeight: 'bolder',
    color: theme.palette.primary.white,
    background: theme.palette.custom.darkGreenColor,
    lineHeight: '1.3em',
    border: `0.1em solid ${theme.palette.custom.darkGreenColor}`,
    width: '1.3em',
    height: '1em',
    borderRadius: '1em',
  },
  hiddenView: {
    color: theme.palette.custom.purplishGray,
    background: theme.palette.primary.white,
    display: 'flex',
    border: 'none',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '1em 1em 1em 0.6em',
    fontSize: 'large',
    lineHeight: '1.3em',
    '&:hover': {
      filter: 'brightness(85%)',
    },
  },
  numberIndicatorHidden: {
    background: theme.palette.custom.lighterGray,
    color: theme.palette.custom.purplishGray,
    border: `0.1em solid ${theme.palette.custom.lighterGray}`,
    width: '1.3em',
    height: '1em',
    lineHeight: '1.3em',
    borderRadius: '1em',
  },
}));

function Switch(props) {
  const classes = useStyles();
  const [t] = useTranslation();
  const { currentView, view, onClick, numberOfJobs } = props;
  const currentViewClass = clsx({
    [classes.selectedView]: currentView === view,
    [classes.hiddenView]: currentView !== view,
  });
  const currentIndicator = clsx({
    [classes.numberIndicatorSelected]: currentView === view,
    [classes.numberIndicatorHidden]: currentView !== view,
  });
  const onClickHandler = useCallback(() => {
    onClick(view);
  }, [onClick]);
  return (
    <button type="button" className={currentViewClass} onClick={onClickHandler}>
      <AmberSectionTitle
        text={t(`qualityControlDashboard.${view}`)}
        marginBottom={0}
      />
      <AmberSectionTitle
        text={numberOfJobs}
        typographyClasses={{ root: currentIndicator }}
        marginBottom={0}
      />
    </button>
  );
}
Switch.propTypes = {
  currentView: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  numberOfJobs: PropTypes.number.isRequired,
};
export default Switch;
