import moment from 'moment';

export const getFormattedSeconds = (seconds) =>
  moment.utc(seconds * 1000).format('HH:mm:ss');
export const getFormattedDate = (deadline, format) =>
  moment(deadline).format(format || 'DD-MM-YYYY HH:mm');
export const getCountDown = (deadline, locale) =>
  moment(deadline).locale(locale).from(new Date());
export const getTimeDifferenceInHours = (deadline) =>
  moment(deadline).diff(new Date(), 'hours');
export const isDateValid = (deadline) =>
  typeof deadline === 'number' && moment(deadline).isValid();
export const getUTCEpochTime = () => moment().valueOf();
