import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import NoMatch from './components/noMatch';
import Theme from './components/ui/Theme';
import JobManagerDashboard from './containers/JobManagerDashboard';
import TranscriberDashboard from './containers/TranscriberDashboard';
import QualityControllerDashboard from './containers/QualityControllerDashboard';
import AdminDashboard from './containers/AdminDashboard';
import Login from './containers/login';
import FirebaseLogin from './containers/FirebaseLogin';
import LoginSwitch from './containers/loginSwitch';
import PrivateRoute from './PrivateRoute';
import {
  ADMIN,
  PERFECT_JOB_MANAGER,
  TRANSCRIBER,
  QUALITY_CHECKER,
} from './roles';
import setupSentry from './sentry';
import TranscriberInvoiceDashboard from "./components/TranscriberInvoiceDashboard";

// Setup sentry
setupSentry();

export default function App(props) {
  const { resetRecoilState } = props;
  return (
    <BrowserRouter>
      <ThemeProvider theme={Theme}>
        <Switch>
          <Route exact path="/" component={LoginSwitch} />
          <Route exact path="/login_new" component={FirebaseLogin} />
          <Route exact path="/login_old" component={Login} />
          <PrivateRoute
            path="/admin/dashboard"
            component={AdminDashboard}
            roles={[ADMIN]}
            resetRecoilState={resetRecoilState}
          />
          <PrivateRoute
            path="/manager/dashboard"
            component={JobManagerDashboard}
            roles={[PERFECT_JOB_MANAGER]}
            resetRecoilState={resetRecoilState}
          />
          <PrivateRoute
            path="/transcriber/dashboard"
            component={TranscriberDashboard}
            roles={[TRANSCRIBER]}
            resetRecoilState={resetRecoilState}
          />
          <PrivateRoute
              path="/transcriber/invoices"
              component={TranscriberInvoiceDashboard}
              roles={[TRANSCRIBER]}
              resetRecoilState={resetRecoilState}
          />
          <PrivateRoute
            path="/quality-controller/dashboard"
            component={QualityControllerDashboard}
            roles={[QUALITY_CHECKER]}
            resetRecoilState={resetRecoilState}
          />
          <PrivateRoute
            path="/quality-controller/invoices"
            component={TranscriberInvoiceDashboard}
            roles={[QUALITY_CHECKER]}
            resetRecoilState={resetRecoilState}
          />
          <Route component={NoMatch} />
        </Switch>
      </ThemeProvider>
    </BrowserRouter>
  );
}
App.propTypes = {
  resetRecoilState: PropTypes.func.isRequired,
};
