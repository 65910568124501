/* eslint-disable no-console */
/* eslint-disable func-names */
import {
  ADMIN,
  PERFECT_JOB_MANAGER,
  QUALITY_CHECKER,
  TRANSCRIBER,
} from '../roles';
import {
  BASE_URL,
  CLIENT_ID,
  CLIENT_SECRET,
  WORD_EXPORT_ENDPOINT,
} from '../settings';
import {
  STORAGE_USER_LOGGED_IN,
  STORAGE_USER_NAME,
  STORAGE_USER_TYPE,
} from './auth';

// ******************** CONSTANTS ******************** \\
export const baseURL = `${BASE_URL}/v2/`;
export const wordExportEndpoint = WORD_EXPORT_ENDPOINT;

export const clientId = CLIENT_ID;
export const clientSecret = CLIENT_SECRET;

export const headers = {
  'Content-Type': 'application/json',
};

// ******************** METHODS ******************** \\
export function storeUserDetailsToLocalStorage(authData) {
  localStorage.setItem(STORAGE_USER_LOGGED_IN, 'true');
  localStorage.setItem(STORAGE_USER_NAME, authData.username);

  const role = getUserRole(authData.accessToken);
  localStorage.setItem(STORAGE_USER_TYPE, JSON.stringify(role));
}

export function storeGoogleSSOUserDetailsToLocalStorage(username, accessToken, roles) {
  localStorage.setItem(STORAGE_USER_LOGGED_IN, 'true');
  localStorage.setItem(STORAGE_USER_NAME, username);

  const role = getUserRoleFromRolesArray(roles);
  localStorage.setItem(STORAGE_USER_TYPE, JSON.stringify(role));

  window.dispatchEvent(new Event("localStorageChangeGoogleSSO"));
}

export function storeUserRoleToLocalStorageNoAccessToken(role) {
  localStorage.setItem(STORAGE_USER_TYPE, JSON.stringify(role));
}

function hasMultipleRoles(roles) {
  return (
    roles.length > 1 &&
    roles.every((role) => [TRANSCRIBER, QUALITY_CHECKER].includes(role))
  );
}

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

export function getUserRoleFromRolesArray(roles) {
  const filteredRoles = roles.filter((role) =>
    [ADMIN, PERFECT_JOB_MANAGER, QUALITY_CHECKER, TRANSCRIBER].includes(role),
  );
  if (filteredRoles.some((role) => role === ADMIN)) {
    return [ADMIN];
  }
  if (filteredRoles.some((role) => role === PERFECT_JOB_MANAGER)) {
    return [PERFECT_JOB_MANAGER];
  }

  if (hasMultipleRoles(filteredRoles)) {
    return [TRANSCRIBER, QUALITY_CHECKER];
  }

  if (filteredRoles.some((role) => role === TRANSCRIBER)) {
    return [TRANSCRIBER];
  }
  if (filteredRoles.some((role) => role === QUALITY_CHECKER)) {
    return [QUALITY_CHECKER];
  }
  return null;
}

export function getUserRole(accessToken) {
  const { roles } = parseJwt(accessToken);
  return getUserRoleFromRolesArray(roles);
}
