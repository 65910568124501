import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import RatingButton from './ratingButton';
import generateKey from '../../../utils/generateReactKey';
import AmberSectionTitle from '../../labels/AmberSectionTitle';
import AmberFormHelper from '../../labels/AmberFormHelper';
import AmberTooltip from "../tooltips/AmberTooltip";
import HelpIcon from "@mui/icons-material/Help";

const useStyles = makeStyles((theme) => ({
  evaluationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1em',
  },
  numberButtons: {
    display: 'flex',
    flexDirection: 'row',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '0.3em',
  },
  sectionTitle: {
    flex: '0 1 50%',
    color: theme.palette.custom.charcoalGray,
    fontWeight: '900',
    marginBottom: 0,
    maxHeight: '1.5em',
  },
  sectionTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  sectionExplanation: {
    color: theme.palette.custom.purplishGray,
  },
    topicContainer: {
    display: 'flex',
    flexDirection: 'row',
    whiteSpace: 'nowrap'
  }
}));

const getNumbersArray = (min, max) =>
  Array.from({ length: max }, (_, number) => number + min);

function RatingSection(props) {
  const { max, min, label, explanation, tooltip, ...rest } = props;
  const ratingNumbers = getNumbersArray(min, max);
  const classes = useStyles();

  return (
    <div className={classes.evaluationContainer}>
      <div className={classes.sectionTitleContainer}>
        <div className={classes.topicContainer}>
            <AmberSectionTitle
                typographyVariant="subtitle2"
                text={label}
                typographyClasses={{ root: classes.sectionTitle }}
                marginBottom={0}
            />
            {tooltip && <AmberTooltip
                tooltip={tooltip}
                testid="qc-check-info-tooltip"
            >
                <HelpIcon
                    sx={{ color: 'custom.gray', pl: 1 }}
                    data-testid="qc-check-info-icon"
                />
            </AmberTooltip>}
        </div>
        {explanation && <AmberFormHelper error={false} text={explanation} />}
      </div>
      <div className={classes.numberButtons}>
        {ratingNumbers.map((number, index) => (
          <RatingButton number={number} key={generateKey(index)} {...rest} />
        ))}
      </div>
    </div>
  );
}
RatingSection.propTypes = {
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  explanation: PropTypes.string,
  tooltip: PropTypes.string,
};

RatingSection.defaultProps = {
  explanation: null,
  tooltip: null,
};
export default RatingSection;
