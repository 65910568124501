import { atom, selector } from 'recoil';

import { getQualityControlJobs, getQualityChecks } from '../utils/api';
import {
  OPEN_JOBS_LIST,
  ACTIVE_JOBS_LIST,
  COMPLETED_JOBS_LIST,
  OPEN_JOBS_QUERY,
  ACTIVE_JOBS_QUERY,
  COMPLETED_JOBS_QUERY,
  CURRENT_VIEW,
  NUMBER_OPEN_ACTIVE_JOBS,
  NUMBER_COMPLETED_JOBS,
  NUMBER_ACTIVE_JOBS,
  NUMBER_OPEN_JOBS,
  SRT_MISSING,
} from './constants';
import {
  QUALITY_CONTROLLER_ACTIVE,
  QUALITY_CONTROLLER_OPEN,
} from '../constants/jobStatuses';

import { QUALITY_CHECKER_OPEN_VIEW } from '../constants/constants';
import { currentUserLoginDetailsAtom, currentUserRoleAtom } from './user';
import { QUALITY_CHECKER } from '../roles';

const isLoggedIn = (userDetails) =>
  userDetails.isLoggedIn;
export const getOpenJobsAsyncSelector = selector({
  key: OPEN_JOBS_QUERY,
  get: async ({ get }) => {
    const userDetails = get(currentUserLoginDetailsAtom);
    const roles = get(currentUserRoleAtom);
    let jobs = [];
    if (
      isLoggedIn(userDetails) &&
      roles.length === 1 &&
      roles[0] === QUALITY_CHECKER
    ) {
      const request = await getQualityControlJobs(QUALITY_CONTROLLER_OPEN);
      jobs = request.data.content;
    }

    return jobs;
  },
});
export const getCompletedJobsSelector = selector({
  key: COMPLETED_JOBS_QUERY,
  get: async ({ get }) => {
    const userDetails = get(currentUserLoginDetailsAtom);
    const roles = get(currentUserRoleAtom);
    let jobs = [];
    if (
      isLoggedIn(userDetails) &&
      roles.length === 1 &&
      roles[0] === QUALITY_CHECKER
    ) {
      const request = await getQualityChecks();
      jobs = request.data.content;
    }

    return jobs;
  },
});
export const getActiveJobsSelector = selector({
  key: ACTIVE_JOBS_QUERY,
  get: async ({ get }) => {
    const userDetails = get(currentUserLoginDetailsAtom);
    const roles = get(currentUserRoleAtom);
    let jobs = [];
    if (
      isLoggedIn(userDetails) &&
      roles.length === 1 &&
      roles[0] === QUALITY_CHECKER
    ) {
      const request = await getQualityControlJobs(QUALITY_CONTROLLER_ACTIVE);
      jobs = request.data.content;
    }

    return jobs;
  },
});
export const getNumberOfOpenActiveJobsSelector = selector({
  key: NUMBER_OPEN_ACTIVE_JOBS,
  get: ({ get }) => {
    const activeJobs = get(activeJobsAtom);
    const openJobs = get(openJobsAtom);
    return activeJobs.length + openJobs.length;
  },
});
export const getNumberOfActiveJobsSelector = selector({
  key: NUMBER_ACTIVE_JOBS,
  get: ({ get }) => {
    const activeJobs = get(activeJobsAtom);
    return activeJobs.length;
  },
});
export const getNumberOfOpenJobsSelector = selector({
  key: NUMBER_OPEN_JOBS,
  get: ({ get }) => {
    const openJobs = get(openJobsAtom);
    return openJobs.lengthl;
  },
});
export const getNumberOfCompletedJobsSelector = selector({
  key: NUMBER_COMPLETED_JOBS,
  get: ({ get }) => {
    const jobs = get(completedJobsAtom);
    return jobs.length;
  },
});

export const openJobsAtom = atom({
  key: OPEN_JOBS_LIST,
  default: getOpenJobsAsyncSelector,
});
export const completedJobsAtom = atom({
  key: COMPLETED_JOBS_LIST,
  default: getCompletedJobsSelector,
});
export const activeJobsAtom = atom({
  key: ACTIVE_JOBS_LIST,
  default: getActiveJobsSelector,
});

export const currentViewAtom = atom({
  key: CURRENT_VIEW,
  default: QUALITY_CHECKER_OPEN_VIEW,
});

export const missingSrtAtom = atom({
  key: SRT_MISSING,
  default: false,
});
