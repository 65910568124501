import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import generateKey from '../../../utils/generateReactKey';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  tabs: {
    marginBottom: '0.5em',
    width: '100%',
    maxWidth: '100%',
  },
  tab: {
    flex: '0 1 34%',
    fontSize: '0.8em',
  },
  tabWrapper: {
    display: 'inline-flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  labelIcon: {
    minHeight: 'fit-content',
  },
}));

function ScrollableTabsButtonForce(props) {
  const { tabs, tabPanelContents, tabIndex } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(tabIndex);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        indicatorColor="primary"
        textColor="primary"
        aria-label="scrollable force tabs"
        classes={{ root: classes.tabs }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.label}
            classes={{
              root: classes.tab,
              wrapper: classes.tabWrapper,
              labelIcon: classes.labelIcon,
            }}
            label={tab.label}
            {...(tab.icon ? { icon: tab.icon } : {})}
            {...a11yProps(0)}
          />
        ))}
      </Tabs>
      {tabPanelContents.map((tabPanel, index) => (
        <TabPanel value={value} index={index} key={generateKey(index)}>
          {tabPanel}
        </TabPanel>
      ))}
    </div>
  );
}

ScrollableTabsButtonForce.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.element,
    }),
  ).isRequired,
  tabPanelContents: PropTypes.arrayOf(PropTypes.element).isRequired,
  tabIndex: PropTypes.number,
};

ScrollableTabsButtonForce.defaultProps = {
  tabIndex: 0,
};

export default ScrollableTabsButtonForce;
