export const REJECT_TRANSCIPT = 'REJECT_TRANSCIPT';
export const REJECT_TRANSCIPT_OK = 'REJECT_TRANSCIPT_OK';
export const REJECT_TRANSCIPT_FAIL = 'REJECT_TRANSCIPT_FAIL';

export const GET_TRANSCRIPT_LIST = 'GET_TRANSCRIPT_LIST';
export const GET_TRANSCRIPT_LIST_OK = 'GET_TRANSCRIPT_LIST_OK';
export const GET_TRANSCRIPT_LIST_FAIL = 'GET_TRANSCRIPT_LIST_FAIL';

export const TRANSCRIPTION_TYPE_TRANSCRIPTION = 'transcription';
export const TRANSCRIPTION_TYPE_CAPTIONS = 'captions';
export const TRANSCRIPTION_TYPE_TRANSLATED_SUBTITLES = 'translatedSubtitles';

export const GET_JOB_LIST = 'GET_JOB_LIST';
export const GET_JOB_LIST_OK = 'GET_JOB_LIST_OK';
export const GET_JOB_LIST_FAIL = 'GET_JOB_LIST_FAIL';
export const CHECK_JOB_LIST_ITEM = 'CHECK_JOB_LIST_ITEM';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_ACCOUNT_LANGUAGE = 'SET_ACCOUNT_LANGUAGE';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const INVITE_TRANSCRIBERS = 'INVITE_TRANSCRIBERS';

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

export const QUALITY_CHECKER_OPEN_VIEW = 'QUALITY_CONTROLLER_OPEN_VIEW';
export const QUALITY_CHEKER_COMPLETED_VIEW =
  'QUALITY_CONTROLLER_COMPLETED_VIEW';

export const RATING_COMPONENT_ID = 'RATING_COMPONENT_ID';
export const FILE_INPUT_ID = 'FILE_INPUT_ID';
export const TEXT_INPUT_ID = 'TEXT_INPUT_ID';

export const WORKFLOW_STYLES = {
  BASIC: 'BASIC',
  FORMATTED: 'FORMATTED',
  HQ: 'HQ',
  TRANSLATION_BASIC: 'TRANSLATION - BASIC',
  TRANSLATION_FORMATTED: 'TRANSLATION - FORMATTED',
  TRANSLATION_HQ: 'TRANSLATION - HQ',
};

export const RECOIL_LOADABLE_LOADING_STATE = 'loading';
export const RECOIL_LOADABLE_DONE_STATE = 'hasValue';
export const RECOIL_LOADABLE_ERROR_STATE = 'hasError';

export const RATING_PERFECT_DONE_STATE = 'ACCEPTED';
export const RATING_PERFECT_REVIEW_NOT_PASSED_STATE = 'REJECTED';
