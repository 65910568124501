import React from 'react';
import PropTypes from 'prop-types';
import { Box, Chip, styled, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

export default function Names(props) {
  const { names } = props;

  const [t] = useTranslation();

  const CustomChip = styled(Chip)(({ theme }) => ({
    margin: theme.spacing(0.25),
  }));

  const isNamesNotEmpty = () => names && names.length !== 0;

  return (
    <>
      {isNamesNotEmpty() && (
        <Box display="flex" flexDirection="column" gridRowGap={12}>
          <span>{t('common.glossary.names')}</span>
          <Autocomplete
            data-testid="dialog-names-tf"
            multiple
            id="tags-filled"
            options={[]}
            value={names}
            freeSolo
            renderTags={(val) =>
              val.map((name) => <CustomChip label={name} key={name} />)
            }
            renderInput={(params) => (
              <TextField
                variant="outlined"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
              />
            )}
            disabled
          />
        </Box>
      )}
    </>
  );
}

Names.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string).isRequired,
};
