import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import {
  PERFECT_OPEN,
  PERFECT_IN_QUEUE,
  PERFECT_EDITING,
  PERFECT_TO_BE_REVIEWED,
  PERFECT_AWAITING_CONFIRMATION_TRANSCRIBER,
  PERFECT_REVIEW_NOT_PASSED,
  PERFECT_PAYMENT_PENDING,
} from '../../constants/jobStatuses';
import { ADMIN } from '../../roles';

const useStyle = makeStyles((theme) => ({
  resetBtn: {
    minWidth: 120,
  },
}));

function OpenDialog(props) {
  const { openDialog, closeDialog, handleCancel } = props;
  const [t, i18n] = useTranslation();

  function getDescription() {
    return t('managerDashboard.table.cancelJob.description');
  }

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {getDescription()}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="secondary">
          {t('managerDashboard.table.cancelJob.no')}
        </Button>
        <Button onClick={() => handleCancel()} color="primary" autoFocus>
          {t('managerDashboard.table.cancelJob.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ResetButton(props) {
  const { role, job, handleCancelJob } = props;
  const [t, i18n] = useTranslation();
  const classes = useStyle();
  const [openDialog, setOpenDialog] = useState(false);

  function closeDialog() {
    setOpenDialog(false);
  }

  function handleCancel() {
    handleCancelJob(job.jobId);
    closeDialog();
  }

  function shouldBeShown(status) {
    if (role.includes(ADMIN)) {
      switch (status) {
        case PERFECT_OPEN:
        case PERFECT_PAYMENT_PENDING:
        case PERFECT_AWAITING_CONFIRMATION_TRANSCRIBER:
        case PERFECT_IN_QUEUE:
        case PERFECT_EDITING:
        case PERFECT_TO_BE_REVIEWED:
        case PERFECT_REVIEW_NOT_PASSED:
          return true;
        default:
          return false;
      }
    } else {
      return false;
    }
  }

  if (!shouldBeShown(job.jobStatus)) return null;

  return (
    <div>
      <OpenDialog
        closeDialog={closeDialog}
        role={role}
        job={job}
        handleCancel={handleCancel}
        openDialog={openDialog}
      />
      <Button
        variant="contained"
        color="secondary"
        className={classes.resetBtn}
        onClick={() => setOpenDialog(true)}
      >
        {t('managerDashboard.table.resetStatus.cancel')}
      </Button>
    </div>
  );
}

export default ResetButton;
