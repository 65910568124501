import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import FileInput from './uploadFileInternal';
import useSnackbar from '../../utils/useSnackbar';
import { FILE_ADDED, FILE_REMOVED } from './inputActionsConstants';

const filterFile = (files, initialFiles) => {
  const file = files.pop();
  return initialFiles.filter((f) => f.name !== file.name);
};

const isFileNameRestrictionEnforced = (
  name,
  enforceNameMatch,
  objectIdentifier,
) => {
  if (!enforceNameMatch) {
    return true;
  }
  return name
    .split('.')
    .some((namePart) => namePart.split(/\s/gu).includes(objectIdentifier));
};

const isFileAllowed = (fileName, supportedFileTypes) => {
  const fileExtension = `.${fileName.split('.').pop()}`;
  return supportedFileTypes.includes(fileExtension);
};

const canUpload = (files, currentFiles, allowMultipleFiles) => {
  if (!allowMultipleFiles && (files.length > 1 || currentFiles.length === 1)) {
    return false;
  }
  return !currentFiles.some((existingFile) =>
    files.some((uploadedFile) => uploadedFile.name === existingFile.name),
  );
};

function DragAndDropFileInput(props) {
  const {
    uploadFunction,
    enforceNameMatch,
    objectIdentifier,
    allowMultipleFiles,
    supportedFileTypes,
    handleSyncChange,
    id,
  } = props;

  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [attachment, setAttachment] = useState([]);
  const { showWarning, showError } = useSnackbar();

  const handleAsyncUpload = useCallback(async (acceptedFiles) => {
    try {
      setIsLoading(true);
      await uploadFunction(acceptedFiles);
      setIsLoading(false);
      setAttachment([...attachment, ...Object.values(acceptedFiles)]);
    } catch (error) {
      setIsLoading(false);
      showError(error.message);
    }
  });

  const handleAcceptedFilesChange = (
    acceptedFiles,
    files,
    action,
    duplicatedFiles,
  ) => {
    if (!Object.keys(acceptedFiles).length) {
      showError(t('common.fileUploading.noFilesAllowed'));
      return;
    }
    if (files.length !== Object.keys(acceptedFiles).length) {
      showWarning(
        `${Object.keys(acceptedFiles).length} ${t(
          'common.fileUploading.outOf',
        )} ${files.length} ${t(
          'common.fileUploading.wereAccepted',
        )}. ${duplicatedFiles} ${t('common.fileUploading.duplicatedFiles')}`,
      );
    }
    if (uploadFunction) {
      handleAsyncUpload(acceptedFiles);
      return;
    }
    const newAttachment = [...attachment, ...Object.values(acceptedFiles)];
    setAttachment(newAttachment);
    if (handleSyncChange) {
      handleSyncChange({ target: { value: newAttachment, id, action } });
    }
  };

  const handleAddedFiles = useCallback(
    (files, action) => {
      if (!canUpload(files, attachment, allowMultipleFiles)) {
        showWarning(t('common.fileUploading.multipleFilesNotAllowed'));
        return;
      }
      const acceptedFiles = {};
      let duplicatedFiles = 0;

      files.forEach((file) => {
        if (!file || !file.name) {
          return;
        }
        const { name } = file;
        if (
          isFileNameRestrictionEnforced(
            name,
            enforceNameMatch,
            objectIdentifier,
          ) &&
          isFileAllowed(name, supportedFileTypes)
        ) {
          acceptedFiles[name] = file;
        }
        if (acceptedFiles[name]) {
          duplicatedFiles += 1;
        }
      });
      handleAcceptedFilesChange(acceptedFiles, files, action, duplicatedFiles);
    },
    [handleSyncChange, handleAsyncUpload, setAttachment],
  );

  const handleRemovedFiles = useCallback(
    (files, action) => {
      const filteredFiles = [...filterFile(files, attachment)];
      setAttachment(filteredFiles);
      if (handleSyncChange) {
        handleSyncChange({
          target: { value: filteredFiles, id, action },
        });
      }
    },
    [handleSyncChange, handleAsyncUpload, setAttachment],
  );

  const handleInputChange = useCallback((files, action) => {
    switch (action) {
      case FILE_ADDED:
        handleAddedFiles(files, action);
        break;
      case FILE_REMOVED:
        handleRemovedFiles(files, action);
        break;
      default:
        break;
    }
  });

  return (
    <FileInput
      attachment={attachment}
      handleFileChange={handleInputChange}
      allowMultipleFiles={allowMultipleFiles}
      supportedFileTypes={supportedFileTypes}
      isLoading={isLoading}
    />
  );
}

DragAndDropFileInput.propTypes = {
  uploadFunction: PropTypes.func,
  enforceNameMatch: PropTypes.bool,
  objectIdentifier: PropTypes.string,
  allowMultipleFiles: PropTypes.bool,
  supportedFileTypes: PropTypes.arrayOf(PropTypes.string),
  handleSyncChange: PropTypes.func,
  id: PropTypes.string,
};
DragAndDropFileInput.defaultProps = {
  uploadFunction: null,
  enforceNameMatch: false,
  objectIdentifier: null,
  allowMultipleFiles: false,
  supportedFileTypes: ['.srt'],
  handleSyncChange: null,
  id: null,
};
export default DragAndDropFileInput;
