import React from 'react';
import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AmberDialogTitle from './AmberDialogTitle';

// Style(s)
const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

// Component(s)
function AmberDialog(props) {
  const {
    children,
    open,
    handleClose,
    title,
    shouldDisplayCloseButton,
    shouldDisplayBackButton,
    handleBack,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      classes={{ root: classes.dialog, paper: classes.paper }}
      {...rest}
    >
      <AmberDialogTitle
        title={title}
        shouldDisplayCloseButton={shouldDisplayCloseButton}
        shouldDisplayBackButton={shouldDisplayBackButton}
        handleBack={handleBack}
        handleClose={handleClose}
      />
      {children}
    </Dialog>
  );
}
AmberDialog.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  shouldDisplayCloseButton: PropTypes.bool,
  shouldDisplayBackButton: PropTypes.bool,
  handleBack: PropTypes.func,
};
AmberDialog.defaultProps = {
  open: false,
  shouldDisplayCloseButton: true,
  shouldDisplayBackButton: false,
  handleBack: () => {},
  handleClose: () => {},
};

export default AmberDialog;
