import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import {
  PERFECT_OPEN,
  PERFECT_IN_QUEUE,
  PERFECT_EDITING,
  PERFECT_TO_BE_REVIEWED,
  PERFECT_AWAITING_CONFIRMATION_TRANSCRIBER,
  PERFECT_REVIEW_NOT_PASSED,
  PERFECT_CANCELLED,
  BUSY,
} from '../../constants/jobStatuses';
import { ADMIN, PERFECT_JOB_MANAGER } from '../../roles';

const useStyle = makeStyles((theme) => ({
  root: {
    marginRight: 4,
  },
  resetBtn: {
    minWidth: 120,
  },
}));

function OpenDialog(props) {
  const { openDialog, role, closeDialog, resetStatusOfThisJob } = props;
  const [t, i18n] = useTranslation();

  function getDescription() {
    if (role.includes(ADMIN)) {
      return t('managerDashboard.table.resetStatus.descriptionAdmin');
    } else {
      return t('managerDashboard.table.resetStatus.descriptionPJM');
    }
  }

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {getDescription()}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="secondary">
          {t('managerDashboard.table.resetStatus.cancel')}
        </Button>
        <Button
          onClick={() => resetStatusOfThisJob()}
          color="primary"
          autoFocus
        >
          {t('managerDashboard.table.resetStatus.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ResetButton(props) {
  const { role, job, handleReopenJobs } = props;
  const classes = useStyle();
  const [openDialog, setOpenDialog] = useState(false);
  const [t, i18n] = useTranslation();

  function closeDialog() {
    setOpenDialog(false);
  }

  function resetStatusOfThisJob() {
    handleReopenJobs(job.jobId);
    closeDialog();
  }

  function shouldBeShownForAdmin(status) {
    if (status === BUSY) {
      return false;
    } else {
      return true;
    }
  }

  function shouldBeShownForPJM(status) {
    switch (status) {
      case PERFECT_OPEN:
      case PERFECT_IN_QUEUE:
      case PERFECT_EDITING:
      case PERFECT_AWAITING_CONFIRMATION_TRANSCRIBER:
      case PERFECT_TO_BE_REVIEWED:
      case PERFECT_REVIEW_NOT_PASSED:
        return true;
      default:
        return false;
    }
  }

  if (role.includes(ADMIN) && shouldBeShownForAdmin(job.jobStatus)) {
    return (
      <div className={classes.root}>
        <OpenDialog
          closeDialog={closeDialog}
          role={role}
          job={job}
          resetStatusOfThisJob={resetStatusOfThisJob}
          openDialog={openDialog}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.resetBtn}
          onClick={() => setOpenDialog(true)}
        >
          {t('managerDashboard.table.values.resetStatus')}
        </Button>
      </div>
    );
  } else if (
    role.includes(PERFECT_JOB_MANAGER) &&
    shouldBeShownForPJM(job.jobStatus)
  ) {
    return (
      <div className={classes.root}>
        <OpenDialog
          closeDialog={closeDialog}
          role={role}
          job={job}
          resetStatusOfThisJob={resetStatusOfThisJob}
          openDialog={openDialog}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.resetBtn}
          onClick={() => setOpenDialog(true)}
        >
          {t('managerDashboard.table.values.resetStatus')}
        </Button>
      </div>
    );
  } else {
    return null;
  }
}

export default ResetButton;
