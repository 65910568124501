import React from 'react';
import { Button, Tooltip } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import StyledMenuItem from './styledMenuItem';
import StyledMenu from './styledMenu';

import { getUserName } from '../../utils/auth';
import IconAvatar from '../../assets/images/icons/custom-avatar.svg';

import './header.scss';
import '../ui/HeaderButton.scss';
import { logout } from '../../utils/api';

const useStyles = makeStyles((theme) => ({
  popperClose: {
    pointerEvents: 'none',
  },
  menuList: {
    minWidth: 256,
  },
  [theme.breakpoints.down('sm')]: {
    buttonText: {
      display: 'none',
    },
  },
}));

function ProfileMenu(props) {
  const { resetRecoilState } = props;
  const classes = useStyles();
  const [t] = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const userName = getUserName();

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  const handleLogout = async () => {
    await logout();
    resetRecoilState();
  };

  return (
    <>
      <Tooltip title={t('nav.profileTooltip')} placement="bottom">
        <Button
          aria-controls="ProfileMenu"
          className="HeaderButton"
          color="primary"
          aria-haspopup="true"
          variant="contained"
          onClick={handleClick}
        >
          <i>
            <img src={IconAvatar} alt="avatar" />
          </i>
          {userName && (
            <strong className={classes.buttonText}>{userName}</strong>
          )}
        </Button>
      </Tooltip>
      <StyledMenu
        className={classes.menuList}
        id="ProfileMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={() => handleLogout()}>
          <ListItemText primary={t('nav.logout')} />
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
}

ProfileMenu.propTypes = {
  resetRecoilState: PropTypes.func,
};
ProfileMenu.defaultProps = {
  resetRecoilState: null,
};
export default ProfileMenu;
