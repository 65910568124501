import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

import AmberSectionTitle from '../../labels/AmberSectionTitle';
import AmberFormHelper from '../../labels/AmberFormHelper';
import DragAndDropFileInput from '../../upload_file_input/loadingDragDropInput';
import RatingForm from './ratingForm';
import {
  FILE_INPUT_ID,
  RATING_COMPONENT_ID,
  TEXT_INPUT_ID,
  TRANSCRIPTION_TYPE_TRANSCRIPTION,
} from '../../../constants/constants';
import { getRatingDefaults } from '../../../constants/ratingCategories';
import AmberCustomBoxTextArea from '../../textfields/amberCustomBoxTextArea';
import { getFileTypesBasedOnAllowedUploads } from '../../../utils/fileFormatUtils';
import { getUserName } from '../../../utils/auth';

const useStyles = makeStyles((theme) => ({
  contentsContainer: { display: 'flex', flexDirection: 'column' },
  label: {
    color: theme.palette.custom.charcoalGray,
  },
  dropDownContainer: {
    marginBottom: '1em',
  },
}));

const getComponentsForWorkFlow = ({
  uploadOptions,
  editorEnabled,
  transcriptionType,
  classes,
  handleFormChange,
  _id,
  t,
}) =>
  !editorEnabled && (
    <>
      <AmberSectionTitle
        typographyClasses={{ root: classes.label }}
        typographyVariant="body1"
        text={t('dialogJobFeedback.uploadFile')}
      />
      <AmberSectionTitle
        typographyClasses={{ root: classes.label }}
        typographyVariant="body1"
        text={`${t(
          'transcriberDashBoard.uploadCaptions.availableFormats',
        )} ${uploadOptions.reduce((acc, val) => `${acc}${val}, `, '')}`}
      />
      <AmberFormHelper
        text={
          TRANSCRIPTION_TYPE_TRANSCRIPTION === transcriptionType
            ? t('dialogJobFeedback.uploadSrtHelperTranscript', { jobId: _id })
            : t('dialogJobFeedback.uploadSrtHelper', { jobId: _id })
        }
      />
      <DragAndDropFileInput
        enforceNameMatch
        objectIdentifier={_id}
        allowMultipleFiles
        id={FILE_INPUT_ID}
        handleSyncChange={handleFormChange}
        supportedFileTypes={getFileTypesBasedOnAllowedUploads(uploadOptions)}
      />
    </>
  );

const linkToGuidelines = (t, userName) => (
  <div>
    {t('dialogJobFeedback.rateTranscriber', { email: userName })}
    <a
      target="_blank"
      href="https://helpdesk.amberscript.com/en/quality-control-platform-workflow"
      rel="noreferrer noopener"
    >
      {t('dialogJobFeedback.qualityControlGuidelines')}
    </a>
  </div>
);

function JobRatingContents(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const { job } = props;
  const [notesError, setNotesError] = useState(false);
  const userName = job?.transcriber || '';

  const {
    handleChange,
    editorEnabled,
    issueType,
    notes,
    uploadOptions,
    job: { _id, workflowStyle, transcriptionType },
  } = props;

  const handleFormChange = useCallback(
    (event) => {
      let target = event;
      if (event.target) {
        target = event.target;
      }
      const { value, id } = target;

      handleChange({
        id,
        value,
      });
    },
    [handleChange],
  );

  useEffect(() => {
    if (notes !== undefined) {
      if (!notes.trim()) {
        setNotesError(true);
        return;
      }
      setNotesError(false);
    }
  }, [notes, issueType]);

  return (
    <div className={classes.contentsContainer}>
      <div className={classes.dropDownContainer}>
        {getComponentsForWorkFlow({
          uploadOptions,
          editorEnabled,
          transcriptionType,
          classes,
          handleFormChange,
          _id,
          t,
        })}
      </div>
      <AmberSectionTitle
        typographyVariant="caption"
        text={linkToGuidelines(t, userName)}
        component="strong"
      />
      <RatingForm
        id={RATING_COMPONENT_ID}
        handleRatingChange={handleFormChange}
        customRatings={getRatingDefaults(t, workflowStyle, transcriptionType)}
      />
      <AmberCustomBoxTextArea
        handleChange={handleFormChange}
        value={notes}
        id={TEXT_INPUT_ID}
        hasError={notesError}
        formHelperText={t('dialogJobFeedback.notesExplanation')}
        placeholder={t('dialogJobFeedback.textAreaPlaceholder')}
        rows={13}
      />
    </div>
  );
}

JobRatingContents.propTypes = {
  issueType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ]),
  notes: PropTypes.string,
  uploadOptions: PropTypes.arrayOf(PropTypes.string),
  handleChange: PropTypes.func.isRequired,
  editorEnabled: PropTypes.bool,
  job: PropTypes.shape({
    _id: PropTypes.string,
    workflowStyle: PropTypes.string,
    transcriber: PropTypes.string,
    transcriptionType: PropTypes.string,
  }).isRequired,
};

JobRatingContents.defaultProps = {
  issueType: undefined,
  notes: undefined,
  uploadOptions: null,
  editorEnabled: true,
};

export default JobRatingContents;
