const data = [
  {
    languageName: 'Danish',
    languageCode: 'da',
  },
  {
    languageName: 'French',
    languageCode: 'fr',
  },
  {
    languageName: 'English',
    languageCode: 'en',
  },
  {
    languageName: 'Czech',
    languageCode: 'cs',
  },
  {
    languageName: 'Chinese',
    languageCode: 'zh',
  },
  {
    languageName: 'Dutch',
    languageCode: 'nl',
  },
  {
    languageName: 'Finnish',
    languageCode: 'fi',
  },
  {
    languageName: 'Hungarian',
    languageCode: 'hu',
  },
  {
    languageName: 'German',
    languageCode: 'de',
  },
  {
    languageName: 'Italian',
    languageCode: 'it',
  },
  {
    languageName: 'Portuguese',
    languageCode: 'pt',
  },
  {
    languageName: 'Russian',
    languageCode: 'ru',
  },
  {
    languageName: 'Spanish (EU)',
    languageCode: 'es',
  },
  {
    languageName: 'Spanish (LATAM)',
    languageCode: 'es-la',
  },
  {
    languageName: 'Irish',
    languageCode: 'ga',
  },
  {
    languageName: 'Polish',
    languageCode: 'pl',
  },
  {
    languageName: 'Romanian',
    languageCode: 'ro',
  },
  {
    languageName: 'Swedish',
    languageCode: 'sv',
  },
  {
    languageName: 'Japanese',
    languageCode: 'ja',
  },
  {
    languageName: 'Korean',
    languageCode: 'ko',
  },
  {
    languageName: 'Norwegian',
    languageCode: 'no',
  },
  {
    languageName: 'German (Austria)',
    languageCode: 'de-at',
  },
  {
    languageName: 'German (Switzerland)',
    languageCode: 'de-ch',
  },
  {
    languageName: 'Portuguese (Brazil)',
    languageCode: 'pt-br',
  },
  {
    languageName: 'French (Canada)',
    languageCode: 'fr-ca',
  },
  {
    languageName: 'English (Australia)',
    languageCode: 'en-au',
  },
  {
    languageName: 'English (United Kingdom)',
    languageCode: 'en-uk',
  },
  {
    languageName: 'Dutch (Flemish)',
    languageCode: 'nl-be',
  },
  {
    languageName: 'Catalan',
    languageCode: 'ca',
  },
  {
    languageName: 'Mandarin',
    languageCode: 'cmn',
  },
];

export default function intlConvertor(name) {
  const labelExisted = data.some((d) => d.languageCode === name);
  if (name === 'en-ca') {
    return `French (Canada)`;
  } else if (labelExisted) {
    return data.filter((d) => d.languageCode === name)[0].languageName;
  } else {
    try {
      const languageNames = new Intl.DisplayNames(['en'], { type: 'language' });
      return languageNames.of(name);
    } catch (error) {
      return name;
    }
  }
}
