import React from 'react';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// Style(s)
const useStyles = makeStyles((theme) => ({
  iconButton: {
    margin: theme.spacing(1),
  },
}));

function AmberIconButton(props) {
  const { label, icon, onClick, color, ...rest } = props;
  const classes = useStyles();

  return (
    <IconButton
      aria-label={label}
      className={classes.iconButton}
      onClick={onClick}
      color={color || 'inherit'}
      {...rest}
    >
      {icon}
    </IconButton>
  );
}
AmberIconButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
};

AmberIconButton.defaultProps = { color: 'inherit', label: '' };
export default AmberIconButton;
