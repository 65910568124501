import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, withStyles } from '@material-ui/core';
import logo from '../../assets/images/new_logo_square.jpg';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    minHeight: theme.mixins.toolbar.minHeight,
  },
  img: {
    width: 28,
    height: 'auto',
    boxSizing: 'content-box',
    padding: `0 ${theme.spacing(1)}px`,
  },
  [theme.breakpoints.up('xs')]: {
    img: {
      width: 33,
      padding: `0 ${theme.spacing(1.5)}px`,
    },
  },
  [theme.breakpoints.up('md')]: {
    img: {
      width: 39,
      padding: `0 ${theme.spacing(2)}px`,
    },
  },
});

function Logo(props) {
  const { to, href, tooltip } = props;
  const img = (
    <img
      className={props.classes.img}
      src={logo}
      alt="Amberscript"
      height="59"
      width="54"
    />
  );

  if (to) {
    return (
      <Tooltip title={tooltip} placement="bottom">
        <Link to={to} className={props.classes.root}>
          {img}
        </Link>
      </Tooltip>
    );
  } else if (href) {
    return (
      <Tooltip title={tooltip} placement="bottom">
        <a href={href} className={props.classes.root}>
          {img}
        </a>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={tooltip} placement="bottom">
        <span className={props.classes.root}>{img}</span>
      </Tooltip>
    );
  }
}

export default withStyles(styles)(Logo);
