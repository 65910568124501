/* eslint-disable no-console */
import React, { useCallback } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import AmberLoadingButton from '../buttons/AmberLoadingButton';
import UploadedFile from './uploadedFile';
import { FILE_ADDED } from './inputActionsConstants';
import generateKey from '../../utils/generateReactKey';

const useStyles = makeStyles((theme) => ({
  dragDrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    '&:focus': {
      outline: 'none',
    },
  },
  btnUpload: {
    marginTop: 24,
    textTransform: 'none',
  },
  spinnerClass: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '70%',
    left: '50%',
  },
}));

const FileInput = (props) => {
  const {
    handleFileChange,
    isLoading,
    allowMultipleFiles,
    attachment,
    supportedFileTypes,
  } = props;

  const theme = useTheme();
  const classes = useStyles();
  const [t] = useTranslation();

  const handleChange = useCallback(
    (event) => {
      const {
        target: { value, action },
      } = event;

      const files = Array.isArray(value) ? Array.from(value) : [value];
      handleFileChange(files, action);
    },
    [handleFileChange],
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      const synteticEvent = {
        target: {
          value: acceptedFiles,
          action: FILE_ADDED,
        },
      };
      handleChange(synteticEvent);
    },
    [attachment],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: isLoading,
  });

  return (
    <>
      <Box
        display="flex"
        position="relative"
        height={100}
        border="2px dashed rgb(0, 90, 80)"
        borderRadius={8}
        p={2}
      >
        <div {...getRootProps()} className={classes.dragDrop}>
          <input {...getInputProps()} accept={supportedFileTypes.join(',')} />
          {isDragActive && (
            <Box
              display="flex"
              width="100%"
              height="100%"
              justifyContent="center"
              alignItems="center"
            >
              <Box component="span">{t('common.fileUploading.dropFile')}</Box>
            </Box>
          )}
          {!isDragActive && (
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              height="100%"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                compoonent="span"
                fontSize={16}
                color={theme.palette.custom.darkGreenColor}
              >
                {t('common.fileUploading.dragDrop')}
              </Box>
              <AmberLoadingButton
                component="span"
                className={classes.btnUpload}
                startIcon={<CloudUploadIcon />}
                loading={isLoading}
                disabled={isLoading}
                spinnerClass={classes.spinnerClass}
                text={
                  allowMultipleFiles
                    ? t('common.fileUploading.dragDropButtonFiles')
                    : t('common.fileUploading.dragDropButtonFile')
                }
              />
            </Box>
          )}
        </div>
      </Box>
      {attachment.length !== 0 && (
        <Box display="flex" flexDirection="column" maxHeight={200}>
          <Box
            component="span"
            display="block"
            fontSize={14}
            fontWeight={600}
            marginTop={2}
          >
            {allowMultipleFiles
              ? t('common.fileUploading.dragDropSelectedFiles')
              : t('common.fileUploading.dragDropSelectedFile')}
          </Box>
          {attachment.map((file, index) => (
            <UploadedFile
              key={generateKey(index)}
              file={file}
              fileRemovalHandler={handleChange}
            />
          ))}
        </Box>
      )}
    </>
  );
};

FileInput.propTypes = {
  handleFileChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  allowMultipleFiles: PropTypes.bool,
  attachment: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  supportedFileTypes: PropTypes.arrayOf(PropTypes.string),
};
FileInput.defaultProps = {
  isLoading: false,
  allowMultipleFiles: false,
  attachment: [],
  supportedFileTypes: ['.srt'],
};

export default FileInput;
