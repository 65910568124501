import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Button,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AssignDialogManager from './assignTranscriberToManagerDialog';
import { useSelectMultiple } from '../../utils/useSelectHooks';
import Loading from '../common/loading';

import '../job/JobsTable.scss';

const useTableStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  tableWarapper: {
    overflowX: 'scroll',
  },
  button: {
    margin: 8,
  },
}));

const pageSizes = [25, 100, 500];

const headRows = [
  { id: 'username', enabledSorting: true },
  { id: 'fullname', enabledSorting: true },
  { id: 'assigned', enabledSorting: true },
  { id: 'actions', enabledSorting: false },
];

function TranscriberTable(props) {
  const classes = useTableStyles();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('userName');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizes[0]);
  const [filters, setFilters] = useState({});
  const [assignManagerData, setAssignManagerData] = useState({
    open: false,
    transcriberUsername: null,
  });
  const {
    transcribers,
    handleAssignTranscriberToManager,
    filterOptions,
    handleRemoveTranscriberFromManager,
    refreshTable,
  } = props;
  const [t] = useTranslation();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  function onRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleChangePage(event, page) {
    setPage(page);
  }

  function handleChangeRowsPerPage(event) {
    setPageSize(event.target.value);
  }

  function loadTranscriberList() {
    props.loadTranscriberList({
      ...filters,
      page: page,
      pageSize: pageSize,
      sortProperty: orderBy,
      sortDirection: order,
    });
  }

  useEffect(() => {
    loadTranscriberList();
  }, [order, orderBy, page, pageSize, filters, refreshTable]);

  useEffect(() => {
    props.loadFiltersOptions();
  }, []);

  return (
    <Grid container spacing={0} className={classes.root}>
      <AssignDialogManager
        open={assignManagerData.open}
        managersList={filterOptions.data.perfectJobManagers}
        transcriberUsername={assignManagerData.transcriberUsername}
        handleAssign={(transcriberUsername, selectedManager) =>
          handleAssignTranscriberToManager(transcriberUsername, selectedManager)
        }
        handleClose={() =>
          setAssignManagerData({ open: false, transcriberUsername: null })
        }
      />
      <Grid item xs={12} sm={12} className={classes.tableWarapper}>
        <Table style={{ tableLayout: 'auto' }} size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              {headRows.map(
                (row) => {
                  if (row.enabledSorting) {
                    return (
                      <TableCell
                        key={row.id}
                        sortDirection={orderBy === row.id ? order : false}
                      >
                        <Tooltip title="Sort" enterDelay={300}>
                          <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                          >
                            {t(`managerDashboard.table.headers.${row.id}`)}
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell key={row.id}>
                      {t(`managerDashboard.table.headers.${row.id}`)}
                    </TableCell>
                  );
                },

                this,
              )}
            </TableRow>
          </TableHead>
          {transcribers.isLoading && <Loading thickness={5} size={40} />}
          {transcribers.loaded && (
            <TableBody>
              {transcribers.data.content.map((transcriber) => (
                <TableRow key={transcriber.userName} hover tabIndex={-1}>
                  <TableCell>{transcriber.userName}</TableCell>
                  <TableCell>{transcriber.fullName}</TableCell>
                  <TableCell>{transcriber.perfectJobManager || 'No'}</TableCell>
                  <TableCell>
                    {transcriber.perfectJobManager && (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={() =>
                            setAssignManagerData({
                              open: true,
                              transcriberUsername: transcriber.userName,
                            })
                          }
                        >
                          {t('common.button.reassign')}
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.button}
                          onClick={() =>
                            handleRemoveTranscriberFromManager(
                              transcriber.userName,
                              transcriber.perfectJobManager,
                            )
                          }
                        >
                          {t('common.button.remove')}
                        </Button>
                      </>
                    )}
                    {!!transcriber.perfectJobManager && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() =>
                          setAssignManagerData({
                            open: true,
                            transcriberUsername: transcriber.userName,
                          })
                        }
                      >
                        {t('common.button.assign')}
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>

        <TablePagination
          rowsPerPageOptions={pageSizes}
          component="div"
          count={transcribers.data.totalElements}
          rowsPerPage={pageSize}
          page={page}
          backIconButtonProps={{
            'aria-label': t('common.table.prevPage'),
          }}
          nextIconButtonProps={{
            'aria-label': t('common.table.nextPage'),
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
}

export default TranscriberTable;
