import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  DialogContent,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Trans, useTranslation } from 'react-i18next';
import { AccessAlarm } from '@material-ui/icons';
import AmberOutlinedButton from '../../buttons/AmberOutlinedButton';
import AmberPrimaryButton from '../../buttons/AmberPrimaryButton';
import AmberDialog from '../dialogs/AmberDialog';
import AmberDialogActions from '../dialogs/AmberDialogActions';
import { AMBER_PRIMARY } from '../../../constants/colours';
import { DONT_SHOW_EXPORT_DIALOG } from '../../../store/constants';

const useStyles = makeStyles(() => ({
  actionItems: {
    justifyContent: 'space-between',
  },
}));

function CutOffDialog(props) {
  const [t] = useTranslation();
  const { open, setOpenCutOffDialog, acceptJob, jobForCutOffDialog } = props;
  const classes = useStyles();
  const [checkboxValue, setCheckboxValue] = useState(false);

  const onCheckboxChange = () => {
    setCheckboxValue((prev) => !prev);
  };

  function setDontShowDialogLocalStorage() {
    localStorage.setItem(DONT_SHOW_EXPORT_DIALOG, checkboxValue);
  }

  function closeDialog() {
    setOpenCutOffDialog(false);
  }

  const handleAcceptJobClick = () => {
    setDontShowDialogLocalStorage();
    acceptJob(jobForCutOffDialog);
    closeDialog();
  };

  return (
    <AmberDialog
      open={open}
      title={t('cutOffDialog.title')}
      fullWidth
      handleClose={closeDialog}
      maxWidth="sm"
    >
      <DialogContent>
        <Box display="flex">
          <Box width="20%">
            {checkboxValue}
            <AccessAlarm
              style={{ height: 70, width: 70, color: AMBER_PRIMARY }}
            />
          </Box>
          <Box width="80%" alignSelf="center">
            <Trans i18nKey="cutOffDialog.content" />
          </Box>
        </Box>
      </DialogContent>
      <AmberDialogActions classes={{ root: classes.actionItems }}>
        <Box>
          <FormControlLabel
            control={
              <Checkbox onChange={onCheckboxChange} checked={checkboxValue} />
            }
            label={t('cutOffDialog.dontShow')}
          />
        </Box>
        <Box>
          <AmberOutlinedButton
            text={t('common.button.close')}
            onClick={closeDialog}
          />
          <AmberPrimaryButton
            text={t('cutOffDialog.accept')}
            onClick={handleAcceptJobClick}
          />
        </Box>
      </AmberDialogActions>
    </AmberDialog>
  );
}
CutOffDialog.propTypes = {
  setOpenCutOffDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  job: PropTypes.shape({ status: PropTypes.string }).isRequired,
  acceptJob: PropTypes.func.isRequired,
};

export default CutOffDialog;
