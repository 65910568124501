import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AmberCard from './AmberCard';
import AmberSectionTitleNoMargin from '../labels/AmberSectionTitleNoMargin';

const useStyles = makeStyles((theme) => ({
  noJobFound: {
    textAlign: 'center',
  },
  noJobFoundCardPaper: {
    width: '100%',
    padding: 0,
    transition: 'color 500ms, border 500ms ease-out',
    border: `1px solid ${theme.palette.custom.basicLightGray}`,
    color: theme.palette.custom.infoMessage,
    paddingTop: '1em',
    paddingBottom: '1em',
    marginTop: '15%',
  },
  noJobFoundCardContents: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
}));

function AmberNoItemFoundCard(props) {
  const { text } = props;
  const classes = useStyles();
  const [t] = useTranslation();
  return (
    <AmberCard
      classes={{ root: classes.noJobFoundCardPaper }}
      contentClasses={{ root: classes.noJobFoundCardContents }}
    >
      <AmberSectionTitleNoMargin
        text={text || t('common.error.noItemFoundGeneric')}
        typographyClasses={{ root: classes.noJobFound }}
        margin={0}
      />
    </AmberCard>
  );
}
AmberNoItemFoundCard.propTypes = {
  text: PropTypes.string,
};

AmberNoItemFoundCard.defaultProps = {
  text: null,
};
export default AmberNoItemFoundCard;
