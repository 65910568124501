import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 0,
    transition: 'color 500ms, border 500ms ease-out',
    border: `1px solid ${theme.palette.custom.basicLightGray}`,
    color: theme.palette.custom.infoMessage,

    '&:hover': {
      border: `1px solid ${theme.palette.primary.black}`,
      color: theme.palette.custom.charcoalGray,
    },
  },
}));
function AmberClickableCard(props) {
  const { children, onClick, id, classes, ...rest } = props;
  const defaultClasses = useStyles();

  return (
    <Card
      classes={classes ? { ...classes } : { root: defaultClasses.root }}
      {...rest}
    >
      <CardActionArea id={id} onClick={(event) => onClick(id, event)}>
        <CardContent>{children}</CardContent>
      </CardActionArea>
    </Card>
  );
}
AmberClickableCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.object),
};
AmberClickableCard.defaultProps = {
  classes: null,
};

export default AmberClickableCard;
