export const SRT_OPTION = 'SRT';
export const SRT_SOURCE_OPTION = 'SRT_SOURCE';
export const TXT_OPTION = 'TXT';
export const JSON_OPTION = 'JSON';
export const LQ_VIDEO_OPTION = 'LQ_VIDEO';
export const HQ_VIDEO_OPTION = 'HQ_VIDEO';
export const VTT_OPTION = 'VTT';
export const EBU_STL_OPTION = 'STL';
export const DOC_OPTION = 'DOCX';
export const RTF_OPTION = 'RTF';
