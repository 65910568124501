import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import AmberOutlinedButton from '../../components/buttons/AmberOutlinedButton';

const useStyles = makeStyles((theme) => ({
  buttonRed: {
    color: theme.palette.custom.pinkishRed,
    border: `2px solid ${theme.palette.custom.pinkishRed}`,
    '&:hover': {
      border: `1px solid ${theme.palette.custom.pinkishRed}`,
      backgroundColor: 'rgba(50, 150, 120, 0.04)',
    },
  },
}));

function FileReportAction(props) {
  const { handleOnClickReport } = props;
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    handleOnClickReport && (
      <AmberOutlinedButton
        text={t('qualityControlDashboard.reportIssue')}
        onClick={handleOnClickReport}
        classes={{ root: classes.buttonRed }}
      />
    )
  );
}

FileReportAction.propTypes = {
  handleOnClickReport: PropTypes.func,
};

FileReportAction.defaultProps = {
  handleOnClickReport: null,
};

export default FileReportAction;
