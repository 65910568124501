import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import PriorityHighSharpIcon from '@material-ui/icons/PriorityHighSharp';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import AmberSectionTitle from '../../../labels/AmberSectionTitle';

import {
  PERFECT_DONE,
  PERFECT_REVIEW_NOT_PASSED,
} from '../../../../constants/jobStatuses';

const useStyles = makeStyles((theme) => ({
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.1em',

    margin: 0,
  },
  iconClass: {
    color: theme.palette.custom.purplishGray,
    padding: '0.2em',
    fontSize: 'larger',
    borderRadius: '10em',
    background: theme.palette.custom.lightGray,
  },
  iconClassError: {
    color: theme.palette.custom.purplishGray,
    padding: '0.2em',
    marginBottom: '0.2em',
    fontSize: 'larger',
    background: theme.palette.custom.lightGray,
  },
}));
function JobCompletedStatus(props) {
  const { status } = props;
  const [t] = useTranslation();
  const classes = useStyles();
  switch (status) {
    case PERFECT_DONE:
      return (
        <div className={classes.statusContainer}>
          <AmberSectionTitle
            typographyVariant="body1"
            text={t('qualityControlDashboard.completed')}
            marginBottom={0}
            paddingRight="0.5em"
          />
          <DoneAllIcon classes={{ root: classes.iconClass }} />
        </div>
      );
    case PERFECT_REVIEW_NOT_PASSED:
      return (
        <div className={classes.statusContainer}>
          <AmberSectionTitle
            typographyVariant="body1"
            paddingRight="0.5em"
            paddingLeft="1em"
            text={t('qualityControlDashboard.rejected')}
            marginBottom={0}
          />
          <PriorityHighSharpIcon classes={{ root: classes.iconClass }} />
        </div>
      );

    default:
      return null;
  }
}
JobCompletedStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default JobCompletedStatus;
