const CURRENCIES = {
  eur: '€',
  usd: '$',
  gbp: '£',
  cny: '¥',
  jpy: '¥',
  sek: 'kr',
  rub: '₽',
  bgn: 'лв',
};

export { CURRENCIES as default };
