import React from 'react';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';

function AmberSimpleTooltip(props) {
  const { children, interactive, placement, enterDelay, leaveDelay, ...rest } =
    props;
  return (
    <Tooltip
      interactive={interactive}
      placement={placement}
      enterDelay={enterDelay}
      leaveDelay={leaveDelay}
      {...rest}
    >
      {children}
    </Tooltip>
  );
}

AmberSimpleTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  interactive: PropTypes.bool,
  placement: PropTypes.string,
  enterDelay: PropTypes.number,
  leaveDelay: PropTypes.number,
};
AmberSimpleTooltip.defaultProps = {
  interactive: true,
  placement: 'bottom-start',
  enterDelay: 500,
  leaveDelay: 200,
};
export default AmberSimpleTooltip;
