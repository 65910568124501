import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import AmberCard from '../../cards/AmberCard';
import AmberSectionTitle from '../../labels/AmberSectionTitle';
import {
  getFormattedDate,
  getFormattedSeconds,
  isDateValid,
} from '../../../utils/timeUtils';
import generateKey from '../../../utils/generateReactKey';
import FieldValue from '../fields/fieldValue';
import { ADMIN, TRANSCRIBER } from '../../../roles';
import { currentUserLoginDetailsAtom } from '../../../store/user';
import CURRENCIES from '../../../constants/currencies';

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    background: theme.palette.custom.whiteTwo,
    borderRadius: '1em',
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 32%',
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardTitle: {
    color: theme.palette.custom.charcoalGray,
    marginBottom: 0,
  },
  cardDates: {
    color: theme.palette.custom.purplishGray,
    fontWeight: 'bolder',
    marginBottom: 0,
  },
  textDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    marginTop: '1em',
  },
}));
const isObject = (obj) =>
  obj !== undefined && obj !== null && obj.constructor === Object;

const parseObject = (object) => {
  const keys = Object.keys(object);
  return keys.map((key) => ({ value: object[key].toString(), key }));
};

const parseInput = (key, value) => {
  if (isObject(value)) {
    return parseObject(value);
  }
  if (isDateValid(value)) {
    return [{ value: getFormattedDate(value), key }];
  }
  if (typeof value === 'boolean') {
    return [{ value: value.toString(), key }];
  }
  return [{ value, key }];
};

function DeadlineContainer(props) {
  const [t] = useTranslation();
  const { fieldName, deadline } = props;
  const classes = useStyles();
  return (
    <AmberCard classes={{ root: classes.cardRoot }}>
      <AmberSectionTitle
        typographyVariant="caption"
        text={t(`common.jobFields.${fieldName}`)}
        classes={{ root: classes.cardTitle }}
      />
      <AmberSectionTitle
        typographyVariant="body1"
        text={getFormattedDate(deadline || 'invalid', 'DD MMMM YYYY')}
        classes={{ root: classes.cardDates }}
      />
      {deadline && (
        <AmberSectionTitle
          typographyVariant="body1"
          text={getFormattedDate(deadline || 'invalid', 'HH:mm')}
          classes={{ root: classes.cardDates }}
        />
      )}
    </AmberCard>
  );
}

DeadlineContainer.propTypes = {
  fieldName: PropTypes.string.isRequired,
  deadline: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DeadlineContainer.defaultProps = {
  deadline: null,
};

function DetailsTabPanelContents(props) {
  const classes = useStyles();
  const [t] = useTranslation();

  const {
    jobDetails: {
      _id,
      jobId,
      pricing,
      transcriptionType,
      qualityControlDeadline,
      qualityControllerDeadline,
      jobNotes,
      nrAudioSeconds,
      jobIssueComments,
      language,
      transcriberDeadline,
      created,
      numberOfSpeakers,
      workflowStyle,
      rates,
      priority,
      notificationCounterForAssignedTranscriber,
      qualityCheckRejectionCount,
      ...rest
    },
  } = props;
  const restOfFields = Object.keys(rest);

  const roles = useRecoilValue(currentUserLoginDetailsAtom)?.role;

  function getRoleRate() {
    const rate = rates?.find((x) => x.userRole === TRANSCRIBER);

    return rate;
  }

  function getRateLabel() {
    const rate = getRoleRate();
    if (rate) {
      return `${CURRENCIES[rate.currency]}${rate.amountCentsPerMinute / 100} / ${t('common.jobFields.minute')} • ${CURRENCIES[rate.currency]}${rate.amountCents / 100} ${t('common.jobFields.total')}`;
    }

    return '-';
  }

  return (
    <div className={classes.detailsContainer}>
      <div className={classes.cardsContainer}>
        <DeadlineContainer fieldName="created" deadline={created} />
        <DeadlineContainer
          fieldName="transcriberDeadline"
          deadline={transcriberDeadline}
        />
        {/** backend is all over the place with the naming convention.... */}
        <DeadlineContainer
          fieldName="qualityControlDeadline"
          deadline={qualityControllerDeadline || qualityControlDeadline}
        />
      </div>
      <div className={classes.textDetailsContainer}>
        <FieldValue
          fieldName={t('common.jobFields.workflowStyle')}
          value={
            workflowStyle
              ? t(`qualityControlDashboard.${workflowStyle}`)
              : t('qualityControlDashboard.unspecifiedField')
          }
        />
        <FieldValue
          fieldName={t('common.jobFields.transcriptionType')}
          value={t(`qualityControlDashboard.${transcriptionType}`)}
        />
        <FieldValue
          fieldName={t('common.jobFields.language')}
          value={language.toUpperCase()}
        />
        <FieldValue
          fieldName={t('common.jobFields.numberOfSpeakers')}
          value={numberOfSpeakers}
        />
        <FieldValue
          fieldName={t('common.jobFields.nrAudioSeconds')}
          value={getFormattedSeconds(nrAudioSeconds)}
        />
        {(roles.includes(TRANSCRIBER) || roles.includes(ADMIN)) && (
          <FieldValue
            fieldName={t('common.jobFields.rate')}
            value={getRateLabel()}
          />
        )}
        <FieldValue fieldName={t('common.jobFields.jobId')} value={_id} />
        {restOfFields.map((itemKey) => {
          const values = parseInput(itemKey, rest[itemKey]);
          return values.map((currentValue) => {
            const newValue =
              currentValue.value ||
              t('qualityControlDashboard.unspecifiedField');
            return (
              <FieldValue
                key={generateKey(currentValue.key)}
                fieldName={t(`common.jobFields.${currentValue.key}`)}
                value={newValue}
              />
            );
          });
        })}
        <FieldValue
          fieldName={t(
            'common.jobFields.notificationCounterForAssignedTranscriber',
          )}
          value={notificationCounterForAssignedTranscriber}
        />

        <FieldValue
          fieldName={t('common.jobFields.priority')}
          value={priority}
        />

        <FieldValue
          fieldName={t('common.jobFields.qualityCheckRejectionCount')}
          value={qualityCheckRejectionCount}
        />
      </div>
    </div>
  );
}

DetailsTabPanelContents.propTypes = {
  jobDetails: PropTypes.shape({
    _id: PropTypes.string,
    jobId: PropTypes.string,
    transcriptionType: PropTypes.string,
    qualityControlDeadline: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    qualityControllerDeadline: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    jobNotes: PropTypes.string,
    nrAudioSeconds: PropTypes.number,
    language: PropTypes.string,
    transcriberDeadline: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    pricing: PropTypes.objectOf(PropTypes.object),
    numberOfSpeakers: PropTypes.number,
    workflowStyle: PropTypes.string,
    created: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    jobOptions: PropTypes.shape({
      transcriptionStyle: PropTypes.string,
    }),
    rates: PropTypes.arrayOf(
      PropTypes.shape({
        userRole: PropTypes.string,
        amountCents: PropTypes.number,
        amountCentsPerMinute: PropTypes.number,
        currency: PropTypes.string,
      }),
    ),
    priority: PropTypes.number.isRequired,
    notificationCounterForAssignedTranscriber: PropTypes.number.isRequired,
    qualityCheckRejectionCount: PropTypes.number
  }).isRequired,
};

export default DetailsTabPanelContents;
