import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { getTimeFromSec } from '../../helpers/timeUtils';
import generateKey from '../../utils/generateReactKey';

function getWordsText(words) {
  const segmentText = words
    .reduce((result, word) => {
      const { text } = word;
      if (text.length > 0 && text !== ' ') {
        result.push(text);
      }
      return result;
    }, [])
    .join(' ');
  return segmentText;
}

function getSegmentStartTime(words) {
  if (words && words.length > 0) {
    return words[0].start;
  }
  return null;
}

function getSegmentEndTime(words) {
  if (words && words.length > 0) {
    return words[words.length - 1].end;
  }
  return null;
}

const useStyles = makeStyles((theme) => ({
  transcriptTime: {
    color: theme.palette.primary[400],
    fontSize: 12,
    paddingBottom: theme.spacing(2),
  },
}));

export default function Transcript(props) {
  const { data } = props;
  const classes = useStyles();

  return (
    <Grid container>
      {data.map((t, index) => {
        const words = getWordsText(t.words);
        const start = getTimeFromSec(getSegmentStartTime(t.words));
        const end = getTimeFromSec(getSegmentEndTime(t.words));
        return (
          <React.Fragment key={generateKey(index)}>
            <Grid item xs={3}>
              {t.speaker}
            </Grid>
            <Grid item xs={9}>
              <div>
                <span className={classes.transcriptTime}>
                  {start}-{end}
                </span>
              </div>
              {words}
            </Grid>
          </React.Fragment>
        );
      })}
    </Grid>
  );
}

Transcript.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      words: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          start: PropTypes.number,
          end: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
};
