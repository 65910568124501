import { Button, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import InviteDialog from './inviteDialog';
import AssignDialog from './assignDialog';
import AssignDialogManagers from './assignDialogManagers';
import ChangeDatesDialog from './changeDates';
import { ADMIN } from '../../roles';
import {
  PERFECT_IN_REVIEW,
  PERFECT_REPORTED,
  PERFECT_TO_BE_REVIEWED,
} from '../../constants/jobStatuses';
import {
  checkIfBtnAssignManagerCanBeEnabled,
  checkIfBtnAssignTranscribersCanBeEnabled,
  checkIfBtnChangeDatesCanBeEnabled,
  checkIfBtnInviteTranscribersCanBeEnabled,
} from './helpers';
import { getRoles } from '../../utils/auth';
import Job from '../../utils/types/Job';
import CancelMultipleJobDialog from './cancelMultipleJobDialog';
import ResetMultipleJobDialog from './resetMultipleJobDialog';

const useToolbarStyles = makeStyles(() => ({
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    flex: '0 0 auto',
    alignSelf: 'self-start',
    marginTop: 10,
  },
  title: {
    flex: '0 0 auto',
    alignSelf: 'self-start',
    marginTop: 10,
  },
}));

export default function JobsToolbar(props) {
  const classes = useToolbarStyles();
  const [openInviteDalog, setOpenInviteDialog] = React.useState(false);
  const [openAssignDalog, setOpenAssignDialog] = React.useState(false);
  const [
    openQualityControllerAssignDialog,
    setOpenQualityControllerAssignDialog,
  ] = React.useState(false);
  const [openAssignDialogManagers, setOpenAssignDialogManagers] =
    React.useState(false);
    const [cancelDialogManagers, setCancelDialogManagers] =
    React.useState(false);
    const [resetDialogManagers, setResetDialogManagers] =
    React.useState(false);
  const {
    managersList,
    transcribersOptions,
    qualityControllerOptions,
    handleAssignQualityController,
    handleInvite,
    handleAssign,
    handleAssignManagers,
    selectedJobIds,
    handleDeadlineChange,
    selectedJobs,
    closeDialog,
    dialogEditOptions,
    openChangeDatesDialog,
    handleCancelJob,
    handleReopenJobs,
    handleSetSkipQualityControl
  } = props;

  const [t] = useTranslation();
  const role = getRoles();

  function handleOpenInviteDialog() {
    setOpenInviteDialog(true);
  }

  function handleOpenQualityControllerAssignmentDialog() {
    setOpenQualityControllerAssignDialog(true);
  }

  function handleCloseQualityControllerAssignmentDialog() {
    setOpenQualityControllerAssignDialog(false);
  }

  function handleCloseInviteDialog() {
    setOpenInviteDialog(false);
  }

  // Assign dialog for transcribers
  function handleOpenAssignDialog() {
    setOpenAssignDialog(true);
  }

  function handleCloseAssignDialog() {
    setOpenAssignDialog(false);
  }

  return (
    <>
      <Toolbar>
        <Typography variant="h6" id="tableTitle">
          {t('managerDashboard.toolbar.title')}
        </Typography>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          {role.includes(ADMIN) && (
            <Button
              onClick={() => setCancelDialogManagers(true)}
              disabled={!selectedJobIds.length}
              color="primary"
            >
              {t('managerDashboard.toolbar.cancelFiles')}
            </Button>
          )}

          {role.includes(ADMIN) && (
            <Button
              onClick={() => setResetDialogManagers(true)}
              disabled={!selectedJobIds.length}
              color="primary"
            >
              {t('managerDashboard.toolbar.resetFiles')}
            </Button>
          )}
          {/* Change dates */}
          {role.includes(ADMIN) && (
            <Button
              onClick={openChangeDatesDialog}
              disabled={
                !checkIfBtnChangeDatesCanBeEnabled(selectedJobIds, selectedJobs)
              }
              color="primary"
            >
              {t('managerDashboard.toolbar.changeDeadline')}
            </Button>
          )}
          {/* Assign managers */}
          {role.includes(ADMIN) && (
            <Button
              onClick={() => setOpenAssignDialogManagers(true)}
              disabled={
                !checkIfBtnAssignManagerCanBeEnabled(
                  selectedJobIds,
                  selectedJobs,
                )
              }
              color="primary"
            >
              {t('managerDashboard.toolbar.assignButtonManagers')}
            </Button>
          )}
          {/* Assign transcribers */}
          <Button
            onClick={handleOpenAssignDialog}
            disabled={
              !checkIfBtnAssignTranscribersCanBeEnabled(
                selectedJobIds,
                selectedJobs,
              )
            }
            color="primary"
          >
            {t('managerDashboard.toolbar.assignButton')}
          </Button>
          {/* Invite transcribers */}
          <Button
            onClick={handleOpenInviteDialog}
            disabled={
              !checkIfBtnInviteTranscribersCanBeEnabled(
                selectedJobIds,
                selectedJobs,
              )
            }
            color="primary"
          >
            {t('managerDashboard.toolbar.inviteButton')}
          </Button>
          {/* Assign QC */}
          <Button
            onClick={handleOpenQualityControllerAssignmentDialog}
            disabled={!selectedJobIds.length}
            color="primary"
          >
            {t('managerDashboard.toolbar.inviteQCButton')}
          </Button>
        </div>
      </Toolbar>
      <ChangeDatesDialog
        open={dialogEditOptions.changeDates}
        handleClose={closeDialog}
        selectedJobIds={selectedJobIds}
        handleDeadlineChange={handleDeadlineChange}
      />
      <InviteDialog
        open={openInviteDalog}
        handleClose={handleCloseInviteDialog}
        handleInvite={handleInvite}
        transcribersOptions={transcribersOptions}
        selectedjobIds={selectedJobIds}
      />
      <AssignDialog
        open={openQualityControllerAssignDialog}
        handleClose={handleCloseQualityControllerAssignmentDialog}
        transcribersOptions={qualityControllerOptions || []}
        handleAssign={handleAssignQualityController}
        selectedjobIds={selectedJobIds}
        isQC={true}
        handleSetSkipQualityControl={handleSetSkipQualityControl}
        selectPlaceholder={t(
          'managerDashboard.toolbar.qualityControllerSelectorPlaceholder',
        )}
        dialogTitle={t(
          'managerDashboard.toolbar.qualityControllerAssignmentTitle',
        )}
        selectorHelper={t('managerDashboard.toolbar.qualityController')}
      />
      <AssignDialog
        open={openAssignDalog}
        handleClose={handleCloseAssignDialog}
        handleAssign={handleAssign}
        transcribersOptions={transcribersOptions}
        selectedjobIds={selectedJobIds}
      />
      {role.includes(ADMIN) && (
        <AssignDialogManagers
          open={openAssignDialogManagers}
          handleClose={() => setOpenAssignDialogManagers(false)}
          handleAssign={handleAssignManagers}
          managersList={managersList}
          selectedjobIds={selectedJobIds}
        />
      )}

      {role.includes(ADMIN) && (
        <CancelMultipleJobDialog
          open={cancelDialogManagers}
          handleClose={() => setCancelDialogManagers(false)}
          handleCancelJob={handleCancelJob}
          selectedjobIds={selectedJobIds}
        />
      )}
      {role.includes(ADMIN) && (
        <ResetMultipleJobDialog
          open={resetDialogManagers}
          handleClose={() => setResetDialogManagers(false)}
          handleReopenJobs={handleReopenJobs}
          selectedjobIds={selectedJobIds}
        />
      )}
    </>
  );
}

JobsToolbar.propTypes = {
  selectedJobIds: PropTypes.arrayOf(PropTypes.string),
  managersList: PropTypes.arrayOf(PropTypes.string),
  transcribersOptions: PropTypes.arrayOf(PropTypes.string),
  qualityControllerOptions: PropTypes.arrayOf(PropTypes.string),
  handleInvite: PropTypes.func.isRequired,
  handleAssign: PropTypes.func.isRequired,
  handleAssignQualityController: PropTypes.func.isRequired,
  handleAssignManagers: PropTypes.func.isRequired,
  selectedJobs: PropTypes.arrayOf(Job),
  handleDeadlineChange: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  dialogEditOptions: PropTypes.shape({
    deadline: PropTypes.string,
    jobId: PropTypes.string,
    filename: PropTypes.string,
    deadlineRole: PropTypes.string,
    changeDates: PropTypes.bool,
  }),
  openChangeDatesDialog: PropTypes.func.isRequired,
};

JobsToolbar.defaultProps = {
  selectedJobIds: [],
  qualityControllerOptions: [],
  managersList: [],
  transcribersOptions: [],
  selectedJobs: [],
  dialogEditOptions: {
    deadline: null,
    jobId: null,
    filename: null,
    deadlineRole: null,
    changeDates: null,
  },
};
