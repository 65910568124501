import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { Edit, Add, Search, Language } from '@material-ui/icons';

export const EditIcon = Edit;

export const AddIcon = Add;

export const PlayIcon = (props) => (
  <SvgIcon {...props} nativeColor="#fff">
    <path d="M8 5v14l11-7z" />
  </SvgIcon>
);

export const PauseIcon = (props) => (
  <SvgIcon {...props} nativeColor="#fff">
    <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
  </SvgIcon>
);

export const ReplaceIcon = Search;

export const UndoIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M11.997 5.414 11.997 1.725 6.728 6.993 11.997 12.262 11.997 7.518C15.475 7.518 18.32 10.364 18.32 13.841 18.32 17.32 15.475 20.165 11.997 20.165 8.525 20.165 5.68 17.32 5.68 13.841L3.569 13.841C3.569 18.477 7.362 22.275 11.997 22.275 16.638 22.275 20.431 18.477 20.431 13.841 20.431 9.206 16.638 5.414 11.997 5.414"></path>
  </SvgIcon>
);

export const RedoIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M12.003 5.414 12.003 1.725 17.272 6.993 12.003 12.262 12.003 7.518C8.525 7.518 5.68 10.364 5.68 13.841 5.68 17.32 8.525 20.165 12.003 20.165 15.475 20.165 18.32 17.32 18.32 13.841L20.431 13.841C20.431 18.477 16.638 22.275 12.003 22.275 7.362 22.275 3.569 18.477 3.569 13.841 3.569 9.206 7.362 5.414 12.003 5.414"></path>
  </SvgIcon>
);

export const HighlightIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M16.109 2.077 3.433 14.752 3.433 19.087 3.447 19.101 2.078 20.47 3.53 21.923 4.888 20.566 9.247 20.566 21.922 7.891 16.109 2.077ZM14.006 7.086 16.109 4.984 19.015 7.891 16.913 9.993 14.006 7.086ZM5.488 18.244 5.488 15.605 12.554 8.538 15.461 11.445 8.395 18.512 5.724 18.512 5.488 18.244Z"></path>
  </SvgIcon>
);

export const LangIcon = Language;

export const AvatarIcon = (props) => (
  // this does not work
  <SvgIcon {...props}>
    <defs>
      <path id="a" d="M15 12H0V0h29v12z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 22)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          fill="#FFF"
          d="M27 3l-2-1-3-2H9L6 2 3 3C1 4 0 5 0 7v5h29V7l-2-4"
          mask="url(#b)"
        />
      </g>
      <path fill="#C8CDD0" d="M18 22l-5-1-3 5 5 8 5-8-2-4" />
      <path fill="#2F424F" d="M17 26h-3l-1 4 2 4 3-4-1-4" />
      <path fill="#2F424F" d="M17 24l-1-3h-1l-2 3v2l1 1h3v-3" />
      <path fill="#E6E9EA" d="M11 26l4-3 5 3 2-4v-3H9v3l2 4" />
      <path
        fill="#E5C3B3"
        d="M24 13c0 6-5 11-9 11-3 0-8-5-8-11s4-10 8-10c5 0 9 4 9 10"
      />
      <path
        fill="#2F424F"
        d="M10 3c1-2 6-4 11 0 4 2 3 9 3 9s-5 1-10-2c-4-2-5 0-6 2 0 2-2 1-2 1S5 7 10 3"
      />
      <path
        fill="#FFF"
        d="M15 34L9 23l-2 2 2 1v2l6 6m0 0l7-11 1 2-2 1 1 2-7 6"
      />
    </g>
  </SvgIcon>
);

export const SavedIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M0 0h24v24H0z" fill="none"></path>
    <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path>
  </SvgIcon>
);
