import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import CustomSelection from '../common/fields/selection';
import { QUALITY_CHECKER, TRANSCRIBER } from '../../roles';
import { getGMTDate } from '../../helpers/timeUtils';

const availableDateTypes = [
  'Transcriber deadline',
  'Client deadline',
  'QC deadline',
];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  detailLabel: {
    display: 'flex',
    alignItems: 'center',
    height: 32,
    color: theme.palette.primary[400],
    fontSize: 14,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

export default function ChangeDatesDialog(props) {
  const { open, handleClose, selectedJobIds, handleDeadlineChange } = props;
  const [deadlineModified, setDeadlineModified] = useState(false);
  const [notifyClient, setNotifyClient] = useState(false);
  const [t] = useTranslation();
  const classes = useStyles();
  const [dateType, setDateType] = useState('');
  const [newDeadline, setNewDeadline] = useState('');
  const [isDisabled, setIsDisabled] = useState('');

  function getNewDateInCorrectFormat() {
    const newDate = new Date(newDeadline);
    return getGMTDate(newDate);
  }

  function getDateType() {
    if (dateType === availableDateTypes[0]) {
      return TRANSCRIBER;
    }
    if (dateType === availableDateTypes[2]) {
      return QUALITY_CHECKER;
    }
    return 'CLIENT_DEADLINE';
  }

  function resetInputsToDefault() {
    setNewDeadline('');
    setDateType('');
    setNotifyClient(false);
    setDeadlineModified(false);
  }

  function handleChangeDates() {
    resetInputsToDefault();

    handleDeadlineChange(
      selectedJobIds,
      getNewDateInCorrectFormat(),
      getDateType(),
      notifyClient
    );
  }

  function changeNewDeadline(event) {
    const { value } = event.target;
    setNewDeadline(value);
    setDeadlineModified(true);
  }

  function closeDialog() {
    resetInputsToDefault();
    handleClose();
  }

  useEffect(() => {
    if (!dateType || !newDeadline) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [dateType, newDeadline]);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="invite-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="invite-dialog-title">Change dates</DialogTitle>
      <DialogContent>
        <div className={classes.root}>
          <Grid container>
            <Grid item xs={4} className={classes.detailLabel}>
              Date type
            </Grid>
            <Grid item xs={8}>
              {/* Select the type of the date to modify */}
              <CustomSelection
                id="changeDateSelection"
                value={dateType}
                data={availableDateTypes}
                onChange={(e) => setDateType(e.target.value)}
                fullWidth
              />
            </Grid>

            {/* Select a new date */}
            <Grid item xs={4} className={classes.detailLabel}>
              {`${dateType} Date`}
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="datetime-local"
                type="datetime-local"
                defaultValue={newDeadline}
                onChange={changeNewDeadline}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {/* only if client */}
            {dateType === 'Client deadline' && deadlineModified ? (
              <>
                <Grid item xs={4} className={classes.detailLabel}>
                  {t('common.changeDeadline.notify_client')}
                </Grid>
                <Grid item xs={8}>
                  <Checkbox
                    onChange={() => setNotifyClient(!notifyClient)}
                    checked={notifyClient}
                  />
                </Grid>
              </>
            ) : (
              ''
            )}
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="secondary">
          {t('common.button.cancel')}
        </Button>
        <Button
          onClick={handleChangeDates}
          color="primary"
          disabled={isDisabled}
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ChangeDatesDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleDeadlineChange: PropTypes.func.isRequired,
  selectedJobIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ChangeDatesDialog.defaultProps = {
  open: false,
};
