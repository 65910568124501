import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import generateKey from '../../../utils/generateReactKey';

const ITEM_HEIGHT = 48;

export default function DropDown(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const {
    options,
    iconClass,
    dropdownClass,
    iconText,
    itemClass,
    iconComponent,
  } = props;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [t, i18n] = useTranslation();

  return (
    <div className={dropdownClass}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        className={iconClass}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <span>{iconText}</span>

        {iconComponent || <ExpandMoreIcon />}
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'fit-content',
          },
        }}
      >
        {options?.map((option, index) => (
          <MenuItem
            key={generateKey(index, index)}
            disableGutters
            className={itemClass}
            onClick={handleClose}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
