import React from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import { currentUserLanguageAtom } from '../../../store/user';
import {
  getFormattedSeconds,
  getCountDown,
  getFormattedDate,
  getTimeDifferenceInHours,
} from '../../../utils/timeUtils';
import DoubleRowInfo from './doubleRowInfo';
import AmberSectionTitle from '../../labels/AmberSectionTitle';
import CURRENCIES from '../../../constants/currencies';
import { QUALITY_CHECKER } from '../../../roles';

const getMediaFileMetadataInfo = (t, nrAudioSeconds, numberOfSpeakers) => {
  const seconds = getFormattedSeconds(nrAudioSeconds);
  const speakers = `${numberOfSpeakers} ${t(
    'qualityControlDashboard.speakerLabel'
  )}`;
  return `${seconds} • ${speakers}`;
};

const getJobMetaDataInfo = (t, jobOptions, workflowStyle, revisions) => {
  const transcriptionStyleText = t(
    `qualityControlDashboard.${
      jobOptions ? jobOptions.transcriptionStyle : 'unspecifiedField'
    }`
  );

  const workflowStyleText = workflowStyle
    ? t(`qualityControlDashboard.${workflowStyle}`)
    : t('qualityControlDashboard.unspecifiedField');

  const revisionsText = revisions > 0
    ? `${t('qualityControlDashboard.revision')} ${revisions}`
    : undefined;

  return `${transcriptionStyleText} • ${workflowStyleText}${revisionsText ? ` • ${revisionsText}` : ''}`;
};

const useStyles = makeStyles((theme) => ({
  languageSection: {
    fontWeight: 'bolder',
    color: theme.palette.custom.charcoalGray,
    padding: '1.1em',
    margin: 0,
    alignSelf: 'center',
  },
}));

function JobTextVisuals(props) {
  const currentUserSelectedLocale = useRecoilValue(currentUserLanguageAtom);
  const [t] = useTranslation();
  const classes = useStyles();
  const {
    job: {
      displayFileName,
      transcriptionType,
      qualityControlDeadline,
      nrAudioSeconds,
      language,
      numberOfSpeakers,
      workflowStyle,
      jobOptions,
      targetLanguage,
      rates,
      qualityCheckRejectionCount
    },
    isCompleted,
  } = props;
  const getTargetLanguageText = () => {
    if (!language) {
      return `${t(
        'qualityControlDashboard.unspecifiedField',
      )} → ${targetLanguage.toUpperCase()}`;
    }
    return `${language.toUpperCase()} → ${targetLanguage.toUpperCase()}`;
  };
  const rate = rates?.find((x) => x.userRole === QUALITY_CHECKER) || null;

  return (
    <>
      <DoubleRowInfo
        highlight={displayFileName}
        useTooltip
        metaInfo={getMediaFileMetadataInfo(t, nrAudioSeconds, numberOfSpeakers)}
        useHighlightBold
        data-testid="job-item-display-file-name-formatted-seconds"
      />
      <DoubleRowInfo
        highlight={t(`qualityControlDashboard.${transcriptionType}`)}
        metaInfo={getJobMetaDataInfo(t, jobOptions, workflowStyle, qualityCheckRejectionCount)}
        data-testid="job-item-transcription-type-transcription-style-wf-style"
      />
      {!targetLanguage && (
        <AmberSectionTitle
          text={
            language
              ? language.toUpperCase()
              : t('qualityControlDashboard.unspecifiedField')
          }
          classes={{ root: classes.languageSection }}
          data-testid="job-item-language-section"
        />
      )}
      {targetLanguage && (
        <AmberSectionTitle
          text={getTargetLanguageText()}
          classes={{ root: classes.languageSection }}
          data-testid="job-item-language-section"
        />
      )}
      {!isCompleted && (
        <DoubleRowInfo
          highlight={getCountDown(
            qualityControlDeadline,
            currentUserSelectedLocale,
          )}
          metaInfo={getFormattedDate(qualityControlDeadline)}
          isUrgent={getTimeDifferenceInHours(qualityControlDeadline) <= 24}
          data-testid="job-item-count-down-due-date"
        />
      )}
      {isCompleted && (
        <AmberSectionTitle
          text={getFormattedDate(qualityControlDeadline, 'DD-MM-YYYY')}
          classes={{ root: classes.languageSection }}
        />
      )}
      {rate && (
        <DoubleRowInfo
          highlight={`${CURRENCIES[rate.currency]}${rate.amountCentsPerMinute / 100
            } / ${t('common.jobFields.minute')}`}
          metaInfo={`${CURRENCIES[rate.currency]}${rate.amountCents / 100} ${t(
            'common.jobFields.total',
          )}`}
          data-testid="job-item-display-rate"
        />)}
    </>
  );
}
JobTextVisuals.propTypes = {
  job: PropTypes.shape({
    _id: PropTypes.string,
    displayFileName: PropTypes.string,
    targetLanguage: PropTypes.string,
    transcriptionType: PropTypes.string,
    qualityControlDeadline: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    nrAudioSeconds: PropTypes.number,
    language: PropTypes.string,
    numberOfSpeakers: PropTypes.number,
    workflowStyle: PropTypes.string,
    jobOptions: PropTypes.shape({
      transcriptionStyle: PropTypes.string,
    }),
    qualityCheckRejectionCount: PropTypes.number,
  }).isRequired,
  isCompleted: PropTypes.bool,
  rates: PropTypes.object,
};
JobTextVisuals.defaultProps = {
  isCompleted: false,
};
export default JobTextVisuals;
