import { ReactComponent as AnonymizationIcon } from '../assets/images/anonymization.svg';
import { ReactComponent as RushIcon } from '../assets/images/rush.svg';
import { ReactComponent as PremiumIcon } from '../assets/images/premium.svg';

export function getAnonymizationTag(jobOptions) {
  if (jobOptions.anonymization !== 'NONE') {
    return {
      icon: AnonymizationIcon,
      tooltip: 'transcriberDashBoard.anonymization',
    };
  }
  return undefined;
}

export function getRushTag(jobOptions) {
  if (jobOptions.turnaroundTime === 'ONE_DAY') {
    return { icon: RushIcon, tooltip: 'transcriberDashBoard.rush' };
  }
  return undefined;
}

export function getPremiumTag(jobToDisplay) {
  if (jobToDisplay.workflowStyle === 'PREMIUM') {
    return { icon: PremiumIcon, tooltip: 'transcriberDashBoard.premium' };
  }
  return undefined;
}

export function getTagsForJob(jobToDisplay) {
  const tags = [];

  tags.push(getAnonymizationTag(jobToDisplay?.jobOptions));
  tags.push(getRushTag(jobToDisplay?.jobOptions));
  tags.push(getPremiumTag(jobToDisplay));

  return tags.filter((jobTag) => !!jobTag);
}
