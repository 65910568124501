import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import AmberSectionTitle from '../../labels/AmberSectionTitle';

const useStyles = makeStyles((theme) => ({
  fieldValueContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  fieldName: {
    color: theme.palette.custom.charcoalGray,
    flex: '0 0 50%',
  },
  fieldValue: {
    color: theme.palette.custom.charcoalGray,
    fontWeight: 900,
    fontStyle: 'normal',
  },
}));

function FieldValue(props) {
  const { fieldName, value, fieldClasses, containerClassNames, valueClasses } =
    props;
  const classes = useStyles();
  return (
    <div className={containerClassNames || classes.fieldValueContainer}>
      <AmberSectionTitle
        typographyClasses={
          fieldClasses ? { ...fieldClasses } : { root: classes.fieldName }
        }
        text={fieldName}
        typographyVariant="body2"
      />
      <div />
      <AmberSectionTitle
        classes={
          valueClasses ? { ...valueClasses } : { root: classes.fieldValue }
        }
        typographyVariant="body2"
        text={value}
      />
    </div>
  );
}
FieldValue.propTypes = {
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fieldClasses: PropTypes.objectOf(PropTypes.string),
  containerClassNames: PropTypes.string,
  valueClasses: PropTypes.objectOf(PropTypes.string),
};

FieldValue.defaultProps = {
  fieldClasses: null,
  containerClassNames: null,
  value: '',
  valueClasses: null,
};
export default FieldValue;
