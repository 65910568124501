import React from 'react';
import { makeStyles, ThemeProvider } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { styled, createTheme } from '@mui/material/styles';
import { Box, Select, MenuItem, Pagination } from '@mui/material';
import AmberText from '../../typography/AmberText';
import AmberTextBig from '../../typography/AmberTextBig';
import useMediaQueries from "../../../helpers/customHooks/mediaQueries";

const theme = createTheme();

// Styles
const PaginationBox = styled('div')(
    ({ theme }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F5F5F5;
    padding-top: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(2)};
    font-size: 0.9rem;
  `,
);

const NoBorderSelect = styled(Select)(
    ({ theme }) => `
    color: ${theme.palette.primary.main};
    font-weight: 600;

    svg {
        color: ${theme.palette.primary.main};
    }

    fieldset {
        border: none !important;
    }
  `,
);

const useStyles = makeStyles((theme) => ({
  root: {
    '& ul > li > button:not(.Mui-disabled)': {
      borderWidth: 2,
      borderColor: theme.palette.primary.main,
    },
    '& ul > li > button:not(.Mui-disabled) > svg': {
      fill: theme.palette.primary.main,
    },
    '& ul > li > button.Mui-selected': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
    // Selected page
    '& ul > li > button.Mui-selected:hover': {
      backgroundColor: '#005A50',
      borderColor: '#005A50',
    },
    '& ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected)':
        {
          backgroundColor: 'transparent',
          color: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
    // Other pages
    '& ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected):hover':
        {
          backgroundColor: '#DBF1EB',
        },
    '& ul > li > button.Mui-disabled': {
      borderWidth: 2,
    },
    // Left arrow
    '& ul > li:first-child > button:not(.Mui-selected):hover': {
      backgroundColor: '#DBF1EB',
    },
    // Right arrow
    '& ul > li:last-child > button:not(.Mui-selected):hover': {
      backgroundColor: '#DBF1EB',
    }
  },
}));

// Pagination
function AmberPaginationPerPageBox(props) {
  const [t] = useTranslation();
  const { pageSize, rowsPerPageOptions, handleChangeRowsPerPage } = props;

  return (
      <Box
          display={{ xs: 'none', sm: 'flex' }}
          justifyContent="flex-end"
          alignItems="center"
          flex={1}
          marginRight={8}
      >
          <AmberTextBig text={rowsPerPageOptions[0]} />
        <Box marginLeft={1}>
          <AmberText text={t('PAGINATION.labelRowsPerPage')} />
        </Box>
      </Box>
  );
}

AmberPaginationPerPageBox.propTypes = {
  pageSize: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
};

// Main component
export default function AmberPaginationMain(props) {
  const [isSmallScreen] = useMediaQueries();
  const {
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPageOptions,
    pageSize,
    isEmpty,
    totalPages,
  } = props;

  const classes = useStyles();

  if (isEmpty) {
    return null;
  }

  return (
      <ThemeProvider theme={theme}>
        <PaginationBox>
          <Box display={{ xs: 'none', sm: 'none', md: 'flex' }} flex={1} />

          {/* NAGIVATIONS THROUGH THE TABLE */}
          {totalPages > 1 && (
              <Box display="flex" justifyContent="center" flex={3}>
                <Pagination
                    className={classes.root}
                    count={totalPages}
                    variant="outlined"
                    shape="rounded"
                    onChange={(event, number) => handleChangePage(number)}
                    size={isSmallScreen ? 'small' : 'large'}
                    color="primary"
                />
              </Box>
          )}
        </PaginationBox>
      </ThemeProvider>
  );
}

AmberPaginationMain.propTypes = {
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  pageSize: PropTypes.number.isRequired,
  totalPages: PropTypes.number,
  isEmpty: PropTypes.bool,
};

AmberPaginationMain.defaultProps = {
  totalPages: 1,
  isEmpty: false,
};