import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Divider } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import AmberSectionTitle from '../../labels/AmberSectionTitle';
import AmberIconButton from '../../buttons/AmberIconButton';

const useStyles = makeStyles(() => ({
  root: { display: 'flex', flexDirection: 'column' },
  buttonOpen: {
    transform: 'rotate(180deg)',
    padding: '0.5em',
    margin: 0,
    transition: 'transform 500ms',
  },
  buttonClosed: {
    transform: 'rotate(360deg)',
    transition: 'transform 500ms',
    margin: 0,
    padding: '0.5em',
  },
  openJobsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 1 90%',
  },
  collapsibleControl: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  collapsibleWarnButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  divider: {
    marginBottom: '1em',
  },
}));

function CollapseControl(props) {
  const {
    numberOfActiveJobs,
    handleCollapse,
    isCollapsed,
    collapseControlTitle,
  } = props;
  const [t] = useTranslation();
  const classes = useStyles();
  const buttonClass = clsx({
    [classes.buttonOpen]: isCollapsed,
    [classes.buttonClosed]: !isCollapsed,
  });
  return (
    <>
      <div className={classes.collapsibleControl}>
        <AmberSectionTitle
          text={collapseControlTitle}
          typographyVariant="h6"
          marginBottom={0}
        />
        <div className={classes.collapsibleWarnButton}>
          {!!numberOfActiveJobs && (
            <AmberSectionTitle
              text={t('qualityControlDashboard.activeWarnText')}
              typographyVariant="caption"
              marginBottom={0}
              paddingTop="0.5em"
            />
          )}
          <AmberIconButton
            icon={<KeyboardArrowDownIcon />}
            onClick={handleCollapse}
            classes={{ root: buttonClass }}
          />
        </div>
      </div>
      <Divider className={classes.divider} />
    </>
  );
}
CollapseControl.propTypes = {
  numberOfActiveJobs: PropTypes.number,
  handleCollapse: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool,
  collapseControlTitle: PropTypes.string,
};
CollapseControl.defaultProps = {
  isCollapsed: true,
  collapseControlTitle: '',
  numberOfActiveJobs: 0,
};

export default CollapseControl;
