import React from 'react';
import { styled, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { createTheme } from '@mui/material/styles';
import { v4 as uuidV4 } from 'uuid';
import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
  Skeleton,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import AmberEnhancedTableHead from './AmberEnhancedTableHead';
import AmberPaginationPerBox from './AmberPaginationMain';
import AmberLargeTextLabel from './TextFields/AmberLargeTextLabel';
import {TableBody} from "@material-ui/core";

// Var(s)
const PAGINATION_OPTIONS = [50];
const theme = createTheme();

const Root = styled('div')(
  ({ theme }) => `
  table {
    width: 100%;
  }

  td,
  th {
    text-align: left;
    padding: ${theme.spacing(3)};
    white-space: nowrap;
  }

  th {
    font-family: Geomanist-Bold;
    color: #6F7B72;
    background-color: #F5F5F5;
  }

  td {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .Mui-active {
    color: ${theme.palette.primary.main} !important;
  }

  .MuiTableSortLabel-root:hover {
    color: ${theme.palette.primary.main} !important;
  }

  .MuiTableSortLabel-icon{
    color: ${theme.palette.primary.main} !important;
  }

  .MuiTableRow-hover:hover{
      .hoverButton{
        visibility: visible!important;
      }
  }
  `,
);

function AmberStickySortableTable(props) {
  const {
    headCells,
    dataToDisplay,
    rowsPerPageOptions = PAGINATION_OPTIONS,
    isLoading,
    paginationOptions,
    updatePaginationOptions,
    canRowBeClicked,
    handleRowClicked,
    rowBackgroundColor,
    elevation = 1,
    noTopBorderRadius = false,
  } = props;

  const { page, pageSize, sortProperty, sortDirection } = paginationOptions;

  const [t] = useTranslation();

  const handleRequestSort = (event, property) => {
    const isAsc = sortProperty === property && sortDirection === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';

    updatePaginationOptions(page, pageSize, property, newOrder);
  };

  const handleChangePage = (newPage) => {
    const calcPage = newPage - 1;
    if (calcPage !== page) {
      updatePaginationOptions(calcPage, pageSize, sortProperty, sortDirection);
    }
  };

  const handleChangeRowsPerPage = (newPage) => {
    const newRowsPerPage = parseInt(newPage, 10);
    if (newRowsPerPage !== pageSize) {
      updatePaginationOptions(0, newRowsPerPage, sortProperty, sortDirection);
    }
  };

  function getUniqueKey(row) {
    // eslint-disable-next-line no-underscore-dangle
    return uuidV4();
  }

  function displayDataInTable() {
    if (dataToDisplay) {
      const dataSize = dataToDisplay.length;

      if (dataSize > 0) {
        return dataToDisplay.map((row, indexRow) => {
          const clickable = canRowBeClicked(row);

          const getCursor = () => (clickable ? 'pointer' : 'unset');

          const handleClick = () => clickable && handleRowClicked(row);

          const getColor = () => "red";

          /**
           * Returns the style with status color
           * for the left border if it's the first column (ONLY FOR KALTURA TABLE)
           * Returns normal style for other tables
           */
          const getCellStyle = (headCell, index) => {
            const color = getColor();

            const style = {
              width: {
                xs: headCell.width.xs,
                sm: headCell.width.normal,
              },
              display: {
                xs: headCell.displayInXS ? 'table-cell' : 'none',
                sm: 'table-cell',
              },
            };
            return style;
          };

          return (
            <TableRow
              data-testid={`table-row-${indexRow + 1}`}
              key={getUniqueKey(row)}
              hover
              tabIndex={-1}
              sx={{
                height: 60,
                cursor: getCursor(),
              }}
              onClick={handleClick}
            >
              {headCells.map((headCell, index) => (
                <TableCell
                  key={`${getUniqueKey(row)}-${headCell.key}`}
                  align="left"
                  sx={getCellStyle(headCell, index)}
                >
                  {headCell.displayer(row, index)}
                </TableCell>
              ))}
            </TableRow>
          );
        });
      }

      return (
        <TableRow tabIndex={-1} sx={{ height: 60 }}>
          <TableCell>
            <Box display="flex" height={60} paddingLeft={2} alignItems="center">
              <AmberLargeTextLabel text={t('transcriberDashBoard.noResultsFound')} />
            </Box>
          </TableCell>
        </TableRow>
      );
    }
    return null;
  }

  function displayLoadingDataInTable(numberOfElements) {
    const list = [];
    const tableCells = [];

    for (let index = 0; index < headCells.length; index += 1) {
      const headCell = headCells[index];

      tableCells.push(
        <TableCell
          key={`tc-${index}`}
          align="left"
          sx={{
            width: { xs: headCell.width.xs, sm: headCell.width.normal },
            display: {
              sm: 'table-cell',
            },
          }}
        >
          <Skeleton variant="text" />
        </TableCell>,
      );
    }

    for (let index = 0; index < numberOfElements; index += 1) {
      list.push(
        <TableRow key={`tr-${index}`} hover tabIndex={-1} sx={{ height: 60 }}>
          {tableCells}
        </TableRow>,
      );
    }

    return list;
  }

  return (
    <Root sx={{ maxWidth: '100%' }}>
      <Paper
        elevation={elevation}
        sx={
          noTopBorderRadius
            ? { width: '100%', overflow: 'hidden', borderRadius: '0 0 4px 4px' }
            : { width: '100%', overflow: 'hidden', borderRadius: '4px' }
        }
      >
        <TableContainer
          sx={{ minHeight: 110, maxHeight: 'calc(100vh - 290px)' }}
        >
          <Table stickyHeader aria-labelledby="tableTitle" size="medium">
            <AmberEnhancedTableHead
              sortDirection={sortDirection}
              sortProperty={sortProperty}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />

            <TableBody>
              {/* Loaded and not empty */}
              {!isLoading && displayDataInTable()}

              {/* Loading with content */}
              {isLoading &&
                displayLoadingDataInTable(
                  dataToDisplay.numberOfElements || pageSize,
                )}
            </TableBody>
          </Table>
        </TableContainer>

        <AmberPaginationPerBox
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          totalElements={dataToDisplay.totalElements}
          isFirst={dataToDisplay.first}
          isLast={dataToDisplay.last}
          isEmpty={dataToDisplay.empty}
          totalPages={dataToDisplay.totalPages}
          rowsPerPageOptions={rowsPerPageOptions}
          page={page}
          pageSize={pageSize}
        />
      </Paper>
    </Root>
  );
}

AmberStickySortableTable.propTypes = {
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      width: PropTypes.shape({
        xs: PropTypes.string,
        normal: PropTypes.string,
      }).isRequired,
      sortable: PropTypes.bool,
      displayer: PropTypes.func,
    }).isRequired,
  ).isRequired,
  dataToDisplay: PropTypes.arrayOf(PropTypes.shape({
    totalElements: PropTypes.number,
    numberOfElements: PropTypes.number,
    totalPages: PropTypes.number,
    first: PropTypes.bool,
    last: PropTypes.bool,
    empty: PropTypes.bool,
  })).isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  isLoading: PropTypes.bool,
  paginationOptions: PropTypes.shape({
    page: PropTypes.number,
    pageSize: PropTypes.number,
    sortDirection: PropTypes.string,
    sortProperty: PropTypes.string,
  }).isRequired,
  updatePaginationOptions: PropTypes.func,
  canRowBeClicked: PropTypes.func,
  handleRowClicked: PropTypes.func,
  rowBackgroundColor: PropTypes.func,
  elevation: PropTypes.number,
  noTopBorderRadius: PropTypes.bool,
};

AmberStickySortableTable.defaultProps = {
  rowsPerPageOptions: PAGINATION_OPTIONS,
  elevation: 1,
  noTopBorderRadius: false,
};

export default function ThemedAmberStickySortableTable(props) {
  return (
      <ThemeProvider theme={theme}>
        <AmberStickySortableTable {...props} />
      </ThemeProvider>
  );
}