import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, DialogContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useRecoilValueLoadable } from 'recoil';
import AmberOutlinedButton from '../../buttons/AmberOutlinedButton';
import AmberLoadingButton from '../../buttons/AmberLoadingButton';

import AmberDialog from '../dialogs/AmberDialog';
import AmberDialogActions from '../dialogs/AmberDialogActions';
import JobRatingContents from './jobRatingContents';
import {
  FILE_INPUT_ID,
  RATING_COMPONENT_ID,
  RATING_PERFECT_DONE_STATE,
  RATING_PERFECT_REVIEW_NOT_PASSED_STATE,
  RECOIL_LOADABLE_ERROR_STATE,
  RECOIL_LOADABLE_LOADING_STATE,
  TEXT_INPUT_ID,
  TRANSCRIPTION_TYPE_TRANSCRIPTION,
} from '../../../constants/constants';
import { getRatingDefaults } from '../../../constants/ratingCategories';
import { getExportOptionsSelectorFamily } from '../../../store/jobManagerDashboard';
import CircularIndeterminate from '../../loading/indeterminateSpinner';
import useSnackbar from '../../../utils/useSnackbar';
import {
  getFileTypesBasedOnAllowedUploads,
  getFormat,
} from '../../../utils/fileFormatUtils';
import { SRT_OPTION } from '../../../store/contractOptions';

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonReject: {
    color: theme.palette.custom.pinkishRed,
    border: `2px solid ${theme.palette.custom.pinkishRed}`,
    '&:hover': {
      border: `1px solid ${theme.palette.custom.pinkishRed}`,
      backgroundColor: 'rgba(50, 150, 120, 0.04)',
    },
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogPaper: {
    minHeight: '70%',
    maxHeight: '70%',
  },
  spinnerColour: {
    color: theme.palette.error.main,
  },
}));

const validateFiles = (files, editorEnabled) =>
  editorEnabled || !!(files && files.length);

const validateRatings = (ratings, worflowStyle, transcriptionType) => {
  if (!ratings) {
    return false;
  }
  const ratingDefaults = getRatingDefaults(
    null,
    worflowStyle,
    transcriptionType,
  );
  const ratingDefaultsKeys = Object.keys(ratingDefaults);
  return ratingDefaultsKeys.every(
    (key) => ratings[key] && ratings[key].selected,
  );
};

const validateNotes = (notes) => {
  if (!notes) {
    return false;
  }
  return notes.trim().length > 0;
};

const getProcessedRatings = (ratings) => {
  const ratingsKeys = Object.keys(ratings);
  return ratingsKeys.reduce((acc, key) => {
    acc[key] = ratings[key].selected;
    return acc;
  }, {});
};

const hasRequiredFiles = (
  transcriptionType,
  editorEnabled,
  files,
  allowedUploads,
) => {
  if (editorEnabled) {
    return true;
  }

  if (TRANSCRIPTION_TYPE_TRANSCRIPTION === transcriptionType) {
    return getFileTypesBasedOnAllowedUploads(allowedUploads)?.some(
      (allowedUpload) =>
        files?.some(
          (file) => getFormat(file) === allowedUpload.replace('.', ''),
        ),
    );
  }
  return files?.some((file) => SRT_OPTION.toLowerCase() === getFormat(file));
};

function JobRatingDialog(props) {
  const { open, handleClose, handleOnClickAction, loadingActionRequest, job } =
    props;
  const { workflowStyle, transcriptionType, _id } = job;

  const [t] = useTranslation();
  const classes = useStyles();
  const { showError } = useSnackbar();

  const [notes, setNotes] = useState(undefined);
  const [files, setFiles] = useState(undefined);
  const [ratings, setRatings] = useState(undefined);

  const [canSubmit, setCanSubmit] = useState(false);
  const [canReject, setCanReject] = useState(false);
  const [requiredFilesError, setRequiredFilesError] = useState(false);

  const exportOptions = useRecoilValueLoadable(
    getExportOptionsSelectorFamily(_id),
  );

  useEffect(() => {
    if (
      validateRatings(ratings, workflowStyle, transcriptionType) &&
      validateNotes(notes)
    ) {
      setCanReject(true);
      if (
        validateFiles(files, exportOptions?.contents?.editorEnabled) &&
        hasRequiredFiles(
          transcriptionType,
          exportOptions?.contents?.editorEnabled,
          files,
          exportOptions?.contents?.uploadOptions,
        )
      ) {
        setCanSubmit(true);
        setRequiredFilesError(false);
        return;
      }
      setRequiredFilesError(true);
      setCanSubmit(false);
      return;
    }
    setCanReject(false);
    setCanSubmit(false);
  }, [notes, files, ratings, _id]);

  const handleClickReject = useCallback(() => {
    const processedRatings = getProcessedRatings(ratings);
    const data = {
      ratings: processedRatings,
      notes,
    };
    handleOnClickAction(RATING_PERFECT_REVIEW_NOT_PASSED_STATE, data);
  });

  const handleClickAccept = useCallback(() => {
    const processedRatings = getProcessedRatings(ratings);
    const data = {
      ratings: processedRatings,
      notes,
      files,
    };
    handleOnClickAction(RATING_PERFECT_DONE_STATE, data);
  });

  const handleChange = useCallback((event) => {
    const { id, value } = event;
    switch (id) {
      case TEXT_INPUT_ID:
        setNotes(value);
        break;
      case RATING_COMPONENT_ID:
        setRatings({ ...value });
        break;
      case FILE_INPUT_ID:
        setFiles([...value]);
        break;
      default:
        break;
    }
  });

  // FIXME needs to USE HOC COMPONENT, this is duplicated code (DRY be damned)
  const isDataLoading = () =>
    exportOptions.state === RECOIL_LOADABLE_LOADING_STATE;

  const showDataError = () => {
    if (exportOptions.state === RECOIL_LOADABLE_ERROR_STATE) {
      showError(exportOptions.contents.response.data.message);
    }
  };

  useEffect(() => {
    showDataError();
  }, [exportOptions.state]);

  if (isDataLoading()) {
    return <CircularIndeterminate thickness={3.6} />;
  }

  return (
    <AmberDialog
      open={open}
      title={t('dialogJobFeedback.title')}
      fullWidth
      maxWidth="sm"
      handleClose={handleClose}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent>
        <JobRatingContents
          handleChange={handleChange}
          notes={notes}
          editorEnabled={exportOptions?.contents?.editorEnabled}
          uploadOptions={exportOptions?.contents?.uploadOptions}
          ratings={ratings}
          files={files}
          job={job}
        />
        {requiredFilesError &&
          (TRANSCRIPTION_TYPE_TRANSCRIPTION === transcriptionType ? (
            <Box mt={1} component="span" color="error.main">
              {t('dialogJobFeedback.missingSrtDocument')}
            </Box>
          ) : (
            <Box mt={1} component="span" color="error.main">
              {t('dialogJobFeedback.missingSrt')}
            </Box>
          ))}
      </DialogContent>
      <AmberDialogActions classes={{ root: classes.dialogActions }}>
        <AmberOutlinedButton
          text={t('common.button.close')}
          onClick={handleClose}
        />
        <div className={classes.actionButtons}>
          <AmberLoadingButton
            text={t('dialogJobFeedback.reject')}
            onClick={handleClickReject}
            classes={{ root: classes.buttonReject }}
            spinnerClass={classes.spinnerColour}
            disabled={!canReject || loadingActionRequest}
            loading={loadingActionRequest}
            variant="outlined"
          />
          <AmberLoadingButton
            text={t('dialogJobFeedback.approve')}
            onClick={handleClickAccept}
            loading={loadingActionRequest}
            disabled={!canSubmit || loadingActionRequest}
          />
        </div>
      </AmberDialogActions>
    </AmberDialog>
  );
}

JobRatingDialog.propTypes = {
  handleOnClickAction: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  job: PropTypes.objectOf(PropTypes.string).isRequired,
  loadingActionRequest: PropTypes.bool,
};

JobRatingDialog.defaultProps = {
  loadingActionRequest: false,
};

export default JobRatingDialog;
