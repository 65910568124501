import React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  getExportOptionsSelectorFamily,
  jobTranscriptSelectorFamily,
} from '../../store/jobManagerDashboard';
import useSnackbar from '../../utils/useSnackbar';
import {
  RECOIL_LOADABLE_ERROR_STATE,
  RECOIL_LOADABLE_LOADING_STATE,
} from '../../constants/constants';
import AmberTextButton from '../../components/buttons/AmberTextButton';
import generateKey from '../../utils/generateReactKey';
import DropDown from '../../components/common/dropdown/dropdown';

const useStyles = makeStyles((theme) => ({
  dropDown: {
    display: 'flex',
    padding: 0,
    fontSize: 'inherit',
  },
  iconClass: {
    flex: '1 1 auto',
    color: theme.palette.custom.darkGreenColor,
    padding: '6px 8px',
    fontSize: 'inherit',
    borderRadius: '10%',
  },
  itemClass: {
    paddingLeft: '5px',
  },
  exportTextButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    justifyContent: 'flex-start',
    padding: 0,
  },
}));

const ExportComponents = (props) => {
  const {
    job: { _id, recordId, sourceJobId },
    tabValue,
  } = props;
  const exportOptions = useRecoilValueLoadable(
    getExportOptionsSelectorFamily(_id),
  );
  const jobTranscript = useRecoilValueLoadable(
    jobTranscriptSelectorFamily({ jobId: _id, tabValue }),
  );
  const { showError } = useSnackbar();

  const [t] = useTranslation();
  const classes = useStyles();

  const getTranscript = () => {
    if (jobTranscript.state === RECOIL_LOADABLE_ERROR_STATE) {
      return null;
    }
    if (jobTranscript.state !== RECOIL_LOADABLE_LOADING_STATE) {
      return jobTranscript.contents;
    }
    return null;
  };

  const handleFunc = async (func, identifier, data) => {
    try {
      await func(identifier, data);
    } catch (error) {
      showError(error.message);
    }
  };

  const getExportIdentifier = (useSourceJobId, useRecordId) => {
    if (useSourceJobId) {
      return sourceJobId;
    }
    if (useRecordId) {
      return recordId;
    }
    return _id;
  };

  const mapDownloadComponents = (exportOptionsContents) =>
    exportOptionsContents?.map((exportFunc, index) => {
      const { func, buttonKey, useRecordId, useSourceJobId } = exportFunc;
      const transcript = getTranscript();
      const identifier = getExportIdentifier(useSourceJobId, useRecordId);
      if (!func) {
        return null;
      }
      return (
        <AmberTextButton
          fullWidth
          disableRipple
          classes={{
            root: classes.exportTextButton,
          }}
          onClick={() =>
            handleFunc(func, identifier, { transcript, targetJobId: _id })
          }
          text={t(`common.button.${buttonKey}`)}
          key={generateKey(index)}
        />
      );
    });

  const getDownloadComponents = () => {
    if (exportOptions.state === RECOIL_LOADABLE_ERROR_STATE) {
      return [];
    }
    if (exportOptions.state !== RECOIL_LOADABLE_LOADING_STATE) {
      return mapDownloadComponents(exportOptions.contents?.exportOptions);
    }
    return [];
  };

  if (!getDownloadComponents()?.length) {
    return null;
  }

  return (
    <DropDown
      dropdownClass={classes.dropDown}
      iconClass={classes.iconClass}
      itemClass={classes.itemClass}
      iconText={t('common.dropdown.export')}
      options={getDownloadComponents()}
    />
  );
};

ExportComponents.propTypes = {
  job: PropTypes.shape({
    _id: PropTypes.string,
    recordId: PropTypes.string,
    transcriptionType: PropTypes.string,
    sourceJobId: PropTypes.string,
  }),
  tabValue: PropTypes.number,
};
ExportComponents.defaultProps = {
  job: {},
  tabValue: 0,
};

export default ExportComponents;
