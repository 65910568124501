import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import { useRecoilValueLoadable } from 'recoil';
import PropTypes from 'prop-types';
import UploadConfirmationModal from './uploadConfirmationModal';

import { uploadCaptions, uploadDocument } from '../../utils/api';
import DragAndDropFileInput from '../upload_file_input/loadingDragDropInput';
import {
  FILE_INPUT_ID,
  RECOIL_LOADABLE_ERROR_STATE,
  RECOIL_LOADABLE_LOADING_STATE,
  TRANSCRIPTION_TYPE_TRANSCRIPTION,
} from '../../constants/constants';
import { getExportOptionsSelectorFamily } from '../../store/jobManagerDashboard';
import {
  DOC_OPTION,
  RTF_OPTION,
  SRT_OPTION,
} from '../../store/contractOptions';
import CircularIndeterminate from '../loading/indeterminateSpinner';
import useSnackbar from '../../utils/useSnackbar';
import AmberFormHelper from '../labels/AmberFormHelper';
import {
  getFileTypesBasedOnAllowedUploads,
  getFormat,
} from '../../utils/fileFormatUtils';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minWidth: 300,
    maxWidth: 500,
    minHeight: 400,
    padding: 16,
  },
  explanationContainer: {
    display: 'flex',
  },
  button: {
    margin: theme.spacing(1),
    textTransform: 'none',
  },
  infoColour: {
    color: theme.palette.custom.darkGreenColor,
  },
  label: {
    color: theme.palette.custom.charcoalGray,
  },

  margin: {
    margin: 4,
  },
}));

export default function UploadCaptions(props) {
  const classes = useStyles();
  const [t] = useTranslation();
  const {
    openUploadCaptions,
    setOpenUploadCaptions,
    jobDetails: { jobId, transcriptionType },
    submitJob,
  } = props;
  const { showError } = useSnackbar();
  const exportOptions = useRecoilValueLoadable(
    getExportOptionsSelectorFamily(jobId),
  );

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(true);

  const [systemBusy, setSystemBusy] = useState(false);

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const handleFormChange = (event) => {
    let target = event;
    if (event.target) {
      target = event.target;
    }
    const { value } = target;

    setSelectedFiles([...value]);
  };

  function handleClose() {
    setSelectedFiles([]);
    setBtnDisabled(true);
    setErrorMessage('');
    setSystemBusy(false);

    setOpenUploadCaptions(false);
  }

  function checkIfFilesAreSelectedCorrectly() {
    if (!selectedFiles.length) {
      setBtnDisabled(true);
      return;
    }
    // Check if SRT is uploaded
    const srtUploaded = selectedFiles.some(
      (file) => SRT_OPTION.toLowerCase() === getFormat(file),
    );

    const documentUploaded = selectedFiles.some(
      (file) =>
        DOC_OPTION.toLowerCase() === getFormat(file) ||
        RTF_OPTION.toLowerCase() === getFormat(file),
    );

    if (
      !srtUploaded &&
      TRANSCRIPTION_TYPE_TRANSCRIPTION !== transcriptionType
    ) {
      setErrorMessage(t('dialogJobFeedback.srtRequiredError'));
      setBtnDisabled(true);
      return;
    }

    if (
      !documentUploaded &&
      TRANSCRIPTION_TYPE_TRANSCRIPTION === transcriptionType
    ) {
      setErrorMessage(t('dialogJobFeedback.documentRequiredError'));
      setBtnDisabled(true);
      return;
    }

    setErrorMessage('');
    setBtnDisabled(false);
  }

  function startUploadingFiles() {
    setSystemBusy(true);

    Promise.all(
      selectedFiles.map((file) =>
        (TRANSCRIPTION_TYPE_TRANSCRIPTION === transcriptionType
          ? uploadDocument(jobId, file)
          : uploadCaptions(jobId, file, getFormat(file))),),
    )
      .then(() => {
        if (submitJob) {
          submitJob(jobId);
        }
      })
      .then(() => {
        handleClose();
      })
      .catch((err) => {
        showError(err?.message || t('common.error.unknownError'));
      })
      .finally(() => {
        setSystemBusy(false);
      });
  }

  useEffect(() => {
    checkIfFilesAreSelectedCorrectly();
  }, [selectedFiles]);

  // FIXME needs to USE HOC COMPONENT, this is duplicated code (DRY be damned)
  const showDataError = () => {
    if (exportOptions.state === RECOIL_LOADABLE_ERROR_STATE) {
      showError(exportOptions.contents.response.data.message);
    }
  };
  const isDataLoading = () =>
    exportOptions.state === RECOIL_LOADABLE_LOADING_STATE;

  useEffect(() => {
    showDataError();
  }, [exportOptions.state]);

  if (isDataLoading()) {
    return <CircularIndeterminate thickness={3.6} />;
  }

  return (
    <>
      <UploadConfirmationModal
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        onOkayClick={startUploadingFiles}
        title={
          TRANSCRIPTION_TYPE_TRANSCRIPTION === transcriptionType
            ? t('transcriberDashBoard.currentJobCard.documentTitleConfirmation')
            : t('transcriberDashBoard.currentJobCard.captionTitleConfirmation')
        }
        description={
          TRANSCRIPTION_TYPE_TRANSCRIPTION === transcriptionType
            ? t(
                'transcriberDashBoard.currentJobCard.documentDescriptionConfirmation',
              )
            : t(
                'transcriberDashBoard.currentJobCard.captionDescriptionConfirmation',
              )
        }
      />
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        open={openUploadCaptions}
        onClose={handleClose}
        maxWidth="md"
      >
        <DialogTitle>
          {TRANSCRIPTION_TYPE_TRANSCRIPTION === transcriptionType
            ? t('dialogJobFeedback.uploadFile')
            : t('dialogJobFeedback.uploadSrt')}
        </DialogTitle>

        <DialogContent>
          <>
            <div className={classes.explanationContainer}>
              <InfoIcon className={classes.infoColour} />
              <Box component="span" marginLeft={1} fontSize={14}>
                {t('transcriberDashBoard.uploadCaptions.pleaseUpload')}
                <Box
                  component="span"
                  display="block"
                  width="auto"
                  fontWeight={600}
                  marginTop={1}
                >
                  {`${t(
                    'transcriberDashBoard.uploadCaptions.availableFormats',
                  )} ${exportOptions?.contents?.uploadOptions.reduce(
                    (acc, val) => `${acc}${val}, `,
                    '',
                  )}`}
                </Box>
              </Box>
            </div>

            <AmberFormHelper
              text={
                TRANSCRIPTION_TYPE_TRANSCRIPTION === transcriptionType
                  ? t('dialogJobFeedback.uploadSrtHelperTranscript', { jobId })
                  : t('dialogJobFeedback.uploadSrtHelper', { jobId })
              }
            />
            <DragAndDropFileInput
              enforceNameMatch
              objectIdentifier={jobId}
              allowMultipleFiles
              id={FILE_INPUT_ID}
              handleSyncChange={handleFormChange}
              supportedFileTypes={getFileTypesBasedOnAllowedUploads(
                exportOptions?.contents?.uploadOptions,
              )}
            />
          </>
        </DialogContent>

        <DialogActions>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
          >
            <Button
              variant="contained"
              color="primary"
              component="span"
              className={classes.button}
              fullWidth
              disabled={btnDisabled || systemBusy}
              onClick={() => setOpenConfirmation(true)}
            >
              {t('transcriberDashBoard.uploadCaptions.action')}
            </Button>
            {btnDisabled && (
              <Box
                display="block"
                component="span"
                color="#e53935"
                fontSize={14}
              >
                {errorMessage}
              </Box>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
UploadCaptions.propTypes = {
  openUploadCaptions: PropTypes.bool,
  setOpenUploadCaptions: PropTypes.func.isRequired,
  jobDetails: PropTypes.objectOf(PropTypes.string).isRequired,
  submitJob: PropTypes.func.isRequired,
};

UploadCaptions.defaultProps = {
  openUploadCaptions: false,
};
