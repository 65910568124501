import { TRANSCRIBER, QUALITY_CHECKER } from '../../../roles';
const getRoles = (t) => [
  {
    role: TRANSCRIBER,
    deadlineFieldName: t('common.jobFields.transcriberDeadline'),
  },
  {
    role: QUALITY_CHECKER,
    deadlineFieldName: t('common.jobFields.qualityControlDeadline'),
  },
  { role: null, deadlineFieldName: t('common.jobFields.clientDeadline') },
];

export default getRoles;
