import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import intlConvertor from '../../../helpers/intlConvertor';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 200,
    maxWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SimpleSelect(props) {
  const { id, data, label, type, ...rest } = props;
  const classes = useStyles();
  const inputId = `${id}-select-input`;

  function onChange(e) {
    props.onChange(e);
  }

  function getFullNameOfLanguages(element) {
    return intlConvertor(element);
  }

  function sortedData() {
    if (type === 'lang') {
      const langWithLabels = data.map((d) => ({
        code: d,
        label: getFullNameOfLanguages(d),
      }));
      const sortedLangsWithLabels = langWithLabels.sort((a, b) =>
        (a.label > b.label ? 1 : -1),);
      const sortedLangs = sortedLangsWithLabels.map((l) => l.code);
      return sortedLangs;
    }
    return data;
  }

  return (
    <FormControl className={classes.formControl} {...rest}>
      <InputLabel shrink htmlFor={inputId}>
        {label}
      </InputLabel>
      <Select
        value={props.value}
        onChange={onChange}
        inputProps={{
          name: id,
          id: inputId,
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {sortedData().map((element) => (
          <MenuItem key={element.key || element} value={element.key || element}>
            {type === 'lang' ? getFullNameOfLanguages(element) : ( element.value || element)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

SimpleSelect.propTypes = {
  data: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
