import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  progress: {
    overflow: 'hidden',
    margin: 'auto',
    marginTop: theme.spacing(2),
    zIndex: 1000,
  },
}));

export default function CircularDeterminate(props) {
  const classes = useStyles();
  const { size, thickness } = props;

  return (
    <div className={classes.root}>
      <CircularProgress
        className={classes.progress}
        size={size}
        thickness={thickness}
      />
    </div>
  );
}

CircularDeterminate.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  thickness: PropTypes.number.isRequired,
};
CircularDeterminate.defaultProps = {
  size: 40,
};
