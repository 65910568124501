import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { makeStyles } from '@material-ui/core/styles';
import {
  RECOIL_LOADABLE_DONE_STATE,
  RECOIL_LOADABLE_LOADING_STATE,
} from './constants/constants';
import Header from './containers/HeaderContainer';
import {
  currentUserLoginStatusSelector,
  currentUserRoleAtom,
} from './store/user';
import CircularDeterminate from './components/common/loading';

const useStyles = makeStyles(() => ({
  circularIndeterminate: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: '-1.5em 0 0 -1.5em',
  },
}));

const isRoleCorrect = (pathRoles, userLoginRoles) =>
  userLoginRoles[0] === pathRoles[0];

const PrivateRoute = ({
  component: Component,
  roles,
  resetRecoilState,
  ...rest
}) => {
  const classes = useStyles();
  const isLoggedIn = useRecoilValueLoadable(currentUserLoginStatusSelector);
  const userRoles = useRecoilValue(currentUserRoleAtom);
  const getRenderedComponent = (props) => {
    if (
      isLoggedIn.state === RECOIL_LOADABLE_DONE_STATE &&
      isLoggedIn.contents &&
      isRoleCorrect(roles, userRoles)
    ) {
      return (
        <>
          <Header resetRecoilState={resetRecoilState} history={props.history} />
          <Component {...props} />
        </>
      );
    }
    if (isLoggedIn.state === RECOIL_LOADABLE_LOADING_STATE) {
      return (
        <div className={classes.circularIndeterminate}>
          <CircularDeterminate size="3.6rem" thickness={3.6} />
        </div>
      );
    }
    return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
  };

  return <Route {...rest} render={(props) => getRenderedComponent(props)} />;
};
PrivateRoute.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  history: PropTypes.objectOf(PropTypes.func),
  location: PropTypes.shape({ pathname: PropTypes.string }),
  component: PropTypes.func.isRequired,
  resetRecoilState: PropTypes.func.isRequired,
};
PrivateRoute.defaultProps = {
  history: null,
  location: null,
};
export default PrivateRoute;
