import React, { useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

function AmberSimpleTextField(props) {
  const {
    multiline,
    maxRows,
    variant,
    value,
    disabled,
    fullWidth,
    id,
    onChange,
    inputClass,
    ...rest
  } = props;
  const handleChange = useCallback((event) => {
    const { target } = event;
    onChange({ value: target.value, id });
  });

  return (
    <TextField
      multiline={multiline}
      maxRows={maxRows}
      variant={variant}
      value={value}
      disabled={disabled}
      fullWidth={fullWidth}
      onChange={handleChange}
      className={inputClass}
      {...rest}
    />
  );
}

AmberSimpleTextField.propTypes = {
  multiline: PropTypes.bool,
  maxRows: PropTypes.number,
  variant: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  // disabled fields don't need to have a change handler
  onChange: PropTypes.func,
  inputClass: PropTypes.shape(),
};

AmberSimpleTextField.defaultProps = {
  multiline: false,
  maxRows: 4,
  variant: 'outlined',
  value: '',
  disabled: false,
  fullWidth: false,
  id: null,
  onChange: null,
  inputClass: null,
};

export default AmberSimpleTextField;
