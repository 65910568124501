import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip, withStyles } from '@material-ui/core';
import { Typography } from '@mui/material';

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'white',
    color: theme.palette.custom.charcoalGray,
    maxWidth: theme.spacing(50),
    fontWeight: 400,
    lineHeight: theme.spacing(0.2),
    marginBottom: theme.spacing(1),
    border: `1px solid ${theme.palette.custom.lightGray}`,
    boxShadow: '0px 5px 25px rgba(0, 0, 0, 0.1)',
  },
  arrow: {
    '&:before': {
      border: `1px solid ${theme.palette.custom.lightGray}`,
    },
    color: theme.palette.common.white,
  },
}))(Tooltip);

export default function AmberTooltip(props) {
  const { children, tooltip, testid } = props;

  return (
    <CustomTooltip
      title={
        <Box padding={2}>
          <Typography fontSize="normal">{tooltip}</Typography>
        </Box>
      }
      placement="top"
      arrow
      data-testid={testid}
    >
      <span>{children}</span>
    </CustomTooltip>
  );
}

AmberTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.string.isRequired,
  testid: PropTypes.string,
};

AmberTooltip.defaultProps = {
  testid: '',
}
