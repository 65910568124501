import React from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

function AmberSectionTitle(props) {
  const { text, typographyVariant, typographyClasses, ...rest } = props;

  return (
    <Typography
      variant={typographyVariant || 'h6'}
      {...(typographyClasses ? { classes: { ...typographyClasses } } : {})}
    >
      <Box display="block" component="span" marginBottom={1} {...rest}>
        {text}
      </Box>
    </Typography>
  );
}
AmberSectionTitle.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  typographyVariant: PropTypes.string,
  typographyClasses: PropTypes.objectOf(PropTypes.string),
};
AmberSectionTitle.defaultProps = {
  typographyVariant: 'h6',
  typographyClasses: null,
};

export default AmberSectionTitle;
