// We badly need typescript.....

import PropTypes from 'prop-types';

const jobPropType = PropTypes.shape({
  jobId: PropTypes.string,
  _id: PropTypes.string,
  recordId: PropTypes.string,
  created: PropTypes.number,
  updated: PropTypes.number,
  language: PropTypes.string,
  submitted: PropTypes.bool,
  submittedDate: PropTypes.number,
  userRecordStatus: PropTypes.string,
  jobStatus: PropTypes.string,
  jobType: PropTypes.string,
  nrAudioSeconds: PropTypes.number,
  transcriptionType: PropTypes.string,
  accessKey: PropTypes.string,
  filename: PropTypes.string,
  numberOfSpeakers: PropTypes.number,
  transcriber: PropTypes.string,
  perfectJobManager: PropTypes.string,
  deadline: PropTypes.number,
  sourceJobId: PropTypes.string,
  targetLanguage: PropTypes.string,
  jobOptions: PropTypes.shape({
    transcriptionStyle: PropTypes.string,
    turnaroundTime: PropTypes.string,
    burnInSubtitles: PropTypes.bool,
    portal: PropTypes.bool,
    timestampEnabledInExport: PropTypes.bool,
  }),
  transcriberDeadline: PropTypes.number,
  notes: PropTypes.string,
  deliveryDate: PropTypes.number,
  errorMsg: PropTypes.string,
  workflowStatus: PropTypes.string,
  workflowStyle: PropTypes.string,
  displayFileName: PropTypes.string,
  qualityController: PropTypes.string,
  jobNotes: PropTypes.string,
  qualityControlDeadline: PropTypes.number,
  historyLog: PropTypes.arrayOf(
    PropTypes.shape({
      created: PropTypes.number,
      userName: PropTypes.string,
      type: PropTypes.string,
      message: PropTypes.string,
    }),
  ),
  jobIssueComments: PropTypes.arrayOf(
    PropTypes.shape({
      created: PropTypes.number,
      userName: PropTypes.string,
      type: PropTypes.string,
      message: PropTypes.string,
      issueType: PropTypes.string,
    }),
  ),
  glossaryId: PropTypes.string,
  burnInCaptions: PropTypes.bool,
  priority: PropTypes.number,
  glossaryLink: PropTypes.string,
  externalJobIdentifier: PropTypes.string,
  externalCustomerIdentifier: PropTypes.string,
  jobSource: PropTypes.string,
  invoiced: PropTypes.bool,
  client: PropTypes.string,
  invoiceItemId: PropTypes.string,
  notificationCounterForAssignedTranscriber: PropTypes.number,
  rates: PropTypes.arrayOf(
    PropTypes.shape({
      userRole: PropTypes.string,
      amountCents: PropTypes.number,
      amountCentsPerMinute: PropTypes.number,
      currency: PropTypes.string,
    }),
  ),
  manualInvoice: PropTypes.bool,
});
export default jobPropType;
