import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Comment, Edit, Person, Warning } from '@material-ui/icons';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import EditWorkflowStyleDialog from './editWorkflowStyleDialog';
import EditWorkflowStatusDialog from './editWorkflowStatusDialog';
import DeadlineDialog from '../common/deadline_dialog/deadlineDialog';
import ResetButton from './resetButton';
import CancelJobButton from './cancelJobButton';
import Filters from './jobFilters';
import JobsTableToolbar from './jobToolbar';
import Loading from '../common/loading';
import RemainingDeadline from '../common/remainingDeadline';
import { getDateTime, getDateTimeAmsterdam , toHHMMSS } from '../../helpers/timeUtils';
import {
  BUSY,
  DONE,
  ERROR,
  PERFECT_AWAITING_CONFIRMATION_TRANSCRIBER,
  PERFECT_CANCELLED,
  PERFECT_DONE,
  PERFECT_EDITING,
  PERFECT_IN_CHECKOUT,
  PERFECT_IN_QUEUE,
  PERFECT_IN_REVIEW,
  PERFECT_OPEN,
  PERFECT_PAYMENT_DECLINED,
  PERFECT_REPORTED,
  PERFECT_REVIEW_NOT_PASSED,
  PERFECT_TO_BE_REVIEWED,
} from '../../constants/jobStatuses';
import { TRANSCRIPTION } from '../../constants/transcriptionTypes';
import {
  ADMIN,
  PERFECT_JOB_MANAGER,
  QUALITY_CHECKER,
  TRANSCRIBER,
} from '../../roles';
import { TRANSCRIPTION_TYPE_TRANSLATED_SUBTITLES } from '../../constants/constants';
import intlConvertor from '../../helpers/intlConvertor';
import AmberTextButton from '../buttons/AmberTextButton';
import { getRoles } from '../../utils/auth';

import './JobsTable.scss';
import AssignDialog from './assignDialog';
import Job from '../../utils/types/Job';

const useTableStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  commentRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  selectionInfo: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 24,
    paddingRight: 24,
  },
  selectionInfoClear: {
    marginLeft: 16,
  },
  tableWarapper: {
    overflowX: 'scroll',
  },
  nameJobIdColumn: {
    display: 'block',
    padding: 0,
    color: theme.palette.primary.main,
    border: 0,
    maxWidth: '14em',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  notInvoiced: {
    fontSize: 20,
    fontWeight: 800,
  },
  resetBtn: {
    minWidth: 120,
  },
  deadlineDiv: {
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tDeadline: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  warningIcon: {
    color: '#e7a910',
    marginRight: 8,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  editIconRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  editTranscriberDeadlineIcon: {
    fontSize: 18,
    cursor: 'pointer',
    marginLeft: 4,
  },
}));

const headRowsAdmin = [
  { id: 'displayFileName', enabledSorting: false },
  { id: 'transcriptionType', enabledSorting: false },
  { id: 'transcriptionStyle', enabledSorting: false },
  { id: 'workflowStyle', enabledSorting: false },
  { id: 'turnaroundTime', enabledSorting: false },
  { id: 'created', enabledSorting: true },
  { id: 'updated', enabledSorting: false },
  { id: 'language', enabledSorting: true },
  { id: 'targetLanguage', enabledSorting: false },
  { id: 'nrAudioSeconds', enabledSorting: true },
  { id: 'transcriberDeadline', enabledSorting: true },
  { id: 'clientDeadline', enabledSorting: true },
  { id: 'qualityControlDeadline', enabledSorting: false },
  { id: 'priority', enabledSorting: true },
  { id: 'jobStatus', enabledSorting: false },
  { id: 'workflowStatus', enabledSorting: true },
  { id: 'transcriber', enabledSorting: true },
  { id: 'qualityController', enabledSorting: false },
  { id: 'externalCustomerIdentifier', enabledSorting: false },
  { id: 'client', enabledSorting: true },
  { id: 'numberOfSpeakers', enabledSorting: true },
  { id: 'invoiced', enabledSorting: true },
  { id: 'manualInvoice', enabledSorting: true },
  { id: 'perfectJobManager', enabledSorting: true },
  { id: 'actions', enabledSorting: false },
  { id: 'skipQualityControl', enabledSorting: false },
];

const headRowsManager = [
  { id: 'displayFileName', enabledSorting: false },
  { id: 'transcriptionType', enabledSorting: false },
  { id: 'workflowStyle', enabledSorting: true },
  { id: 'created', enabledSorting: true },
  { id: 'language', enabledSorting: true },
  { id: 'targetLanguage', enabledSorting: true },
  { id: 'nrAudioSeconds', enabledSorting: true },
  { id: 'transcriberDeadline', enabledSorting: true },
  { id: 'qualityControlDeadline', enabledSorting: false },
  { id: 'clientDeadline', enabledSorting: true },
  { id: 'jobStatus', enabledSorting: false },
  { id: 'workflowStatus', enabledSorting: true },
  { id: 'transcriber', enabledSorting: true },
  { id: 'qualityController', enabledSorting: false },
  { id: 'numberOfSpeakers', enabledSorting: true },
  { id: 'actions', enabledSorting: false },
  { id: 'skipQualityControl', enabledSorting: false },
];

const dialogDefaults = {
  deadline: null,
  jobId: null,
  filename: null,
  deadlineRole: null,
  changeDates: null,
};

function getHeadRowsByRole(role) {
  if (role.includes(ADMIN)) {
    return headRowsAdmin;
  }
  if (role.includes(PERFECT_JOB_MANAGER)) {
    return headRowsManager;
  }
  return null;
}

function JobsTable(props) {
  const classes = useTableStyles();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [filters, setFilters] = useState({});
  const [dialogEditOptions, setDialogOptions] = useState({
    ...dialogDefaults,
  });

  const [editWorkflowStyle, setEditWorkflowStyle] = useState({
    show: false,
    job: null,
  });
  const [editWorkflowStatus, setEditWorkflowStatus] = useState({
    show: false,
    job: null,
  });
  const [editQualityController, setOpenQualityController] = useState({
    show: false,
    qualityController: null,
    jobIds: [],
  });

  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const {
    jobs,
    filterOptions,
    refreshTable,
    handleSelectItem,
    selected,
    handleReopenJobs,
    handleCancelJob,
    selectedJobs,
    setSelectedJobs,
    handleTranscriberDeadline,
    handleSetSkipQualityControl,
    handleQualityControllerDeadline,
    handleClientDeadline,
    refreshJobTable,
    loadFiltersOptions,
    loadJobList: loadJobListProp,
    handleInvite,
    handleSetQualityController,
    handleAssign,
    handleAssignManagers,
    handleAssignQualityController,
    showDetails,
    onChangeFileNameFilter,
  } = props;
  const [t] = useTranslation();

  const role = getRoles();

  React.useEffect(() => {
    loadFiltersOptions();
  }, []);

  function onRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  function isSelected(jobId) {
    return selected.includes(jobId);
  }

  function handleChangePage(event, pageRequestVar) {
    setPage(pageRequestVar);
  }

  function handleChangeRowsPerPage(event) {
    setPageSize(event.target.value);
  }

  function loadJobList() {
    loadJobListProp({
      ...filters,
      page,
      pageSize,
      sortProperty: orderBy === 'clientDeadline' ? 'deadline' : orderBy,
      sortDirection: order,
    });
  }

  function onSearch(searchFilters) {
    setPage(0);
    setFilters(searchFilters);
  }

  useEffect(() => {
    if (isFilterApplied) {
      loadJobList();
    }
  }, [order, orderBy, page, pageSize, filters, refreshTable, isFilterApplied]);

  function getBackgroundColorByStatus(status, priority) {
    const TRANSPARENT = 'transparent';
    const GREEN_100 = '#c8e6c9';
    const BLUE_100 = '#bbdefb';
    const RED_100 = '#ffcdd2';
    const YELLOW_100 = '#fff9c4';
    const ORANGE_100 = '#ffc459';
    const GRAY = '#CCCCCC';

    if (status === DONE || status === PERFECT_DONE) {
      return GREEN_100;
    }
    if (
      status === ERROR ||
      status === PERFECT_PAYMENT_DECLINED ||
      status === PERFECT_CANCELLED
    ) {
      return RED_100;
    }
    if (status === PERFECT_OPEN && priority >= 10) {
      return GRAY;
    }
    if (status === PERFECT_OPEN) {
      return TRANSPARENT;
    }
    if (status === PERFECT_TO_BE_REVIEWED) {
      return BLUE_100;
    }
    if (status === PERFECT_IN_REVIEW) {
      return BLUE_100;
    }
    if (status === PERFECT_REPORTED) {
      return ORANGE_100;
    }
    return YELLOW_100;
  }

  function checkIfSelectable(job) {
    const { jobStatus } = job;
    switch (jobStatus) {
      case PERFECT_OPEN:
      case PERFECT_AWAITING_CONFIRMATION_TRANSCRIBER:
      case PERFECT_IN_QUEUE:
      case PERFECT_EDITING:
      case PERFECT_TO_BE_REVIEWED:
      case PERFECT_REVIEW_NOT_PASSED:
      case PERFECT_IN_REVIEW:
      case PERFECT_REPORTED:
      case BUSY:
        return true;
      default:
        return false;
    }
  }

  function addOrRemoveJobFromList(job) {
    const { jobId } = job;
    // Remove from the list
    if (selectedJobs.some((selectedJob) => selectedJob.jobId === jobId)) {
      const newList = selectedJobs.filter(
        (selectedJob) => selectedJob.jobId !== jobId,
      );

      setSelectedJobs(newList);
    } else {
      // Add to the list
      selectedJobs.push(job);
      setSelectedJobs(selectedJobs);
    }
  }

  function setSkipQC(jobId, currentSkipQCValue) {
    handleSetSkipQualityControl(jobId, currentSkipQCValue);
  }

  function filterJobs(jobsContent) {
    return jobsContent.filter((job) => job.jobStatus !== PERFECT_IN_CHECKOUT);
  }

  function shouldShowWarningIcon(job) {
    return moment(job.transcriberDeadline).isAfter(job.deadline);
  }

  function openWorkflowStatusEditDialog(job) {
    setEditWorkflowStatus({
      show: true,
      job,
    });
  }

  function openChangeDatesDialog() {
    setDialogOptions({
      ...dialogDefaults,
      changeDates: true,
    });
  }

  function closeEditQualityControllerDialog() {
    setOpenQualityController({
      show: false,
      qualityController: null,
      jobIds: [],
    });
  }

  function openWorkflowStyleEditDialog(job) {
    setEditWorkflowStyle({
      show: true,
      job,
    });
  }

  function handleDeadlineChange(jobIds, newDateUTC, deadlineRole, notify) {
    switch (deadlineRole) {
      case TRANSCRIBER:
        handleTranscriberDeadline(jobIds, newDateUTC);
        break;
      case QUALITY_CHECKER:
        handleQualityControllerDeadline(jobIds, newDateUTC);
        break;
      default:
        handleClientDeadline(jobIds, newDateUTC, notify);
        break;
    }
    setDialogOptions({ ...dialogDefaults });
  }

  function openDialog(job, deadlineRole) {
    const {
      deadline,
      transcriberDeadline,
      qualityControlDeadline,
      jobId,
      filename,
    } = job;

    setDialogOptions({
      show: true,
      clientDeadline: getDateTimeAmsterdam(deadline),
      transcriberDeadline: getDateTimeAmsterdam(transcriberDeadline),
      qualityControlDeadline: getDateTimeAmsterdam(qualityControlDeadline),
      jobId,
      filename,
      deadlineRole,
    });
  }

  function closeDialog() {
    setDialogOptions({
      ...dialogDefaults,
    });
  }

  function getHeadRowsByRoleMapper(row) {
    const { enabledSorting } = row;
    if (enabledSorting) {
      return (
        <TableCell
          key={row.id}
          sortDirection={orderBy === row.id ? order : false}
        >
          <Tooltip title="Sort" enterDelay={300}>
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {t(`managerDashboard.table.headers.${row.id}`)}
            </TableSortLabel>
          </Tooltip>
        </TableCell>
      );
    }

    return (
      <TableCell key={row.id}>
        {t(`managerDashboard.table.headers.${row.id}`)}
      </TableCell>
    );
  }

  return (
    <>
      {editQualityController.show && (
        <AssignDialog
          open={editQualityController.show}
          handleClose={closeEditQualityControllerDialog}
          transcribersOptions={filterOptions.data.qualityControllers || []}
          handleAssign={handleSetQualityController}
          selectedjobIds={editQualityController.jobIds}         
          selectPlaceholder={t(
            'managerDashboard.toolbar.qualityControllerSelectorPlaceholder',
          )}
          dialogTitle={t(
            'managerDashboard.toolbar.qualityControllerAssignmentTitle',
          )}
          selectorHelper={t('managerDashboard.toolbar.qualityController')}
        />
      )}
      {editWorkflowStatus.show && (
        <EditWorkflowStatusDialog
          hideEditWorkflowStatusDialog={() =>
            setEditWorkflowStatus({ show: false, job: null })
          }
          editWorkflowStatus={editWorkflowStatus}
          refreshTable={refreshJobTable}
        />
      )}
      {editWorkflowStyle.show && (
        <EditWorkflowStyleDialog
          hideEditWorkflowStyleDialog={() =>
            setEditWorkflowStyle({ show: false, job: null })
          }
          editWorkflowStyle={editWorkflowStyle}
          refreshTable={refreshJobTable}
        />
      )}
      {dialogEditOptions.show && (
        <DeadlineDialog
          handleClose={closeDialog}
          editDeadline={dialogEditOptions}
          handleDeadlineEdit={handleDeadlineChange}
          dialogTitle={t(
            `common.changeDeadline.${
              dialogEditOptions.deadlineRole || 'client'
            }`,
          )}
        />
      )}
      <Grid container spacing={0} className={classes.root}>
        <Grid item xs={10} sm={10}>
          <JobsTableToolbar
            closeDialog={closeDialog}
            dialogEditOptions={dialogEditOptions}
            openChangeDatesDialog={openChangeDatesDialog}
            handleDeadlineChange={handleDeadlineChange}
            handleInvite={handleInvite}
            handleAssign={handleAssign}
            handleAssignManagers={handleAssignManagers}
            handleCancelJob={handleCancelJob}
            handleReopenJobs={handleReopenJobs}
            handleAssignQualityController={handleAssignQualityController}
            selectedJobIds={selected}
            selectedJobs={selectedJobs}
            managersList={filterOptions.data.perfectJobManagers}
            transcribersOptions={filterOptions.data.transcribers}
            qualityControllerOptions={filterOptions.data.qualityControllers}
            handleSetSkipQualityControl = {handleSetSkipQualityControl}
          />
          {selected.length > 0 && (
            <div className={classes.selectionInfo}>
              {t('managerDashboard.toolbar.selectedItems', {
                selectedItems: selected.length,
              })}
              <Button
                variant="contained"
                className={classes.selectionInfoClear}
                onClick={() => {
                  handleSelectItem(null);
                  setSelectedJobs([]);
                }}
                disabled={selected.length === 0}
                color="primary"
              >
                {t('managerDashboard.toolbar.clearSelection')}
              </Button>
            </div>
          )}
        </Grid>
        <Grid item xs={10} sm={10} className={classes.tableWarapper}>
          <Table style={{ tableLayout: 'auto' }} size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                {getHeadRowsByRole(role).map(
                  (row) => getHeadRowsByRoleMapper(row),
                  this,
                )}
              </TableRow>
            </TableHead>
            {jobs.isLoading && <Loading thickness={5} />}
            {jobs.loaded && (
              <TableBody>
                {filterJobs(jobs.data.content).map((job) => {
                  // FIXME this is backend logic. It has no place in the front-end.
                  if (!job.transcriberDeadline) {
                    job.transcriberDeadline = '';
                  }
                  if (!job.qualityControlDeadline) {
                    job.qualityControlDeadline = '';
                  }
                  const {
                    jobId,
                    jobStatus,
                    qcEnabled,
                    filename,
                    notes,
                    transcriber,
                    invoiced,
                    deadline,
                    transcriptionType,
                    perfectJobManager,
                    numberOfSpeakers,
                    client,
                    workflowStyle,
                    manualInvoice,
                    created,
                    updated,
                    jobOptions,
                    externalCustomerIdentifier,
                    language,
                    targetLanguage,
                    transcriberDeadline,
                    qualityController,
                    qualityControlDeadline,
                    workflowStatus,
                    nrAudioSeconds,
                    priority,
                  } = job;

                  const isSelectedVal = isSelected(jobId);
                  const isSelectable = checkIfSelectable(job);
                  return (
                    <TableRow
                      key={jobId}
                      hover
                      role="checkbox"
                      aria-checked={isSelectedVal}
                      tabIndex={-1}
                      style={{
                        backgroundColor: getBackgroundColorByStatus(
                          jobStatus,
                          priority,
                        ),
                      }}
                      selected={isSelectedVal}
                    >
                      <TableCell padding="checkbox">
                        {isSelectable && (
                          <Checkbox
                            checked={isSelectedVal}
                            onClick={() => {
                              handleSelectItem(jobId);
                              addOrRemoveJobFromList(job);
                            }}
                          />
                        )}
                      </TableCell>
                      {/* Filename / JobID */}
                      <TableCell>
                        <AmberTextButton
                          onClick={() => showDetails(jobId, true)}
                          text={filename}
                          textProps={{
                            paddingLeft: 0,
                            paddingRight: 0,
                            display: 'block',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                          classes={{ root: classes.nameJobIdColumn }}
                        />

                        <div className={classes.commentRoot}>
                          <span>{jobId}</span>
                          <Tooltip
                            disableFocusListener
                            title={t('notes.editNotes')}
                          >
                            <Comment
                              className={classes.editTranscriberDeadlineIcon}
                              onClick={() => showDetails(jobId, true)}
                              style={{
                                color: notes
                                  ? 'rgba(0, 0, 0, 0.87)'
                                  : '#9E9E9E',
                              }}
                            />
                          </Tooltip>
                        </div>
                      </TableCell>
                      {/* Transcription Type */}
                      <TableCell>
                        {transcriptionType ===
                        TRANSCRIPTION_TYPE_TRANSLATED_SUBTITLES
                          ? 'translated subtitles'
                          : transcriptionType}
                      </TableCell>
                      {/* transcriptionStyle */}
                      {role.includes(ADMIN) && (
                        <TableCell>{jobOptions?.transcriptionStyle}</TableCell>
                      )}
                      {/* Workflow Style */}
                      <TableCell>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Box display="block" component="span" marginRight={2}>
                            {workflowStyle || '-'}
                          </Box>
                          {transcriptionType !== TRANSCRIPTION && (
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Edit
                                className={classes.editTranscriberDeadlineIcon}
                                onClick={() => openWorkflowStyleEditDialog(job)}
                              />
                            </Box>
                          )}
                        </Box>
                      </TableCell>
                      {/* turnaroundTime */}
                      {role.includes(ADMIN) && (
                        <TableCell>{jobOptions?.turnaroundTime}</TableCell>
                      )}
                      {/* Created */}
                      <TableCell>
                        <div className={classes.deadlineDiv}>
                          <RemainingDeadline
                            deadline={created}
                            noStyle
                            noTooltip
                          />
                          {getDateTimeAmsterdam(created)}
                        </div>
                      </TableCell>
                      {/* Updated */}
                      {role.includes(ADMIN) && (
                        <TableCell>
                          <div className={classes.deadlineDiv}>
                            <RemainingDeadline
                              deadline={updated}
                              noStyle
                              noTooltip
                            />
                            {getDateTimeAmsterdam(updated)}
                          </div>
                        </TableCell>
                      )}
                      {/* Language */}
                      <TableCell>{intlConvertor(language)}</TableCell>
                      {/* Target Language */}
                      <TableCell>
                        {transcriptionType ===
                        TRANSCRIPTION_TYPE_TRANSLATED_SUBTITLES
                          ? intlConvertor(targetLanguage)
                          : ''}
                      </TableCell>
                      {/* Number audio seconds */}
                      <TableCell>{toHHMMSS(nrAudioSeconds)}</TableCell>
                      {/* Transcriber Deadline */}
                      <TableCell>
                        <div className={classes.tDeadline}>
                          {shouldShowWarningIcon(job) && (
                            <Warning className={classes.warningIcon} />
                          )}
                          <div className={classes.deadlineDiv}>
                            <RemainingDeadline
                              deadline={transcriberDeadline}
                              noTooltip
                            />
                            <div className={classes.editIconRoot}>
                              <span>{getDateTimeAmsterdam(transcriberDeadline)}</span>
                              {role.includes(ADMIN) && (
                                <Edit
                                  className={
                                    classes.editTranscriberDeadlineIcon
                                  }
                                  onClick={() => openDialog(job, TRANSCRIBER)}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      {/* Client Deadline */}
                      <TableCell>
                        <div className={classes.deadlineDiv}>
                          <RemainingDeadline deadline={deadline} noTooltip />
                          <div className={classes.editIconRoot}>
                            <span>{getDateTimeAmsterdam(deadline)}</span>
                            {role.includes(ADMIN) && (
                              <Edit
                                className={classes.editTranscriberDeadlineIcon}
                                onClick={() => openDialog(job, null)}
                              />
                            )}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={classes.deadlineDiv}>
                          <RemainingDeadline
                            deadline={qualityControlDeadline}
                            noTooltip
                          />
                          <div className={classes.editIconRoot}>
                            <span>{getDateTimeAmsterdam(qualityControlDeadline)}</span>
                            {role.includes(ADMIN) && (
                              <Edit
                                className={classes.editTranscriberDeadlineIcon}
                                onClick={() => openDialog(job, QUALITY_CHECKER)}
                              />
                            )}
                          </div>
                        </div>
                      </TableCell>
                      {/* Job Priority */}
                      <TableCell>{priority}</TableCell>
                      {/* Job Status */}
                      <TableCell>{jobStatus}</TableCell>
                      {/* Workflow Status */}
                      <TableCell>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Box display="block" component="span" marginRight={2}>
                            {workflowStatus || '-'}
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Edit
                              className={classes.editTranscriberDeadlineIcon}
                              onClick={() => openWorkflowStatusEditDialog(job)}
                            />
                          </Box>
                        </Box>
                      </TableCell>
                      {/* transcriber */}
                      <TableCell>
                        <div className={classes.noWrap}>{transcriber}</div>
                      </TableCell>
                      <TableCell>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <div className={classes.noWrap}>
                            {qualityController || '-'}
                          </div>
                          <Edit
                            className={classes.editTranscriberDeadlineIcon}
                            onClick={() =>
                              setOpenQualityController({
                                show: true,
                                qualityController,
                                jobIds: [jobId],
                              })
                            }
                          />
                        </Box>
                      </TableCell>
                      {/* External Customer ID  */}
                      {role.includes(ADMIN) && (
                        <TableCell>{externalCustomerIdentifier}</TableCell>
                      )}
                      {/* Client */}
                      {role.includes(ADMIN) && (
                        <TableCell className={classes.noWrap}>
                          {client}
                        </TableCell>
                      )}
                      {/* Number of speakers */}
                      <TableCell>
                        <Person className="table-cell__user-icon" />
                        {numberOfSpeakers}
                      </TableCell>
                      {/* Invoice / Paid */}
                      {role.includes(ADMIN) &&
                        (invoiced ? (
                          <TableCell>
                            {t('managerDashboard.table.values.yes')}
                          </TableCell>
                        ) : (
                          <TableCell className={classes.notInvoiced}>
                            {t('managerDashboard.table.values.no')}
                          </TableCell>
                        ))}
                      {/* Manual Invoiced */}
                      {role.includes(ADMIN) &&
                        (manualInvoice ? (
                          <TableCell>
                            {t('managerDashboard.table.values.yes')}
                          </TableCell>
                        ) : (
                          <TableCell className={classes.notInvoiced}>
                            {t('managerDashboard.table.values.no')}
                          </TableCell>
                        ))}
                      {/* PJM */}
                      {role.includes(ADMIN) && (
                        <TableCell className={classes.noWrap}>
                          {perfectJobManager}
                        </TableCell>
                      )}
                      {/* Actions */}
                      <TableCell>
                        <div style={{ display: 'flex' }}>
                          <ResetButton
                            role={role}
                            job={job}
                            handleReopenJobs={handleReopenJobs}
                          />
                          <CancelJobButton
                            role={role}
                            job={job}
                            handleCancelJob={handleCancelJob}
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div style={{ display: 'flex' }}>
                          <Checkbox
                            checked={qcEnabled}
                            onClick={() => setSkipQC(jobId, qcEnabled)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={jobs.data.totalElements}
            rowsPerPage={pageSize}
            page={page}
            backIconButtonProps={{
              'aria-label': t('common.table.prevPage'),
            }}
            nextIconButtonProps={{
              'aria-label': t('common.table.nextPage'),
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
        <Grid item xs={2} sm={2}>
          {filterOptions.isLoading && <Loading thickness={5} />}
          {filterOptions.loaded && (
            <Filters
              header={t('managerDashboard.table.title')}
              onSearch={onSearch}
              filterOptions={filterOptions.data}
              jobs={jobs.loaded ? jobs.data.content : []}
              onChangeFileNameFilter={onChangeFileNameFilter}
              setIsFilterApplied={setIsFilterApplied}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

JobsTable.propTypes = {
  selectedJobs: PropTypes.arrayOf(Job),
  selected: PropTypes.arrayOf(PropTypes.string),
  handleSetQualityController: PropTypes.func.isRequired,
  jobs: PropTypes.shape({
    isLoading: PropTypes.bool,
    loaded: PropTypes.bool,
    data: PropTypes.shape({
      content: PropTypes.oneOfType([
        PropTypes.arrayOf(Job),
        PropTypes.shape({}),
      ]),
      totalElements: PropTypes.number,
    }),
  }),
  filterOptions: PropTypes.shape({
    loaded: PropTypes.bool,
    data: PropTypes.shape({
      perfectJobManagers: PropTypes.arrayOf(PropTypes.string),
      qualityControllers: PropTypes.arrayOf(PropTypes.string),
      transcribers: PropTypes.arrayOf(PropTypes.string),
    }),
    isLoading: PropTypes.bool,
  }).isRequired,
  refreshTable: PropTypes.number,
  handleSelectItem: PropTypes.func.isRequired,
  handleReopenJobs: PropTypes.func.isRequired,
  handleCancelJob: PropTypes.func.isRequired,
  setSelectedJobs: PropTypes.func.isRequired,
  handleTranscriberDeadline: PropTypes.func.isRequired,
  handleClientDeadline: PropTypes.func.isRequired,
  refreshJobTable: PropTypes.func.isRequired,
  loadFiltersOptions: PropTypes.func.isRequired,
  loadJobList: PropTypes.func.isRequired,
  handleInvite: PropTypes.func.isRequired,
  handleAssign: PropTypes.func.isRequired,
  handleAssignManagers: PropTypes.func.isRequired,
  showDetails: PropTypes.func.isRequired,
  onChangeFileNameFilter: PropTypes.func.isRequired,
  handleQualityControllerDeadline: PropTypes.func.isRequired,
  handleAssignQualityController: PropTypes.func.isRequired,
  handleSetSkipQualityControl: PropTypes.func.isRequired,
};

JobsTable.defaultProps = {
  selectedJobs: [],
  selected: [],
  jobs: { data: { content: [], totalElements: 0 } },
  refreshTable: 0,
};
export default JobsTable;
