/* eslint-disable import/prefer-default-export */
import apiClient from './apiClient';

/**
 * Creates the session cookie for Google SSO
 * @param {string} idToken
 * @returns {Promise<object>} cookie object
 */
export async function createGoogleSSOSessionCookie(idToken) {
  const url = '/google/firebase-login';
  const params = {
    params: { idToken },
  };
  return apiClient.post(url, null, params);
}
