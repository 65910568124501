import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import AmberSectionTitle from '../../labels/AmberSectionTitle';
import AmberCard from '../../cards/AmberCard';
import generateKey from '../../../utils/generateReactKey';
import { getFormattedDate } from '../../../utils/timeUtils';
import { refreshTableAtom } from '../../../store/jobManagerDashboard';
import useSnackbar from '../../../utils/useSnackbar';
import { setNoteToJob } from '../../../utils/api';

import Notes from '../notes';

const useStyles = makeStyles((theme) => ({
  historyNotesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  historyCardContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    background: theme.palette.custom.whiteTwo,
    borderRadius: '0.3em',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: '1em',
  },
  cardContents: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    '&:last-child': {
      paddingBottom: '0.5em',
    },
  },
  cardText: {
    flex: '0 0 80%',
    alignSelf: 'center',
    color: theme.palette.custom.purplishGray,
  },
  cardDate: {
    alignSelf: 'center',
    color: theme.palette.custom.purplishGray,
  },
  historyNotesTitle: {
    color: theme.palette.custom.purplishGray,
  }
}));




function HistoryCard(props) {
  const {
    history: { created, userName, message },
  } = props;
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <AmberCard
      classes={{ root: classes.card }}
      contentClasses={{ root: classes.cardContents }}
    >
      <div className={classes.cardText}>
        <AmberSectionTitle
          text={message}
          typographyVariant="caption"
          borderBottom={0}
          fontWeight="bolder"
        />
        <AmberSectionTitle
          typographyVariant="caption"
          text={userName || t('common.jobItemDialog.unknownPerson')}
          borderBottom={0}
        />
      </div>
      <AmberSectionTitle
        text={getFormattedDate(created, null)}
        typographyClasses={{ root: classes.cardDate }}
        typographyVariant="caption"
        borderBottom={0}
      />
    </AmberCard>
  );
}

HistoryCard.propTypes = {
  history: PropTypes.shape({
    userName: PropTypes.string,
    message: PropTypes.string,
    created: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

const filterHistory = (history, client) => {
  if (!history) {
    return [];
  }
  return history.filter((item) => item.userName !== client);
};

function HistoryNotesTabPanelContents(props) {
  const [t] = useTranslation();
  const { history, notes, client, jobId, canEditNotes } = props;
  const [stateNotes, setStateNotes] = useState(
    notes ? notes.replace(/^"|"$/g, '') : '',
  );
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const { showError } = useSnackbar();
  const classes = useStyles();
  const historyFiltered = filterHistory(history, client);
  const tableRefreshFunction = useRecoilValue(refreshTableAtom);

  const handleChange = (event) => {
    const { value } = event;
    setStateNotes(value);
  };

  const handleClick = async () => {
    try {
      setIsRequestLoading(true);
      await setNoteToJob(jobId, stateNotes);
      if (tableRefreshFunction?.func) {
        tableRefreshFunction.func(stateNotes);
      }
    } catch (error) {
      showError(error.message);
    } finally {
      setIsRequestLoading(false);
    }
  };

  return (
    <div className={classes.historyNotesContainer}>
      <AmberSectionTitle
        typographyClasses={{ root: classes.historyNotesTitle }}
        text={t('common.jobFields.historyLog')}
      />
      <div className={classes.historyCardContainer}>
        {historyFiltered.map((historyItem, index) => (
          <HistoryCard history={historyItem} key={generateKey(index)} />
        ))}
      </div>
      <AmberSectionTitle
        typographyClasses={{ root: classes.historyNotesTitle }}
        text={t('common.jobFields.notes')}
      />
      <Notes
        notes={stateNotes}
        canEditNotes={canEditNotes}
        onChange={handleChange}
        onClickSave={handleClick}
        isRequestLoading={isRequestLoading}
      />
    </div>
  );
}

HistoryNotesTabPanelContents.propTypes = {
  history: PropTypes.arrayOf(PropTypes.shape()),
  notes: PropTypes.string,
  client: PropTypes.string,
  jobId: PropTypes.string,
  canEditNotes: PropTypes.bool,
};
HistoryNotesTabPanelContents.defaultProps = {
  notes: '',
  client: null,
  jobId: null,
  canEditNotes: false,
  history: null,
};
export default HistoryNotesTabPanelContents;
