import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  useRecoilState,
  useRecoilStateLoadable,
  useRecoilValue,
  useResetRecoilState,
} from 'recoil';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import LoginForm from '../components/login/loginForm';
import { getUser } from '../utils/api';
import { useDataApi } from '../utils/apiHooks';
import useSnackbar from '../utils/useSnackbar';
import { ROLES_ALLOWED } from '../roles';
import {
  currentGoogleSSOUserAtom,
  currentUserDetailsAtom,
  currentUserLoginDetailsAtom,
  currentUserRoleAtom,
} from '../store/user';
import AmberPaper from '../components/papers/AmberPaper';
import {
  cleanLocalStorage,
  getLoginDetails,
  getUserLoggedIn,
} from '../utils/auth';
import {
  getUserRoleFromRolesArray,
  storeUserRoleToLocalStorageNoAccessToken,
} from '../utils/apiHelper';
import CircularDeterminate from '../components/common/loading';
import {
  RECOIL_LOADABLE_DONE_STATE,
  RECOIL_LOADABLE_LOADING_STATE,
} from '../constants/constants';
import {
  setupFirebaseAuthentication,
  signInWithEmailPassword,
} from '../helpers/firebaseAuthentication';
import { redirectToDashBoard } from '../helpers/urlHandler';
import { getItemFromLocalStorage } from '../helpers/storage';
import {
  GOOGLE_SSO_LOADING_LOCAL_STORAGE,
  GOOGLE_SSO_LOCAL_STORAGE_CHANGE,
} from '../components/common/googleSso/constants';
import { googleSSOLoadingAtom } from '../store/googleSso';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    padding: theme.spacing(5),
    backgroundColor: theme.palette.common.white,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

function FirebaseLogin(props) {
  const [forceUserRoleChoice, setForceUserRoleChoice] = useState(false);
  const { showError } = useSnackbar();
  const [t] = useTranslation();
  const [userRoles, setUserRoles] = useRecoilState(currentUserRoleAtom);
  const [userDetails, setCurrentUserDetails] = useRecoilStateLoadable(
    currentUserDetailsAtom,
  );
  const [loginDetails, setLoginDetails] = useRecoilState(
    currentUserLoginDetailsAtom,
  );
  const googleSSOUser = useRecoilValue(currentGoogleSSOUserAtom);
  const resetGoogleSSOUser = useResetRecoilState(currentGoogleSSOUserAtom);
  const [localStorageGoogleSSOChange, setLocalStorageGoogleSSOChange] =
    useState(false);
  const resetGoogleSSOLoading = useResetRecoilState(googleSSOLoadingAtom);

  const { history } = props;
  const classes = useStyles();

  const checkUserRoles = (roles) => {
    setUserRoles(roles);
    if (roles.length > 1) {
      setForceUserRoleChoice(true);
    }
  };

  const handleUserRetrieval = (data) => {
    const roles = getUserRoleFromRolesArray(loginDetails.role);
    const dataNoExtraRoles = { ...data, roles };
    if (!ROLES_ALLOWED.some((role) => roles?.includes(role))) {
      showError(t('loginForm.userTypeError'));
      cleanLocalStorage();
      resetGoogleSSOUser();
      resetGoogleSSOLoading();
      return;
    }
    setCurrentUserDetails({ ...dataNoExtraRoles });
    checkUserRoles(roles);
  };
  const [, doUserRetrieval] = useDataApi(
    false,
    {},
    showError,
    handleUserRetrieval,
  );

  function handleLogin() {
    setLoginDetails({
      ...getLoginDetails(),
    });
    doUserRetrieval(getUser());
  }

  const [loginStatus, doLogin] = useDataApi(false, {}, showError, handleLogin);

  const userLoadingDone = () =>
    userDetails.state === RECOIL_LOADABLE_DONE_STATE &&
    Object.keys(userDetails.contents).length > 0 &&
    userRoles.length === 1;

  const NEW_LOGIN_ENABLED =
    process.env.REACT_APP_FF_NEW_LOGIN !== undefined
      ? JSON.parse(process.env.REACT_APP_FF_NEW_LOGIN)
      : false;

  const redirectUser = () => {
    if (userRoles && userRoles.length === 1 && userLoadingDone()) {
      redirectToDashBoard(history, userRoles);
      localStorage.removeItem(GOOGLE_SSO_LOADING_LOCAL_STORAGE);
      resetGoogleSSOLoading();
      return;
    }
    if (NEW_LOGIN_ENABLED) {
      history.replace('/');
    } else {
      history.replace('/login_new');
    }
  };

  useEffect(() => {
    redirectUser();
  }, [userRoles, userDetails]);

  useEffect(() => {
    setupFirebaseAuthentication();
  }, []);

  const onLogin = useCallback(
    (password, email) => {
      if (email.trim() === '') {
        showError(t('loginForm.emailError'));
      } else if (password === '') {
        showError(t('loginForm.passwordError'));
      } else {
        doLogin(signInWithEmailPassword(email.trim(), password));
      }
    },
    [doLogin, showError],
  );

  const handleRoleCheck = useCallback((id) => {
    setForceUserRoleChoice(false);
    setUserRoles([id]);
    storeUserRoleToLocalStorageNoAccessToken(id);
    setLoginDetails({
      ...loginDetails,
      role: id,
    });
  });

  // Handles the log in process for Google SSO
  // Listens to local storage changes and checks if the user is logged in
  window.addEventListener(GOOGLE_SSO_LOCAL_STORAGE_CHANGE, () => {
    const googleSSOLogin = getItemFromLocalStorage(
      GOOGLE_SSO_LOADING_LOCAL_STORAGE,
    );

    if (googleSSOLogin) {
      setLocalStorageGoogleSSOChange(true);
    }
  });

  useEffect(() => {
    if (localStorageGoogleSSOChange && googleSSOUser) {
      setLocalStorageGoogleSSOChange(false);

      const isLoggedIn = getUserLoggedIn();
      if (isLoggedIn) {
        handleLogin();
      }
    }
  }, [googleSSOUser]);

  return (
    <div className={classes.root}>
      {userDetails.state === RECOIL_LOADABLE_LOADING_STATE ? (
        <CircularDeterminate size="3.6rem" thickness={3.6} />
      ) : (
        <AmberPaper classes={{ root: classes.paper }}>
          <LoginForm
            onLogin={onLogin}
            loginStatus={loginStatus}
            roleCheckRequired={forceUserRoleChoice}
            handleRoleCheck={handleRoleCheck}
          />
        </AmberPaper>
      )}
    </div>
  );
}

FirebaseLogin.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func, push: PropTypes.func })
    .isRequired,
};

export default FirebaseLogin;
