import React, { useState } from 'react';

function useSelectMultiple(initialArray) {
  const [selected, setSelected] = useState(initialArray);

  function isSelected(id) {
    return selected.includes(id);
  }

  function handleSelectItem(id) {
    if (!id) {
      setSelected([]);
    } else {
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
    }
  }

  return [selected, handleSelectItem];
}

function useSelectOne(initialItem) {
  const [selected, setSelected] = useState(initialItem);

  function isSelected(item) {
    return selected === item;
  }

  function handleSelectItem(item) {
    setSelected(item);
  }

  return [selected, handleSelectItem];
}

export { useSelectMultiple, useSelectOne };
