import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import {
  getWorkflowStyles,
  updateWorkflowStyleAndStatus,
} from '../../utils/api';
import generateKey from '../../utils/generateReactKey';
import {
  TRANSCRIPTION,
  CAPTIONS,
  TRANSLATED_SUBTITLES,
  TRANSLATION,
} from '../../constants/transcriptionTypes';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const isTranslationWorkflow = (key) =>
  key.toLocaleLowerCase().includes(TRANSLATION);

function EditWorkflowStyleDialog(props) {
  const classes = useStyles();

  const {
    editWorkflowStyle: {
      show,
      job: { workflowStyle, jobId, transcriptionType },
    },
    hideEditWorkflowStyleDialog,
    refreshTable,
  } = props;

  const [t] = useTranslation();
  const [ws, setWs] = useState(workflowStyle || '');
  const [workflowStyles, setWorkflowStyles] = useState([]);

  function getListOfWorkflowStyles() {
    getWorkflowStyles().then((res) => {
      const response = res.data;
      const list = [];
      switch (transcriptionType) {
        case CAPTIONS:
          Object.keys(response).forEach((key) => {
            if (!isTranslationWorkflow(key)) {
              list.push(key);
            }
          });
          break;
        case TRANSLATED_SUBTITLES:
          Object.keys(response).forEach((key) => {
            if (isTranslationWorkflow(key)) {
              list.push(key);
            }
          });
          break;
        default:
          break;
      }

      setWorkflowStyles(list);
    });
  }

  function updateWorkflowStyle() {
    updateWorkflowStyleAndStatus(jobId, ws, null).then(() => {
      refreshTable();
      hideEditWorkflowStyleDialog();
    });
  }

  useEffect(() => {
    getListOfWorkflowStyles();
  }, []);

  if (!show) return null;
  return (
    <Dialog
      open={show}
      onClose={hideEditWorkflowStyleDialog}
      aria-labelledby="edit-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="assign-dialog-title">
        {t('common.changeWorkflowStyle.title')}
      </DialogTitle>

      <DialogContent>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">
            {t('common.jobFields.workflowStyle')}
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={ws}
            onChange={(event) => setWs(event.target.value)}
            label={t('common.jobFields.workflowStyle')}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {workflowStyles.map((item, index) => (
              <MenuItem key={generateKey(index)} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button onClick={hideEditWorkflowStyleDialog} color="secondary">
          {t('common.button.cancel')}
        </Button>
        <Button onClick={updateWorkflowStyle} color="primary">
          {t('common.button.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EditWorkflowStyleDialog.propTypes = {
  editWorkflowStyle: PropTypes.shape({
    show: PropTypes.bool,
    job: PropTypes.shape({
      transcriptionType: PropTypes.string,
      jobId: PropTypes.string,
      workflowStyle: PropTypes.string,
    }),
  }),
  hideEditWorkflowStyleDialog: PropTypes.func.isRequired,
  refreshTable: PropTypes.func.isRequired,
};
EditWorkflowStyleDialog.defaultProps = {
  editWorkflowStyle: {
    show: false,
    job: {},
  },
};

export default EditWorkflowStyleDialog;
