import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';

import { FILE_REMOVED } from './inputActionsConstants';
import AmberIconButton from '../buttons/AmberIconButton';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: theme.palette.error.main,
  },
}));

function UploadedFile(props) {
  const {
    file,
    file: { name },
    fileRemovalHandler,
  } = props;

  const classes = useStyles();

  const onClickRemove = useCallback(() => {
    const synteticEvent = {
      target: {
        value: file,
        action: FILE_REMOVED,
      },
    };
    fileRemovalHandler(synteticEvent);
  });

  return (
    <Box
      key={name}
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      height={35}
    >
      <Box component="span" display="block" fontSize={14}>
        {name}
      </Box>
      <Box>
        <AmberIconButton
          className={classes.iconButton}
          onClick={onClickRemove}
          icon={<DeleteIcon fontSize="small" />}
        />
      </Box>
    </Box>
  );
}
UploadedFile.propTypes = {
  file: PropTypes.shape({ name: PropTypes.string }).isRequired,
  fileRemovalHandler: PropTypes.func.isRequired,
};

export default UploadedFile;
