import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Names from './names';
import Items from './items';
import AmberNoItemFoundCard from '../../../cards/AmberNoItemFound';

export default function GlossaryPanel(props) {
  const { glossary } = props;

  const [t] = useTranslation();

  if (!glossary) {
    return <AmberNoItemFoundCard text={t('common.glossary.error')} />;
  }

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Names names={glossary.names} />
        <Items items={glossary.items} />
      </Box>
    </>
  );
}

GlossaryPanel.propTypes = {
  glossary: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    names: PropTypes.arrayOf(PropTypes.string).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }),
};

GlossaryPanel.defaultProps = {
  glossary: null,
};
