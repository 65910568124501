/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/browser';

const SECTION = 'section';

/**
 * Send the error to Sentry logs passing a tag
 * @param {error} error
 * @param {string} tag
 */
export function trackSentryErrorByTag(error, tag) {
  Sentry.captureException(error, (scope) => {
    // Scope tags
    scope.setTag(SECTION, tag);

    return scope;
  });
}
