import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core';

import AmberSimpleSelect from '../selectors/AmberSimpleSelect';
import AmberSectionTitle from '../../labels/AmberSectionTitle';
import { getTranslatedIssueTypesMap } from '../../../constants/jobIssueTypes';

import AmberCustomBoxTextArea from '../../textfields/amberCustomBoxTextArea';

const useStyles = makeStyles((theme) => ({
  contentsContainer: { display: 'flex', flexDirection: 'column' },
  label: {
    color: theme.palette.custom.charcoalGray,
  },
  selector: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '1em',
  },
  textArea: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '1em',
  },
}));

const getControl = (isError, theme, provided) => {
  if (isError) {
    return {
      ...provided,
      borderColor: theme.palette.error.main,
      boxShadow: 'none',
      outlineColor: theme.palette.error.main,
      '&:hover': {
        borderColor: theme.palette.error.main,
      },
    };
  }

  return {
    ...provided,
    border: `1px solid ${theme.palette.custom.darkGrey}`,
    borderRadius: '0.5em',
    '&:hover': {
      borderColor: theme.palette.custom.lightGray,
    },
  };
};

const getSelectStyles = (theme, isError) => ({
  control: (provided) => ({ ...getControl(isError, theme, provided) }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: theme.palette.custom.purplishGray,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    color: theme.palette.custom.purplishGray,
  }),
});

function JobReportingContents(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const [notesError, setNotesError] = useState(false);
  const [issueTypeError, setIssueTypeError] = useState(false);

  const selectStyles = getSelectStyles(theme, issueTypeError);
  const { handleChange, issueType, notes, jobStatus } = props;

  const handleSelectChange = useCallback((event) => {
    const {
      selectedOption,
      selectAction: { action },
      id,
    } = event;
    if (action === 'clear') {
      handleChange({ id, value: null });
      return;
    }
    handleChange({ id, value: { ...selectedOption } });
  });

  const textAreaChange = useCallback((event) => {
    const { value, id } = event;
    handleChange({ id, value });
  });

  useEffect(() => {
    if (notes !== undefined && issueType !== undefined) {
      if (!notes.trim() && !issueType) {
        setNotesError(true);
        setIssueTypeError(true);
        return;
      }

      if (!notes.trim()) {
        setNotesError(true);
        setIssueTypeError(false);
      } else if (!issueType) {
        setNotesError(false);
        setIssueTypeError(true);
      } else {
        setNotesError(false);
        setIssueTypeError(false);
      }
    }
  }, [notes, issueType]);

  return (
    <div className={classes.contentsContainer}>
      <div className={classes.selector}>
        <AmberSectionTitle
          typographyClasses={{ root: classes.label }}
          typographyVariant="body1"
          text={t('dialogIssueReport.selectorLabel')}
        />
        <AmberSimpleSelect
          id="issueType"
          value={issueType}
          onChange={handleSelectChange}
          placeholder={t('dialogIssueReport.selectorPlaceholder')}
          optionsArray={getTranslatedIssueTypesMap(t, jobStatus)}
          styles={{ ...selectStyles }}
          errorHelper={t('common.error.noEmpty')}
          error={issueTypeError}
          isClearable
          maxMenuHeight={150}
          isSearchable
        />
      </div>
      <div className={classes.textArea}>
        <AmberSectionTitle
          typographyClasses={{ root: classes.label }}
          typographyVariant="body1"
          text={t('dialogIssueReport.textAreaLabel')}
        />
        <AmberCustomBoxTextArea
          handleChange={textAreaChange}
          value={notes}
          id="notes"
          hasError={notesError}
          formHelperText={t('dialogIssueReport.textAreaExplanation')}
          placeholder={t('dialogIssueReport.textAreaPlaceholder')}
        />
      </div>
      <AmberSectionTitle
        typographyClasses={{ root: classes.label }}
        typographyVariant="body2"
        text={t('dialogIssueReport.jobReportWarning')}
      />
    </div>
  );
}
JobReportingContents.propTypes = {
  issueType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ]),
  notes: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  jobStatus: PropTypes.string.isRequired,
};

JobReportingContents.defaultProps = {
  issueType: undefined,
  notes: undefined,
};

export default JobReportingContents;
