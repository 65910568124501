import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'react-i18next';

import AmberClickableCard from '../cards/AmberClickableCard';
import AmberSectionTitle from '../labels/AmberSectionTitle';
import { QUALITY_CHECKER, TRANSCRIBER } from '../../roles';
import generateKey from '../../utils/generateReactKey';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  card: {
    padding: '1em',
    maxWidth: '10em',
  },
  roleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  roleIcon: {
    fontSize: '3rem',
    paddingLeft: '0.2em',
  },
}));
const Role = (props) => {
  const { role } = props;
  let component = null;
  const classes = useStyles();
  const [t, i18n] = useTranslation();

  switch (role) {
    case TRANSCRIBER:
      component = (
        <div className={classes.roleContainer}>
          <SpellcheckIcon
            fontSize="inherit"
            classes={{ root: classes.roleIcon }}
          />
          <AmberSectionTitle
            typographyVariant="caption"
            text={t('loginForm.transcriberRole')}
          />
        </div>
      );
      break;
    case QUALITY_CHECKER:
      component = (
        <div className={classes.roleContainer}>
          <EditIcon fontSize="inherit" classes={{ root: classes.roleIcon }} />
          <AmberSectionTitle
            typographyVariant="caption"
            text={t('loginForm.qualityControlRole')}
          />
        </div>
      );
      break;
    default:
      break;
  }
  return component;
};

function RoleCheckComponent(props) {
  const { roles, onClickRole } = props;
  const classes = useStyles();
  const [t, i18n] = useTranslation();
  return (
    <div className={classes.container}>
      <AmberSectionTitle typographyVariant="h4" text={t('loginForm.welcome')} />
      <AmberSectionTitle
        typographyVariant="caption"
        text={t('loginForm.pathQuestion')}
      />
      <div className={classes.cardContainer}>
        {roles.map((role) => (
          <div key={generateKey(role)} className={classes.card}>
            <AmberClickableCard onClick={onClickRole} id={role}>
              <Role role={role} />
            </AmberClickableCard>
          </div>
        ))}
      </div>
    </div>
  );
}
Role.propTypes = {
  role: PropTypes.string.isRequired,
};
RoleCheckComponent.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClickRole: PropTypes.func.isRequired,
};
export default RoleCheckComponent;
