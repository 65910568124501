import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TranscriberTable from '../components/transcribers/transcriberTable';
import {
  getTranscribers,
  assignPJMToTranscriber,
  removePJMFromTranscriber,
  getFiltersOptions,
} from '../utils/api';
import { useDataApi } from '../utils/apiHooks';
import useSnackbar from '../utils/useSnackbar';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '80px',
    marginBottom: 40,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));
const initFilterOptions = {
  perfectJobManagers: [],
  transcribers: [],
  languages: [],
};

function ManageTranscribersTab() {
  const classes = useStyles();
  const { showError, showSuccess } = useSnackbar();
  const [refreshTableCount, setRefreshTableCount] = useState(0);
  const [transcribers, doFetchTranscribers] = useDataApi(
    false,
    { content: {}, totalElements: 0 },
    onError,
  );
  const [filterOptions, doFetchFilterOptions] = useDataApi(
    false,
    initFilterOptions,
    onError,
  );

  const [assignTranscriberToManager, doAssignTranscriberToManager] = useDataApi(
    false,
    '',
    onError,
    () => {
      showSuccess('Success');
      refreshTable();
    },
  );
  const [removeTranscriberFromManager, doRemoveTranscriberFromManager] =
    useDataApi(false, '', onError, () => {
      showSuccess('Success');
      refreshTable();
    });

  // Assign a transcriber to manager
  async function handleAssignTranscriberToManager(transcriber, pjm) {
    doAssignTranscriberToManager(assignPJMToTranscriber(transcriber, pjm));
  }

  // Remove a transcriber from manager
  async function handleRemoveTranscriberFromManager(transcriber, pjm) {
    doRemoveTranscriberFromManager(removePJMFromTranscriber(transcriber, pjm));
  }

  function onError(msg) {
    showError(msg);
  }

  function refreshTable() {
    setRefreshTableCount(() => refreshTableCount + 1);
  }

  function loadTranscriberList(options) {
    doFetchTranscribers(getTranscribers(options));
  }

  function loadFiltersOptions() {
    doFetchFilterOptions(getFiltersOptions());
  }

  return (
    <div className={classes.container}>
      <TranscriberTable
        transcribers={transcribers}
        loadFiltersOptions={loadFiltersOptions}
        filterOptions={filterOptions}
        handleAssignTranscriberToManager={handleAssignTranscriberToManager}
        handleRemoveTranscriberFromManager={handleRemoveTranscriberFromManager}
        loadTranscriberList={loadTranscriberList}
        refreshTable={refreshTableCount}
      />
    </div>
  );
}

export default ManageTranscribersTab;
