import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

export default function AmberRootButton(props) {
  const { children } = props;

  return <Box p={1}>{children}</Box>;
}

AmberRootButton.propTypes = {
  children: PropTypes.node.isRequired,
};
