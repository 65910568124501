import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(5),
    backgroundColor: theme.palette.common.white,
    borderRadius: 5,
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 30,
  },
}));

function NoMatch() {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center">
      <Paper className={`col-md-4 ${classes.paper}`}>
        <span>Sorry! Does not exist!</span>
      </Paper>
    </Grid>
  );
}

export default NoMatch;
