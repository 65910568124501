import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import {
  getWorkflowStatuses,
  updateWorkflowStyleAndStatus,
} from '../../utils/api';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function EditWorkflowStatusDialog(props) {
  const classes = useStyles();

  const { editWorkflowStatus, hideEditWorkflowStatusDialog, refreshTable } =
    props;
  const { show, job } = editWorkflowStatus;

  const [t, i18n] = useTranslation();
  const [ws, setWs] = useState(job.workflowStatus || '');
  const [workflowStatuses, setWorkflowStatuses] = useState([]);

  function getListOfWorkflowStatuses() {
    getWorkflowStatuses().then((res) => {
      const list = [];
      for (const item in res.data) {
        list.push(item);
      }
      setWorkflowStatuses(list);
    });
  }

  function updateWorkflowStatus() {
    updateWorkflowStyleAndStatus(job.jobId, null, ws).then((res) => {
      refreshTable();
      hideEditWorkflowStatusDialog();
    });
  }

  useEffect(() => {
    getListOfWorkflowStatuses();
  }, []);

  if (!show) return null;
  return (
    <Dialog
      open={show}
      onClose={hideEditWorkflowStatusDialog}
      aria-labelledby="edit-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="assign-dialog-title">
        {t('common.changeWorkflowStatus.title')}
      </DialogTitle>

      <DialogContent>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">
            {t('common.jobFields.workflowStatus')}
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={ws}
            onChange={(event) => setWs(event.target.value)}
            label={t('common.jobFields.workflowStatus')}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {workflowStatuses.map((item) => {
              return <MenuItem value={item}>{item}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button onClick={hideEditWorkflowStatusDialog} color="secondary">
          {t('common.button.cancel')}
        </Button>
        <Button onClick={updateWorkflowStatus} color="primary">
          {t('common.button.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditWorkflowStatusDialog;
