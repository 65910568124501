/* eslint-disable consistent-return */
/* eslint-disable function-paren-newline */
import axios from 'axios';
import { logout as removeUserDetailsAndLogoutUser } from './auth';
import { baseURL, headers, storeUserDetailsToLocalStorage } from './apiHelper';

// Const(s)
const apiClient = axios.create({
  withCredentials: true,
  baseURL,
  headers,
});

// Let(s)
let isRefreshingAccessToken = false;
let requestsToRefresh = [];

// Method(s)
apiClient.interceptors.request.use((config) => {
  isRefreshingAccessToken = false;
  requestsToRefresh = [];

  const newConfig = config;

  return newConfig;
});

function refreshAccessToken() {
  return axios.post(
    `${baseURL}refresh-token`,
    {},
    {
      headers: {
        ...headers,
      },
      withCredentials: true,
    },
  );
}

apiClient.interceptors.response.use(null, (error) => {
  try {
    const { response, config } = error;

    // Unauthorized user
    if (response.status === 401) {
      // Check if we are in the middle of refreshing the token
      if (!isRefreshingAccessToken) {
        isRefreshingAccessToken = true;

        refreshAccessToken()
          .then((resRefreshToken) => {
            storeUserDetailsToLocalStorage(resRefreshToken.data);

            requestsToRefresh.forEach((cb) =>
              cb(resRefreshToken.data.accessToken),
            );
          })
          .catch(() => {
            removeUserDetailsAndLogoutUser();
            requestsToRefresh.forEach((cb) => cb(null));
          });
      }
      return new Promise((resolve, reject) => {
        requestsToRefresh.push((token) => {
          if (token) {
            resolve(axios(config));
          } else {
            reject(error);
          }
        });
      });
    }

    return Promise.reject(error);
  } catch (e) {
    removeUserDetailsAndLogoutUser();
  }
});

export default apiClient;
