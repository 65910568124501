import React from 'react';
import { TextField, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import IndeterminateSpinner from '../loading/indeterminateSpinner';
import RoleCheckComponent from './rolePicker';
import { currentUserRoleAtom } from '../../store/user';
import GoogleSSO from '../common/googleSso/GoogleSSO';
import { googleSSOLoadingAtom } from '../../store/googleSso';
import AmberDividerOr from '../common/AmberDividerOr';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  wrapper: {
    position: 'relative',
    width: '100%',
  },
  submit: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1.5, 4, 1.5, 4),
    height: theme.spacing(6),
    fontSize: '1rem',
    fontWeight: 800,
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    right: 12,
    marginTop: -12,
    marginLeft: -12,
  },
}));
const LoginForm = ({
  setEmail,
  setPassword,
  classes,
  onLogin,
  password,
  email,
  loginStatus,
}) => {
  const [t] = useTranslation();

  const GOOGLE_SSO_ENABLED = JSON.parse(process.env.REACT_APP_FF_GOOGLE_SSO);
  const googleSSOLoading = useRecoilValue(googleSSOLoadingAtom);

  const shouldDisableLoginButton = () =>
    googleSSOLoading || loginStatus.isLoading;

  return (
    <form
      className={classes.form}
      onSubmit={(e) => {
        e.preventDefault();
        onLogin(password, email);
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width={330}
      >
        {GOOGLE_SSO_ENABLED && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gridRowGap={32}
            width="100%"
          >
            <GoogleSSO />
            <AmberDividerOr />
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          marginTop={GOOGLE_SSO_ENABLED ? 2 : -2}
        >
          <TextField
            margin="normal"
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
            id="email"
            label={t('loginForm.emailFieldLabel')}
            name="email"
            autoFocus
            disabled={googleSSOLoading}
            variant="outlined"
          />
          <TextField
            margin="normal"
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            name="password"
            label={t('loginForm.passwordFieldLabel')}
            type="password"
            id="password"
            disabled={googleSSOLoading}
            variant="outlined"
          />
          <div className={classes.wrapper}>
            <Button
              disabled={shouldDisableLoginButton()}
              className={classes.submit}
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
            >
              {t('loginForm.loginButton')}
              {loginStatus.isLoading && (
                <IndeterminateSpinner
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </div>
        </Box>
      </Box>
    </form>
  );
};
const getLoginComponent = (props) => {
  const { roleCheckRequired, ...rest } = props;

  if (roleCheckRequired) {
    return <RoleCheckComponent {...rest} />;
  }
  return <LoginForm {...rest} />;
};
function LoginComponent(props) {
  const classes = useStyles();
  const { onLogin, loginStatus, roleCheckRequired, handleRoleCheck } = props;
  const [password, setPassword] = React.useState('');
  const [email, setEmail] = React.useState('');
  const userRoles = useRecoilValue(currentUserRoleAtom);
  const onClickRole = (id) => {
    handleRoleCheck(id);
    return id;
  };
  return getLoginComponent({
    onLogin,
    loginStatus,
    password,
    email,
    setPassword,
    setEmail,
    classes,
    roleCheckRequired,
    roles: userRoles,
    onClickRole,
  });
}

LoginComponent.propTypes = {
  handleRoleCheck: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  loginStatus: PropTypes.shape({
    data: PropTypes.shape({ _id: PropTypes.string }),
    loaded: PropTypes.bool,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
  }).isRequired,
  roleCheckRequired: PropTypes.bool.isRequired,
};
LoginForm.propTypes = {
  setEmail: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
  onLogin: PropTypes.func.isRequired,
  loginStatus: PropTypes.shape({
    data: PropTypes.shape({ _id: PropTypes.string }),
    loaded: PropTypes.bool,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
  }).isRequired,
};
LoginForm.defaultProps = {
  classes: null,
};

export default LoginComponent;
