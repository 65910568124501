import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import AmberSimpleTooltip from '../../common/tooltips/AmberSimpleTooltip';
import AmberSectionTitle from '../../labels/AmberSectionTitle';
import AmberSectionTitleRef from '../../labels/AmberSectionTitleRef';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1em',
    flex: '0 1 20%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  highlightBold: {
    color: theme.palette.custom.charcoalGray,
    padding: 0,
    margin: 0,
    fontWeight: 'bolder',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  highlight: {
    color: theme.palette.custom.charcoalGray,
    padding: 0,
    margin: 0,

    fontWeight: 'normal',
  },
  highlightUrgent: {
    padding: 0,
    margin: 0,
    color: theme.palette.custom.pinkishRed,
  },
  metaInfo: {
    padding: 0,
    margin: 0,
    color: theme.palette.custom.purplishGray,
  },
}));

function DoubleRowInfo(props) {
  const { highlight, metaInfo, useHighlightBold, isUrgent, useTooltip } = props;
  const classes = useStyles();
  const fontClass = clsx({
    [classes.highlightBold]: useHighlightBold,
    [classes.highlight]: !useHighlightBold && !isUrgent,
    [classes.highlightUrgent]: isUrgent,
  });
  return (
    <div className={classes.root}>
      {useTooltip ? (
        <AmberSimpleTooltip title={highlight}>
          <AmberSectionTitleRef
            typographyVariant="body1"
            text={highlight}
            classes={{ root: fontClass }}
          />
        </AmberSimpleTooltip>
      ) : (
        <AmberSectionTitle
          typographyVariant="body1"
          text={highlight}
          classes={{ root: fontClass }}
        />
      )}
      <AmberSectionTitle
        typographyVariant="caption"
        text={metaInfo}
        classes={{ root: classes.metaInfo }}
      />
    </div>
  );
}
DoubleRowInfo.propTypes = {
  highlight: PropTypes.string.isRequired,
  metaInfo: PropTypes.string.isRequired,
  useHighlightBold: PropTypes.bool,
  isUrgent: PropTypes.bool,
  useTooltip: PropTypes.bool,
};
DoubleRowInfo.defaultProps = {
  useHighlightBold: false,
  isUrgent: false,
  useTooltip: false,
};
export default DoubleRowInfo;
