import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import AmberOutlinedButton from '../../buttons/AmberOutlinedButton';
import AmberTextButton from '../../buttons/AmberTextButton';
import useSnackbar from '../../../utils/useSnackbar';
import JobTextVisuals from './jobTextVisuals';
import {
  currentJobViewAtom,
  currentJobViewMediaSelector,
  getQualityChecksSelector,
} from '../../../store/job';

import DropDown from '../../common/dropdown/dropdown';
import JobItemDialog from '../../common/job_item_dialog/jobItemDialog';
import JobReportingDialog from '../../common/job_report_dialog/jobReportDialog';
import withQualityControlLoading from '../../../containers/withQualityControlLoading';
import { REPORT_ISSUE, VIEW_DETAILS } from './jobButtonActionsConstants';

import {
  RECOIL_LOADABLE_ERROR_STATE,
  RECOIL_LOADABLE_LOADING_STATE,
} from '../../../constants/constants';

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex' },
  jobItem: {
    display: 'flex',
    flexDirection: 'row',
    flex: '0 1 90%',
    border: `1px solid ${theme.palette.custom.darkGrey}`,
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    borderRadius: '0.3em',
    marginBottom: '1em',
    width: '100%',
    background: theme.palette.primary.white,
  },
  actionButton: {
    alignSelf: 'center',
  },
  dropDown: {
    alignSelf: 'center',
  },
  lastChild: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  exportTextButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    justifyContent: 'flex-start',
    padding: 0,
  },
}));

const hasError = (recoilState) =>
  recoilState.state === RECOIL_LOADABLE_ERROR_STATE;

// FIXME state handling for media files should be in the dialog itself. Apart from
// Doing it here is dumb since it beats the purpose of a state management lib,
// but we needed to ensure that the dialog component can work with redux
// (in order to reduce the development time on the editor) as well.
// Once this feature is revisited, we should move this state handling to the dialog,
// and reduce the bloat in this component.
// Once the dialog can work with the recoil state, error handling/loading data should
// be much easier as well.
// (ノಠ益ಠ)ノ彡┻━┻
function Job(props) {
  const {
    job,
    job: { _id },
    handleOnClickAction,
    disabled,
    buttonText,
    hasDetailsAction,
    handleOnClickReport,
    customExportOptions,
  } = props;
  const classes = useStyles();
  const [t] = useTranslation();
  const [currentJobView, setCurrentJobView] =
    useRecoilState(currentJobViewAtom);
  const media = useRecoilValueLoadable(currentJobViewMediaSelector);
  const qualityChecks = useRecoilValueLoadable(getQualityChecksSelector);

  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
  const [isReportingOpen, setReportingOpen] = useState(false);
  const { showError } = useSnackbar();

  const handleClick = useCallback(() => {
    setIsDetailsDialogOpen(false);
    handleOnClickAction(_id);
  }, [handleOnClickAction]);

  const handleOpenDetails = useCallback(() => {
    setCurrentJobView(job);
    setIsDetailsDialogOpen(true);
  });

  const handleCloseDetails = useCallback(() => {
    setCurrentJobView(null);
    setIsDetailsDialogOpen(false);
  });
  const handleCloseReporting = useCallback(() => {
    setReportingOpen(false);
  });

  const handleOpenReport = useCallback(() => {
    setReportingOpen(true);
  });
  const handleReport = useCallback((issueType, note) => {
    setReportingOpen(false);
    if (isDetailsDialogOpen) {
      setIsDetailsDialogOpen(false);
    }
    handleOnClickReport(_id, issueType, note);
  });

  const getStandardDropDownOptions = () => [
    <AmberTextButton
      key={VIEW_DETAILS}
      text={t('qualityControlDashboard.viewDetails')}
      onClick={handleOpenDetails}
      fullWidth
      classes={{ root: classes.exportTextButton }}
    />,
    <AmberTextButton
      key={REPORT_ISSUE}
      text={t('qualityControlDashboard.reportIssue')}
      onClick={handleOpenReport}
      fullWidth
      classes={{ root: classes.exportTextButton }}
    />,
  ];

  useEffect(() => {
    if (media.state === RECOIL_LOADABLE_ERROR_STATE) {
      showError(media.contents.response.data.message);
    }
    if (qualityChecks.state === RECOIL_LOADABLE_ERROR_STATE) {
      showError(qualityChecks.contents.response.data.message);
    }
  }, [media, currentJobView, qualityChecks]);

  const isDataLoading = () =>
    media.state === RECOIL_LOADABLE_LOADING_STATE ||
    qualityChecks.state === RECOIL_LOADABLE_LOADING_STATE;

  return (
    <div className={classes.jobItem}>
      <JobTextVisuals job={job} />
      <div className={classes.lastChild}>
        <AmberOutlinedButton
          text={buttonText}
          disabled={disabled}
          classes={{ root: classes.actionButton }}
          onClick={handleClick}
          data-testid="job-item-action-button"
        />
        <DropDown
          options={[...customExportOptions, ...getStandardDropDownOptions()]}
          iconComponent={<MoreVertIcon />}
          dropdownClass={classes.dropDown}
          data-testid="job-item-dropdown"
        />
      </div>
      <JobItemDialog
        job={job}
        open={isDetailsDialogOpen}
        disableAction={disabled}
        buttonText={t('common.button.accept')}
        handleOnClickAction={handleClick}
        handleOnClickReport={handleOpenReport}
        handleClose={handleCloseDetails}
        hasDetailsAction={hasDetailsAction}
        shouldDialogLoad={isDataLoading()}
        media={media}
        qualityCheck={
          isDataLoading() || hasError(qualityChecks)
            ? {}
            : qualityChecks.contents
        }
      />
      <JobReportingDialog
        handleClose={handleCloseReporting}
        handleOnClickAction={handleReport}
        open={isReportingOpen}
        job={job}
      />
    </div>
  );
}

Job.propTypes = {
  job: PropTypes.shape({
    _id: PropTypes.string,
    recordId: PropTypes.string,
    sourceJobId: PropTypes.string,
  }).isRequired,
  handleOnClickAction: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
  hasDetailsAction: PropTypes.bool,
  handleOnClickReport: PropTypes.func.isRequired,
  customExportOptions: PropTypes.arrayOf(PropTypes.node),
};
Job.defaultProps = {
  hasDetailsAction: true,
  customExportOptions: [],
};
export default withQualityControlLoading(Job);
