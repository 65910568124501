import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import PropTypes from 'prop-types';
import CircularIndeterminate from '../components/loading/indeterminateSpinner';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
const BackDrop = ({ open }) => {
  const classes = useStyles();
  return (
    <div>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularIndeterminate size="3.6rem" thickness={3.6} />
      </Backdrop>
    </div>
  );
};
BackDrop.propTypes = {
  open: PropTypes.bool.isRequired,
};

function withQualityControlLoading(WrappedComponent) {
  function HOC(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [shouldUseBackdrop, setShouldOpenBackdrop] = useState(true);

    const setDataStatus = useCallback(
      (dataStatus) => {
        setIsLoading(dataStatus);
      },
      [setIsLoading],
    );

    const setUseBackdrop = useCallback(
      (useBackgrop) => {
        setShouldOpenBackdrop(useBackgrop);
      },
      [setShouldOpenBackdrop],
    );
    return (
      <>
        <BackDrop open={isLoading && shouldUseBackdrop} />
        <WrappedComponent
          {...props}
          setIsLoading={setDataStatus}
          setUseBackdrop={setUseBackdrop}
          isLoading={isLoading}
        />
      </>
    );
  }
  return HOC;
}

export default withQualityControlLoading;
