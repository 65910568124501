import { atom, selector } from 'recoil';
import { getUser } from '../utils/api';

import {
  CURRENT_USER_DETAILS,
  CURRENT_USER_ROLES,
  CURRENT_USER_LOGIN_STATUS,
  CURRENT_USER_LOGIN_DETAILS,
  CURRENT_USER_QUERY,
  CURRENT_USER_LANGUAGE,
  GOOGLE_SSO_CURRENT_USER_DETAILS,
} from './constants';

export const getUserAsyncSelector = selector({
  key: CURRENT_USER_QUERY,
  get: async ({ get }) => {
    const userSecrets = get(currentUserLoginDetailsAtom);
    let user = {};
    if (userSecrets.isLoggedIn) {
      const request = await getUser();
      user = request.data;
    }
    return user;
  },
});

export const currentUserDetailsAtom = atom({
  key: CURRENT_USER_DETAILS,
  default: getUserAsyncSelector,
});

export const currentGoogleSSOUserAtom = atom({
  key: GOOGLE_SSO_CURRENT_USER_DETAILS,
  default: null,
});

export const currentUserLanguageAtom = atom({
  key: CURRENT_USER_LANGUAGE,
  default: localStorage.getItem('lang')
    ? localStorage.getItem('lang').toLowerCase()
    : 'en',
});
export const currentUserRoleAtom = atom({
  key: CURRENT_USER_ROLES,
  default: [],
});

export const currentUserLoginDetailsAtom = atom({
  key: CURRENT_USER_LOGIN_DETAILS,
  default: {},
});

export const currentUserLoginStatusSelector = selector({
  key: CURRENT_USER_LOGIN_STATUS,
  get: ({ get }) => {
    const userDetails = get(currentUserDetailsAtom);
    const currentUserRole = get(currentUserRoleAtom);
    const currentUserLoginDetails = get(currentUserLoginDetailsAtom);
    if (
      Object.keys(userDetails).length > 0 &&
      currentUserRole.length === 1 &&
      currentUserLoginDetails.isLoggedIn
    ) {
      return true;
    }
    return false;
  },
});
