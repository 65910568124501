import React, { useCallback } from 'react';
import { makeStyles, Fade, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import AmberFormHelper from '../labels/AmberFormHelper';
import {
  NOTES_MAX_ROWS,
  NOTES_MIN_ROWS,
} from '../common/job_item_dialog/constants';

const useStyles = makeStyles((theme) => ({
  textAreaRoot: {
    margin: 0,
  },
  input: {
    color: 'black',
    fontSize: theme.spacing(2.125),
    fontWeight: 800,
    backgroundColor: theme.palette.custom.lightMango,
  },
  notesBorder: {
    border: `2px solid ${theme.palette.custom.darkGrey}`,
    borderRadius: '0.5em 0.5em 0 0',
    padding: '1em',
    margin: 0,
  },
}));

const getHelperText = (hasError, formHelperText, t) => {
  if (hasError) {
    return (
      <Fade transition={700} in={hasError}>
        <AmberFormHelper error text={t('common.error.noEmpty')} />
      </Fade>
    );
  }
  if (formHelperText) {
    <AmberFormHelper error={false} text={formHelperText} />;
  }
  return null;
};

function AmberNotesTextArea(props) {
  const {
    formHelperText,
    inputClasses,
    hasError,
    handleChange,
    id,
    value,
    ...rest
  } = props;
  const [t] = useTranslation();
  const classes = useStyles();

  const onChange = useCallback((event) => {
    const { target } = event;
    handleChange({ value: target.value, id });
  });

  return (
    <TextField
      helperText={getHelperText(hasError, formHelperText, t)}
      maxRows={NOTES_MAX_ROWS}
      minRows={NOTES_MIN_ROWS}
      InputProps={{
        classes: {
          root: classes.notesBorder,
        },
        className: classes.input,
        disableUnderline: true,
      }}
      classes={{ root: classes.textAreaRoot }}
      variant="standard"
      onChange={onChange}
      value={value}
      id={id}
      multiline
      {...rest}
    />
  );
}
AmberNotesTextArea.propTypes = {
  formHelperText: PropTypes.string,
  hasError: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  value: PropTypes.string,
  inputClasses: PropTypes.objectOf(PropTypes.string),
};
AmberNotesTextArea.defaultProps = {
  formHelperText: '',
  hasError: false,
  id: null,
  value: '',
  inputClasses: {},
};
export default AmberNotesTextArea;
