import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useRecoilState, useRecoilStateLoadable } from 'recoil';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import LoginForm from '../components/login/loginForm';
import { getUser, login } from '../utils/api';
import { useDataApi } from '../utils/apiHooks';
import useSnackbar from '../utils/useSnackbar';
import {
  ADMIN,
  PERFECT_JOB_MANAGER,
  QUALITY_CHECKER,
  TRANSCRIBER,
} from '../roles';
import {
  currentUserDetailsAtom,
  currentUserLoginDetailsAtom,
  currentUserRoleAtom,
} from '../store/user';
import AmberPaper from '../components/papers/AmberPaper';
import { getLoginDetails } from '../utils/auth';
import {
  getUserRoleFromRolesArray,
  storeUserRoleToLocalStorageNoAccessToken,
} from '../utils/apiHelper';
import CircularDeterminate from '../components/common/loading';
import {
  RECOIL_LOADABLE_DONE_STATE,
  RECOIL_LOADABLE_LOADING_STATE,
} from '../constants/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    padding: theme.spacing(5),
    backgroundColor: theme.palette.common.white,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

function Login(props) {
  const [forceUserRoleChoice, setForceUserRoleChoice] = useState(false);
  const { showError } = useSnackbar();
  const [t] = useTranslation();
  const [userRoles, setUserRoles] = useRecoilState(currentUserRoleAtom);
  const [userDetails, setCurrentUserDetails] = useRecoilStateLoadable(
    currentUserDetailsAtom,
  );
  const [loginDetails, setLoginDetails] = useRecoilState(
    currentUserLoginDetailsAtom,
  );
  const { history } = props;
  const classes = useStyles();

  const handleUserRetrieval = (data) => {
    const roles = getUserRoleFromRolesArray(loginDetails.role);
    const dataNoExtraRoles = { ...data, roles };
    if (
      ![ADMIN, TRANSCRIBER, PERFECT_JOB_MANAGER, QUALITY_CHECKER].some((role) =>
        roles.includes(role),)
    ) {
      showError(t('loginForm.userTypeError'));
      return;
    }
    setCurrentUserDetails({ ...dataNoExtraRoles });
    checkUserRoles(roles);
  };
  const [loginStatus, doLogin] = useDataApi(false, {}, showError, handleLogin);
  const [, doUserRetrieval] = useDataApi(
    false,
    {},
    showError,
    handleUserRetrieval,
  );
  const redirectToDashBoard = () => {
    const userType = userRoles && userRoles[0];
    switch (userType) {
      case ADMIN:
        history.push('admin/dashboard');
        break;
      case PERFECT_JOB_MANAGER:
        history.push('manager/dashboard');
        break;
      case QUALITY_CHECKER:
        history.push('quality-controller/dashboard');
        break;
      case TRANSCRIBER:
        history.push('transcriber/dashboard');
        break;
      default:
        break;
    }
  };

  const checkUserRoles = (roles) => {
    setUserRoles(roles);
    if (roles.length > 1) {
      setForceUserRoleChoice(true);
    }
  };

  function handleLogin() {
    setLoginDetails({
      ...getLoginDetails(),
    });
    doUserRetrieval(getUser());
  }

  const userLoadingDone = () =>
    userDetails.state === RECOIL_LOADABLE_DONE_STATE &&
    Object.keys(userDetails.contents).length > 0 &&
    userRoles.length === 1;

  const NEW_LOGIN_ENABLED =
    process.env.REACT_APP_FF_NEW_LOGIN !== undefined
      ? JSON.parse(process.env.REACT_APP_FF_NEW_LOGIN)
      : false;

  useEffect(() => {
    if (userRoles && userRoles.length === 1 && userLoadingDone()) {
      redirectToDashBoard();
      return;
    }
    if (NEW_LOGIN_ENABLED) {
      history.replace('/login_old');
    } else {
      history.replace('/');
    }
  }, [userRoles, userDetails]);

  const onLogin = useCallback(
    (password, email) => {
      if (email.trim() === '') {
        showError(t('loginForm.emailError'));
      } else if (password === '') {
        showError(t('loginForm.passwordError'));
      } else {
        doLogin(login(email.trim(), password));
      }
    },
    [doLogin, showError],
  );

  const handleRoleCheck = useCallback((id) => {
    setForceUserRoleChoice(false);
    setUserRoles([id]);
    storeUserRoleToLocalStorageNoAccessToken(id);
    setLoginDetails({
      ...loginDetails,
      role: id,
    });
  });

  return (
    <div className={classes.root}>
      {userDetails.state === RECOIL_LOADABLE_LOADING_STATE ? (
        <CircularDeterminate size="3.6rem" thickness={3.6} />
      ) : (
        <AmberPaper classes={{ root: classes.paper }}>
          <LoginForm
            onLogin={onLogin}
            loginStatus={loginStatus}
            roleCheckRequired={forceUserRoleChoice}
            handleRoleCheck={handleRoleCheck}
          />
        </AmberPaper>
      )}
    </div>
  );
}

Login.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func, push: PropTypes.func })
    .isRequired,
};

export default Login;
