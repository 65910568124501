import moment from 'moment';

/**
 * Seconds to hours, minutes and seconds
 * @param  {number}
 * @return {string}
 */
export function toHHMMSS(seconds) {
  if (Number.isInteger(seconds)) {
    return moment.utc(seconds * 1000).format('HH:mm:ss');
  }

  return '';
}

/**
 * Get time
 *
 * @param  {Number} timestamp
 * @return {String}
 */
export function getTime(timestamp) {
  try {
    const d = new Date(timestamp);
    return d.toISOString().substr(11, 8);
  } catch (e) {
    return '';
  }
}

/**
 * Get date
 *
 * @see  https://stackoverflow.com/a/3605248/1938970
 * @param  {Number} timestamp
 * @return {String}
 */
export function getDate(timestamp) {
  const d = new Date(timestamp);

  if (d && d instanceof Date && !isNaN(d.getTime())) {
    return `${`0${d.getDate()}`.slice(-2)}-${`0${d.getMonth() + 1}`.slice(
      -2,
    )}-${d.getFullYear()}`;
  }
  return '';
}

export function getDateTime(timestamp) {
  if (timestamp === null || timestamp === '') return '';
  try {
    const d = new Date(timestamp);
    const isoString = d.toISOString();
    const dateYMDHS = `${isoString.substr(0, 10)} ${isoString.substr(11, 8)}`;

    // To local timezone
    const testDateUtc = moment.utc(dateYMDHS);
    const localDateMoment = moment(testDateUtc).local();
    const localFormat = localDateMoment.format('YYYY-MM-DD HH:mm:ss');
    return localFormat;
  } catch (e) {
    return '';
  }
}

export function getDateTimeAmsterdam(timestamp) {
  if (timestamp === null || timestamp === '') return '';
   try {
    // Parse the timestamp as a moment object
    const utcMoment = moment(timestamp);

    // Manually adjust for Amsterdam timezone
    // Check if the date is in Daylight Saving Time
    const isDST = utcMoment.isDST(); // Will return true if DST is in effect
    const offset = isDST ? 2 : 1; // CET is UTC+1, CEST (Daylight saving) is UTC+2

    const amsterdamTime = utcMoment.utcOffset(offset * 60);
    const localFormat = amsterdamTime.format('YYYY-MM-DD HH:mm:ss');

    return localFormat;
  } catch (e) {
    return '';
  }
}

export function getGMTDate(localTimestamp) {
  if (localTimestamp === null || localTimestamp === '') return '';
  try {
    // Parse the timestamp as a moment object in local time
    const localMoment = moment(localTimestamp);

    // Get the correct UTC offset for Amsterdam
    const isDST = localMoment.isDST(); // Check if DST is in effect
    const offset = isDST ? 2 : 1; // Convert Amsterdam time to UTC: +2 for CEST, +1 for CET

    // Adjust the local time to GMT (UTC) by subtracting the offset
    const gmtTime = localMoment.subtract(offset, 'hours');
    const gmtFormat = gmtTime.format('YYYY-MM-DD HH:mm:ss');

    return gmtFormat;
  } catch (e) {
    return '';
  }
}

export function getServerDate(localTimestamp) {
  if (!localTimestamp) return ''; // Handle null or empty input
  
  try {
    // Parse the timestamp in local time using Moment.js
    const localMoment = moment(localTimestamp);
    
    // Convert the local moment object to UTC (GMT)
    const gmtTime = localMoment.utc(); // Converts to UTC directly
    
    // Format the UTC time in the desired format
    const gmtFormat = gmtTime.format('YYYY-MM-DD HH:mm:ss');
    
    return gmtFormat;
  } catch (e) {
    return '';
  }
}


export function getTimeFromSec(seconds) {
  return getTime(seconds * 1000);
}

export function getDateFromNow(seconds) {
  const lang = localStorage.getItem('lang');

  return moment(getDateTime(seconds)).locale(lang).fromNow();
}
