import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Fade } from '@material-ui/core';
import AmberFormHelper from '../../labels/AmberFormHelper';

const makeSelectValue = (value) => ({
  label: value,
  value,
});
const makeSelectOptions = (options) =>
  options.map((option) => ({
    label: option,
    value: option,
  }));

function AmberSimpleSelect(props) {
  const {
    isClearable,
    isSearchable,
    isDisabled,
    optionsArray,
    value,
    id,
    onChange,
    errorHelper,
    error,
    ...rest
  } = props;
  const [internalValue, setInternalValue] = useState(null);
  const [internalOptions, setInternalOptions] = useState([]);

  useEffect(() => {
    if (!value) {
      setInternalValue(null);
      return;
    }
    if (typeof value === 'string') {
      setInternalValue({ ...makeSelectValue(value) });
    } else {
      setInternalValue({ ...value });
    }
  }, [value]);

  useEffect(() => {
    if (optionsArray.every((option) => typeof option === 'string')) {
      setInternalOptions([...makeSelectOptions(optionsArray)]);
    } else {
      setInternalOptions([...optionsArray]);
    }
  }, [optionsArray]);

  const handleChange = (changeValue, action) => {
    const artificialEvent = {};
    artificialEvent.id = id;
    artificialEvent.selectedOption = changeValue;
    artificialEvent.selectAction = action;
    onChange({ ...artificialEvent });
  };

  return (
    <>
      <Select
        isClearable={isClearable}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        options={internalOptions}
        value={internalValue}
        onChange={handleChange}
        {...rest}
      />
      {error && errorHelper ? (
        <Fade transition={700} in={!!error}>
          <AmberFormHelper
            error={error}
            id={`${id}-helper`}
            text={errorHelper}
          />
        </Fade>
      ) : null}
    </>
  );
}

AmberSimpleSelect.propTypes = {
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  optionsArray: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ]),
  ),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ]),
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  errorHelper: PropTypes.string,
  error: PropTypes.bool,
};
AmberSimpleSelect.defaultProps = {
  isClearable: false,
  isSearchable: false,
  isDisabled: false,
  id: null,
  optionsArray: [],
  value: null,
  error: false,
  errorHelper: null,
};
export default AmberSimpleSelect;
