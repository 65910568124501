export const PERFECT_OPEN = 'PERFECT_OPEN';
export const PERFECT_AWAITING_CONFIRMATION_TRANSCRIBER =
  'PERFECT_AWAITING_CONFIRMATION_TRANSCRIBER';
export const PERFECT_PAYMENT_PENDING = 'PERFECT_PAYMENT_PENDING';
export const PERFECT_IN_CHECKOUT = 'PERFECT_IN_CHECKOUT';
export const PERFECT_IN_QUEUE = 'PERFECT_IN_QUEUE';
export const PERFECT_EDITING = 'PERFECT_EDITING';
export const PERFECT_TO_BE_REVIEWED = 'PERFECT_TO_BE_REVIEWED';
export const PERFECT_REVIEW_NOT_PASSED = 'PERFECT_REVIEW_NOT_PASSED';
export const PERFECT_DONE = 'PERFECT_DONE';
export const PERFECT_IN_REVIEW = 'PERFECT_IN_REVIEW';
export const PERFECT_REPORTED = 'PERFECT_REPORTED';
export const PERFECT_CANCELLED = 'PERFECT_CANCELLED';
export const PERFECT_PAYMENT_DECLINED = 'PERFECT_PAYMENT_DECLINED';
export const BUSY = 'BUSY';
export const ERROR = 'ERROR';
export const DONE = 'DONE';
export const QUALITY_CONTROLLER_OPEN = 'open';
export const QUALITY_CONTROLLER_COMPLETED = 'completed';
export const QUALITY_CONTROLLER_ACTIVE = 'active';
