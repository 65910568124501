import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE,
  useRecoilValue,
  useRecoilValueLoadable,
} from 'recoil';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import { acceptQCJob, reportQCJob } from '../../../../utils/api';
import useSnackbar from '../../../../utils/useSnackbar';
import CollapseControl from '../collapseControl';
import {
  activeJobsAtom,
  getNumberOfActiveJobsSelector,
  openJobsAtom,
} from '../../../../store/qualityControl';
import withQualityControlLoading from '../../../../containers/withQualityControlLoading';
import Job from '../job';
import AmberSimpleCollapse from '../../../collapse/AmberSimpleCollapse';
import { RECOIL_LOADABLE_LOADING_STATE } from '../../../../constants/constants';

const useStyles = makeStyles(() => ({
  root: { display: 'flex', flexDirection: 'column' },
  openJobsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 1 90%',
  },
}));

function OpenJobList({ setIsLoading }) {
  const [t] = useTranslation();
  const { showError } = useSnackbar();
  const openJobs = useRecoilValueLoadable(openJobsAtom);
  const numberOfActiveJobs = useRecoilValue(getNumberOfActiveJobsSelector);
  const resetOpenJobs = useRecoilRefresher_UNSTABLE(openJobsAtom);
  const resetActiveJobs = useRecoilRefresher_UNSTABLE(activeJobsAtom);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleJobAccepted = useRecoilCallback(() => async (jobId) => {
    setIsLoading(true);
    try {
      await acceptQCJob(jobId);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 500) {
          showError(error.response.data.error);
        } else {
          showError(error.response.data.message);
        }
      } else {
        showError(error.message);
      }
    } finally {
      resetOpenJobs();
      resetActiveJobs();
      setIsLoading(false);
    }
  });

  const handleJobReported = useRecoilCallback(
    ({ set }) =>
      async (jobId, issueType, note) => {
        setIsLoading(true);
        try {
          await reportQCJob(jobId, issueType, note);
          const newOpenList = openJobs.contents.filter(
            (job) => job._id !== jobId,
          );
          set(openJobsAtom, [...newOpenList]);
        } catch (error) {
          if (error.response) {
            showError(error.response.data.message);
          } else {
            showError(error.message);
          }
        } finally {
          setIsLoading(false);
        }
      },
  );

  const classes = useStyles();

  useEffect(() => {
    setIsLoading(openJobs.state === RECOIL_LOADABLE_LOADING_STATE);
  }, [openJobs]);
  const handleCollapse = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  });

  const handleOnClickAccept = useCallback((jobId) => {
    handleJobAccepted(jobId);
  });

  const handleOnClickReport = useCallback((jobId, issueType, note) => {
    handleJobReported(jobId, issueType, note);
  });

  return (
    openJobs.state !== RECOIL_LOADABLE_LOADING_STATE && (
      <div className={classes.openJobsContainer}>
        <CollapseControl
          numberOfActiveJobs={numberOfActiveJobs}
          handleCollapse={handleCollapse}
          isCollapsed={isCollapsed}
          collapseControlTitle={t(
            'qualityControlDashboard.collapsibleListTitle',
          )}
        />
        <AmberSimpleCollapse
          open={isCollapsed}
          title={t('qualityControlDashboard.collapsibleListTitle')}
          wrapperClasses={classes.root}
        >
          {openJobs.contents.map((openJob, index) => (
            <Job
              job={openJob}
              key={openJob.recordId}
              buttonText={t('common.button.accept')}
              handleOnClickAction={handleOnClickAccept}
              handleOnClickReport={handleOnClickReport}
              disabled={index > 9 || numberOfActiveJobs !== 0}
            />
          ))}
        </AmberSimpleCollapse>
      </div>
    )
  );
}

OpenJobList.propTypes = {
  setIsLoading: PropTypes.func.isRequired,
};

export default withQualityControlLoading(OpenJobList);
